import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ObjectKeys } from "../../constants/object-keys";
import * as urls from "../../../../environments/environment";
import * as elasticsearch from "elasticsearch-browser";
import { Medication } from "../../models/Medication/Medication";

@Injectable({
  providedIn: "root"
})
export class MedicationAutoSearchService {
  private client: elasticsearch.Client;
  private MEDICATION_INDEX: string = "medication_text_index";
  private TYPE = "_doc";
  constructor(private _http: HttpClient) {
    if (!this.client) {
      this.createClient();
    }
  }

  public createClient() {
    // console.log("creating");
    this.client = new elasticsearch.Client({
      host: urls.environment.elasticSearch
      // log: "trace"
    });
  }

  create(
    organisationId: string,
    organisationName: string,
    name: string,
    composition: string,
    dosageForm: string,
    route?: string
  ) {
    return new Promise((resolve, reject) => {
      let body = {
        organisationId: organisationId,
        organisationName: organisationName,
        name: name,
        composition: composition,
        dosageForm: dosageForm
      };
      if (route != null) {
        body[ObjectKeys.route] = route;
      }
      this._http
        .post(urls.environment["medication-auto-search"], body)
        .subscribe(
          (res: Response) => {
            if (res != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  update(
    id: string,
    organisationId: string,
    organisationName: string,
    name: string,
    composition: string,
    dosageForm: string,
    route?: string
  ) {
    return new Promise((resolve, reject) => {
      let body = {
        name: name,
        organisationId: organisationId,
        organisationName: organisationName,
        composition: composition,
        dosageForm: dosageForm,
        medicationId: id
      };
      if (route != null) {
        body[ObjectKeys.route] = route;
      }
      this._http
        .post(urls.environment["medication-auto-search"], body)
        .subscribe(
          (res: Response) => {
            if (res != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public medicineTextSearch(_queryText): any {
    return this.client.search({
      index: this.MEDICATION_INDEX,
      type: this.TYPE,
      filterPath: ["hits.hits._source", "hits.total", "_scroll_id"],
      body: {
        query: {
          bool: {
            must: {
              multi_match: {
                query: _queryText,
                type: "cross_fields",
                operator: "and"
              }
            }
          }
        }
      }
    });
  }

  public medicineNameTextSearch(_queryText): any {
    return new Promise((resolve, reject) => {
      if (_queryText === "") {
        resolve([]);
      } else {
        this.client
          .search({
            index: this.MEDICATION_INDEX,
            type: this.TYPE,
            filterPath: ["hits.hits._source", "hits.total", "_scroll_id"],
            body: {
              query: {
                bool: {
                  must: {
                    multi_match: {
                      query: _queryText,
                      type: "cross_fields",
                      operator: "and",
                      fields: ["name"]
                    }
                  }
                }
              }
            }
          })
          .then(response => {
            if (response.hits.hits) {
              let tempArray = response.hits.hits.map(a => a._source.name);
              let map = {};
              for (let t of tempArray) {
                map[t] = true;
              }
              resolve(Array.from(Object.keys(map)));
              console.log("Response: ", response.hits.hits);
            } else {
              resolve([]);
            }
          });
      }
    });
  }

  public medicineCompositionTextSearch(_queryText): any {
    return new Promise((resolve, reject) => {
      if (_queryText === "") {
        resolve([]);
      } else {
        this.client
          .search({
            index: this.MEDICATION_INDEX,
            type: this.TYPE,
            filterPath: ["hits.hits._source", "hits.total", "_scroll_id"],
            body: {
              query: {
                bool: {
                  must: {
                    multi_match: {
                      query: _queryText,
                      type: "cross_fields",
                      operator: "and",
                      fields: ["composition"]
                    }
                  }
                }
              }
            }
          })
          .then(response => {
            if (response.hits.hits) {
              let tempArray = response.hits.hits.map(
                a => a._source.composition
              );
              let map = {};
              for (let t of tempArray) {
                map[t] = true;
              }
              resolve(Array.from(Object.keys(map)));
            } else {
              resolve([]);
            }
          });
      }
    });
  }

  public fetchRelevantMedicineTextSearch(_composition, _dosageForm) {
    return new Promise((resolve, reject) => {
      let query = {
        bool: {
          must: [
            {
              multi_match: {
                query: _composition,
                type: "cross_fields",
                operator: "and",
                fields: ["composition", "composition_keyword", "name"]
              }
            }
          ]
        }
      };
      if (query["bool"]["filter"] == null) {
        query["bool"]["filter"] = [];
      }
      query["bool"]["filter"].push({
        term: {
          is_removed: false
        }
      });
      if (_dosageForm != null) {
        query["bool"]["filter"].push({
          term: {
            dosageForm: _dosageForm
          }
        });
      }
      this.client
        .search({
          index: this.MEDICATION_INDEX,
          type: this.TYPE,
          filterPath: ["hits.hits._source", "hits.total", "_scroll_id"],
          body: {
            query: query,
            aggs: {
              by_composition: {
                terms: {
                  field: "composition_keyword"
                }
              }
            }
          }
        })
        .then(response => {
          // console.log("ELASTIC", response);
          let searchResultMedication: Medication[] = [];
          if (response.hits.hits) {
            searchResultMedication = response.hits.hits.map(hit => {
              var thisMedicine: Medication = new Medication();
              thisMedicine.initFromJSON(hit._source);
              return thisMedicine;
            });
          } else {
            //No need
          }
          resolve(searchResultMedication);
        })
        .catch(err => {
          console.error(err);
          resolve([]);
        });
    });
  }

  public medicineDatabaseFilterTextSearch(
    organisationId: string,
    _name?: string,
    _composition?: string,
    _dosageForm?: string
  ) {
    let fields = [];
    let queryStr = "";
    if (_dosageForm != null && _dosageForm.length > 0) {
      queryStr += _dosageForm + " ";
      fields.push("dosageForm");
    }
    if (_composition != null && _composition.length > 0) {
      queryStr += _composition + " ";
      fields.push("composition");
      fields.push("composition_keyword");
    }
    if (_name != null && _name.length > 0) {
      queryStr += _name + " ";
      fields.push("name");
    }
    return new Promise((resolve, reject) => {
      let query = {
        bool: {
          must: [
            {
              multi_match: {
                query: queryStr,
                type: "cross_fields",
                operator: "and",
                fields: fields
              }
            }
          ]
        }
      };
      if (query["bool"]["filter"] == null) {
        query["bool"]["filter"] = [
          {
            term: {
              organisationId: organisationId
            }
          }
        ];
      }
      this.client
        .search({
          index: this.MEDICATION_INDEX,
          type: this.TYPE,
          filterPath: ["hits.hits._source", "hits.total", "_scroll_id"],
          body: {
            query: query,
            aggs: {
              by_composition: {
                terms: {
                  field: "composition_keyword"
                }
              }
            }
          }
        })
        .then(response => {
          // console.log("ELASTIC", response);
          let updatedOnMap = {};
          let searchResultMedication: Medication[] = [];
          if (response.hits.hits) {
            searchResultMedication = response.hits.hits.map(hit => {
              var thisMedicine: Medication = new Medication();
              thisMedicine.initFromJSON(hit._source);
              updatedOnMap[thisMedicine.medicationId] =
                hit._source["updatedOn"];
              return thisMedicine;
            });
          } else {
            //No need
          }
          searchResultMedication.sort((a, b) => {
            return updatedOnMap[b.medicationId] - updatedOnMap[a.medicationId];
          });
          resolve(searchResultMedication);
        })
        .catch(err => {
          console.error(err);
          resolve([]);
        });
    });
  }

  public allMedicineResults(organisationId: string) {
    return new Promise((resolve, reject) => {
      let query = {
        bool: {
          must: {
            match_all: {}
          }
        }
      };
      if (query["bool"]["filter"] == null) {
        query["bool"]["filter"] = [
          {
            term: {
              organisationId: organisationId
            }
          }
        ];
      }
      this.client
        .search({
          index: this.MEDICATION_INDEX,
          type: this.TYPE,
          filterPath: ["hits.hits._source", "hits.total", "_scroll_id"],
          body: {
            query: query
          }
        })
        .then(response => {
          // console.log("ELASTIC", response);
          let updatedOnMap = {};
          let searchResultMedication: Medication[] = [];
          if (response.hits.hits) {
            searchResultMedication = response.hits.hits.map(hit => {
              var thisMedicine: Medication = new Medication();
              thisMedicine.initFromJSON(hit._source);
              updatedOnMap[thisMedicine.medicationId] =
                hit._source["updatedOn"];
              return thisMedicine;
            });
          } else {
            //No need
          }
          searchResultMedication.sort((a, b) => {
            return updatedOnMap[b.medicationId] - updatedOnMap[a.medicationId];
          });
          resolve(searchResultMedication);
        })
        .catch(err => {
          console.error(err);
          resolve([]);
        });
    });
  }
}

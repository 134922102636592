// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let baseUrl = "https://india-pro-test.sencillo.in";
export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyAOjQS19ZsXKLMX-Z6V_2f6gSk48mCVW5Q",
    authDomain: "test-md-docvita-2.firebaseapp.com",
    databaseURL: "https://test-md-docvita-2.firebaseio.com",
    projectId: "test-md-docvita-2",
    storageBucket: "test-md-docvita-2.appspot.com",
    messagingSenderId: "683248021912",
    appId: "1:683248021912:web:e96733a0e4368c49"
  },
  intercomId: "j3zaxu43",
  segmentKey: "LMdr2IrH2idlCEv5sRQgKcqTUSs9eR6n",
  elasticSearch: "https://docvita-md-test-elastic.sencillo.in",
  firebaseMessagingKey:
    "BHmYBRot6r-x93Klmsizk-Iy6ltizU4gvsvHq0jcuAD1WJCQQBhc2QSfPaJgTHzOm5BvmUaJBI_76hBHI90qieM",
  "patient-profile": baseUrl + "/v1/patient/profile",
  "patient-details": baseUrl + "/v1/patient/details",
  "validate-unique-identifier":
    baseUrl + "/v1/patient/profile/validate-unique-identifier",
  "make-connection": baseUrl + "/v1/patient/profile/make-connection",
  "opd-sms-status": baseUrl + "/v1/appointment/receive-daily-opdsms",
  "book-appointment": baseUrl + "/v2/appointment/book",
  "cancel-appointment": baseUrl + "/v2/appointment/cancel",
  "complete-appointment": baseUrl + "/v2/appointment/complete",
  "arrive-appointment": baseUrl + "/v2/appointment/arrive",
  "get-appointment-list": baseUrl + "/v1/get-appointments-list-pdf",
  "send-appointments-remainder":
    baseUrl + "/v1/appointment/send-appointments-reminder-manually",
  "add-to-queue": baseUrl + "/v2/appointment/add-to-queue",
  "reschedule-appointment": baseUrl + "/v2/appointment/reschedule",
  "member-profile": baseUrl + "/v1/member/profile",
  "list-of-available-doctors":
    baseUrl + "/v2/opd-schedule/list-of-available-doctors",
  "fetch-member-schedule": baseUrl + "/v1/opd-schedule/fetch-member-schedule",
  "fetch-member-slots": baseUrl + "/v2/opd-schedule/fetch-member-slots",
  "weekly-slot": baseUrl + "/v1/opd-schedule/weekly-slot",
  "specific-availability-slot":
    baseUrl + "/v1/opd-schedule/specific-availability-slot",
  "specific-unavailability-slot":
    baseUrl + "/v1/opd-schedule/specific-unavailability-slot",
  prescription: baseUrl + "/v1/prescription",
  "prescription-template": baseUrl + "/v1/prescription-template",
  "patient-prescription": baseUrl + "/v1/prescription/get-all-for-patient",
  "voice-command": baseUrl + "/v1/voice/assistant/endpoint",
  "voice-command-urine-rm": baseUrl + "/v1/voice/assistant/endpoint/urine-rm",
  "voice-command-sugar": baseUrl + "/v1/voice/assistant/endpoint/sugar",
  "voice-command-total-lipid-profile":
    baseUrl + "/v1/voice/assistant/endpoint/total-lipid-profile",
  "voice-command-cbc": baseUrl + "/v1/voice/assistant/endpoint/cbc",
  "blood-group": baseUrl + "/v1/patient/profile/blood-group",
  "create-patient-bill": baseUrl + "/v1/create-patient-bill",
  "update-patient-bill": baseUrl + "/v1/update-patient-bill",
  "share-prescription-with-patient":
    baseUrl + "/v1/share-prescription-with-patient",
  allergies: baseUrl + "/v1/patient/profile/allergies",
  habits: baseUrl + "/v1/patient/profile/habits",
  "dental-history": baseUrl + "/v1/patient/profile/dental-history",
  "medical-history": baseUrl + "/v1/patient/profile/medical-history",
  "current-medications": baseUrl + "/v1/patient/profile/current-medications",
  "past-history": baseUrl + "/v1/patient/profile/past-history",
  "generate-otp": baseUrl + "/v1/auth/generate-otp",
  "resend-otp": baseUrl + "/v1/auth/resend-otp",
  "verify-otp": baseUrl + "/v1/auth/verify-otp",
  vitals: baseUrl + "/v1/vitals",
  "patient-count": baseUrl + "/v1/dashboard/patient-count",
  "follow-up-list": baseUrl + "/v1/dashboard/follow-up-list",
  "date-of-birth-list": baseUrl + "/v1/dashboard/date-of-birth-list",
  "custom-sms": baseUrl + "/v1/patient-communication/custom-sms",
  "medication-auto-search": baseUrl + "/v1/medication-auto-search",
  "generate-otp-signup": baseUrl + "/v1/auth/generate-otp/signup",
  "verify-otp-signup": baseUrl + "/v1/auth/verify-otp/signup",
  "activate-free-plan": baseUrl + "/v1/organisation/activate-free-plan",
  "quick-note": baseUrl + "/v1/patient/profile/quick-note",
  "remove-from-network": baseUrl + "/v1/patient/profile/remove-connection",
  "consent-form-template": baseUrl + "/v1/consent-form/template",
  "consent-form-category": baseUrl + "/v1/consent-form/category",
  "share-consent-form": baseUrl + "/v1/share-consent-form-with-patient",
  "fetch-all-consent-form-template": baseUrl + "/v1/consent-form/template/all",
  "consent-form": baseUrl + "/v1/consent-form",
  instruction: baseUrl + "/v1/instruction",
  "instruction-category": baseUrl + "/v1/instruction/category",
  "instruction-template": baseUrl + "/v1/instruction/template",
  "share-instruction-form": baseUrl + "/v1/share-instruction-form-with-patient",
  "fetch-all-instruction-template": baseUrl + "/v1/instruction/template/all",
  "fetch-instruction-pdf": baseUrl + "/v1/instruction/generate-pdf",
  "dental-case-history": baseUrl + "/v1/dental/case-history",
  "dental-chart-entry": baseUrl + "/v1/dental/dental-chart-entry",
  "dental-treatment": baseUrl + "/v1/dental/treatment",
  "stats-by-date-range": baseUrl + "/v1/dashboard/stats-by-date-range",
  "send-birthday-sms": baseUrl + "/v1/patient-communication/birthday-sms",
  "token-registration": baseUrl + "/v1/notification/register-token",
  "token-deregistration": baseUrl + "/v1/notification/deregister-token",
  "fetch-member-slots-with-multiple-dates":
    baseUrl + "/v1/opd-schedule/fetch-member-slots/multiple-dates",
  "change-followup-date": baseUrl + "/v1/dashboard/change-followup-date",
  "vital-settings": baseUrl + "/v1/organisation/vital-settings",
  "patient-info-pdf": baseUrl + "/v1/get-patient-info-pdf",
  "get-billing-pdf": baseUrl + "/v1/get-billing-pdf",
  "get-patient-bill": baseUrl + "/v1/get-patient-bill",
  "delete-patient-bill": baseUrl + "/v1/delete-patient-bill",
  "delete-appointment": baseUrl + "/v2/appointment/delete",
  "print-collection-pdf": baseUrl + "/v1/billing/finances/print-collection-pdf"
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

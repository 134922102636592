import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { VitalMeasurement } from "src/app/shared/models/Vitals/Vital Measurement";
import { SelectizeConfigs } from "src/app/shared/constants/object-keys";
import { ActivatedRoute, Router } from "@angular/router";
import { DateActions } from "src/app/shared/models/Demographics";
import { LatestVitalSnapshot } from "src/app/shared/models/Vitals/Latest Vital Snapshot";
import { VitalMeasurementConstants } from "src/app/shared/models/Vitals/Vital Measurement Constants";
import { VoiceService } from "src/app/shared/services/voice/voice.service";
import { GlobalVariables } from "src/app/globar-var/globarVariables";
import { VitalService } from "src/app/shared/services/vitals/vital.service";
import { NotifyService } from "src/app/shared/services/notify/notify.service";
import { PatientVitalModalService } from "../../services/modalStateManagement/patient-vital-modal.service";

@Component({
  selector: "app-patient-vitals-summary-card",
  templateUrl: "./patient-vitals-summary-card.component.html",
  styleUrls: ["./patient-vitals-summary-card.component.css"]
})
export class PatientVitalsSummaryCardComponent implements OnInit, OnChanges {
  patientUUId: string = "";
  // Boolean to track if a user is making new vital entry
  @Input() showVitalEntryEditForm: boolean = false;

  @Input() showLoader: boolean = false;

  vitalSamples = [];
  isOPDAppointmentChart: boolean = true;

  newVitalEntry: LatestVitalSnapshot = new LatestVitalSnapshot();
  latestVitals: LatestVitalSnapshot = new LatestVitalSnapshot();
  @Input() vitals: Array<VitalMeasurement> = new Array<VitalMeasurement>();
  editVital: VitalMeasurement;
  @Output() toggleShowVitalsHistory: EventEmitter<boolean> = new EventEmitter<
    boolean
  >();

  private pain_vital_options = VitalMeasurementConstants.PAIN_VITAL_OPTIONS;
  private blood_pressure_positions =
    VitalMeasurementConstants.BLOOD_PRESSURE_POSITIONS;
  private blood_glucose_timings =
    VitalMeasurementConstants.BLOOD_GLOCOSE_TIMINGS;

  singleSelectConfig = SelectizeConfigs.singleSelectConfig;
  private editing: boolean = false;

  constructor(
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    private _voiceService: VoiceService,
    private _vitalService: VitalService,
    private _notifyService: NotifyService,
    private _patientVitalModalService: PatientVitalModalService
  ) {}

  ngOnInit() {
    // console.log("Created");
    var self = this;

    this.activatedRoute.parent.params.subscribe(params => {
      self.patientUUId = params["patient-uuid"];
      // console.log("Now getting the patient id: ", self.patientUUId);
    });
    this._patientVitalModalService.modalOpenedAgain().subscribe(val => {
      if (val && val instanceof VitalMeasurement) {
        console.log("edit clicked");
        window.analytics.page("Vitals - Edit");
        this.editing = true;
        this.editVital = new VitalMeasurement();
        this.editVital.initFromJSON(val.toJSON());
        this.handleEditVital();
      }
    });
    if (this._router.url.indexOf("paitents") > -1) {
      this.isOPDAppointmentChart = true;
    } else if (this._router.url.indexOf("opd") > -1) {
      this.isOPDAppointmentChart = true;
    }
    self.populateVitalInfo();
  }

  toggleVitalHistory() {
    this.toggleShowVitalsHistory.emit(true);
  }

  ngOnChanges() {
    this.populateVitalInfo();
    this.handleEditVital();
  }

  handleEditVital() {
    if (this.editVital != null && this.editVital.vitalMeasurementId != null) {
      this.newVitalEntry = new LatestVitalSnapshot();
      switch (this.editVital.vitalType) {
        case VitalMeasurementConstants.VITAL_TYPE_HEIGHT: {
          this.newVitalEntry.height.initFromJSON(this.editVital.toJSON());
          break;
        }
        case VitalMeasurementConstants.VITAL_TYPE_WEIGHT: {
          this.newVitalEntry.weight.initFromJSON(this.editVital.toJSON());
          break;
        }
        case VitalMeasurementConstants.VITAL_TYPE_BMI: {
          this.newVitalEntry.bmi.initFromJSON(this.editVital.toJSON());
          break;
        }
        case VitalMeasurementConstants.VITAL_TYPE_PULSE: {
          this.newVitalEntry.pulse.initFromJSON(this.editVital.toJSON());
          break;
        }
        case VitalMeasurementConstants.VITAL_TYPE_TEMPERATURE: {
          this.newVitalEntry.temperature.initFromJSON(this.editVital.toJSON());
          break;
        }
        case VitalMeasurementConstants.VITAL_TYPE_SPO2: {
          this.newVitalEntry.spo2.initFromJSON(this.editVital.toJSON());
          break;
        }
        case VitalMeasurementConstants.VITAL_TYPE_RESPERATORY_RATE: {
          this.newVitalEntry.respiratoryRate.initFromJSON(
            this.editVital.toJSON()
          );
          break;
        }
        case VitalMeasurementConstants.VITAL_TYPE_BLOOD_GLUCOSE: {
          this.newVitalEntry.bloodGlucose.initFromJSON(this.editVital.toJSON());
          break;
        }
        case VitalMeasurementConstants.VITAL_TYPE_BLOOD_PRESSURE: {
          this.newVitalEntry.bloodPressure.initFromJSON(
            this.editVital.toJSON()
          );
          break;
        }
        case VitalMeasurementConstants.VITAL_TYPE_PAIN: {
          this.newVitalEntry.pain.initFromJSON(this.editVital.toJSON());
          break;
        }
      }
      this.showVitalEntryEditForm = true;
      this.editVital = null;
    }
  }

  addVitals() {
    this.showVitalEntryEditForm = !this.showVitalEntryEditForm;
  }

  saveNewVitals() {
    // save the vitals here.
    this.newVitalEntry.setCommonDetails(
      GlobalVariables.getMemberId(),
      this.patientUUId,
      new Date().getTime()
    );
    console.log(this.newVitalEntry);
    if (this.newVitalEntry != null && this.newVitalEntry.isValidForPush()) {
      this._vitalService
        .createOrUpdate(this.newVitalEntry)
        .then(() => {
          //TODO: Show relevant toast message
          this._notifyService.showSuccessMessage("Vital saved");
          this._patientVitalModalService.close();
        })
        .catch(err => {
          console.error(err);
          this._notifyService.showErrorMessage("Please try again");
        });
    } else {
      //TODO: Show relevant error message
      this._notifyService.showErrorMessage("Please try again");
    }
  }
  stopVitalEntry() {
    // reset the variables here.
    this.showVitalEntryEditForm = !this.showVitalEntryEditForm;
  }

  // Close Vital modal here
  closeVitalModal() {
    this._patientVitalModalService.close();
  }

  /**
   * Populate vital information
   */
  populateVitalInfo() {
    if (this.isOPDAppointmentChart) {
      this.getTodaysLatestVitalValues();
    } else {
      this.getLimitedVitals();
    }
  }

  //This function will be called only when patient profile is not opened from opd route
  getLimitedVitals() {
    this.getTodaysLatestVitalValues();
    this.vitalSamples = this.vitalSamples
      .map(val => {
        if (
          val["key"] == VitalMeasurementConstants.VITAL_TYPE_HEIGHT ||
          val["key"] == VitalMeasurementConstants.VITAL_TYPE_WEIGHT ||
          val["key"] == VitalMeasurementConstants.VITAL_TYPE_BMI
        ) {
          return val;
        } else {
          return undefined;
        }
      })
      .filter(Boolean);
  }

  initializeVitalMap() {
    let map = new Map<string, VitalMeasurement>();
    let height = new VitalMeasurement();
    height.vitalType = VitalMeasurementConstants.VITAL_TYPE_HEIGHT;
    height.measuredOn = 0;
    map.set(height.vitalType, height);

    let weight = new VitalMeasurement();
    weight.vitalType = VitalMeasurementConstants.VITAL_TYPE_WEIGHT;
    weight.measuredOn = 0;
    map.set(weight.vitalType, weight);

    let bmi = new VitalMeasurement();
    bmi.vitalType = VitalMeasurementConstants.VITAL_TYPE_BMI;
    bmi.measuredOn = 0;
    map.set(bmi.vitalType, bmi);

    return map;
  }

  getTodaysLatestVitalValues() {
    this.vitalSamples.splice(0, this.vitalSamples.length);
    let map = this.initializeVitalMap();
    for (let v of this.vitals) {
      if (
        DateActions.getDateString(new Date(v.measuredOn)) ==
        DateActions.getDateString(new Date())
      ) {
        let key = v.vitalType;
        if (v.vitalInfo) {
          key += v.vitalInfo;
        }
        if (!map.has(key) || map.get(key).measuredOn <= v.measuredOn) {
          map.set(key, v);
        }
      }
    }
    map.forEach((val, key) => {
      let tempJSON = {
        name: "",
        info: "",
        unit: "",
        value: "",
        unitInNewLine: false,
        key: key
      };
      if (val.vitalInfo) {
        tempJSON["info"] =
          val.vitalInfo.substring(0, 1).toUpperCase() +
          val.vitalInfo.substring(1);
        tempJSON["unitInNewLine"] = true;
      } else {
        tempJSON["info"] = "";
        tempJSON["unitInNewLine"] = false;
      }
      if (val.vitalType) {
        tempJSON["name"] = val.getVitalTypeString();
      }
      if (val.vitalValue1) {
        let s = "" + val.vitalValue1;
        if (val.vitalValue2) {
          s += "/" + val.vitalValue2;
        }
        tempJSON["value"] = s;
      }
      if (val.vitalUnit) {
        tempJSON["unit"] = val.vitalUnit;
      }
      this.vitalSamples.push(tempJSON);
    });
    this.sortVitals();
  }

  sortVitals() {
    let map = new Map<string, number>();
    map.set(VitalMeasurementConstants.VITAL_TYPE_HEIGHT, 1);
    map.set(VitalMeasurementConstants.VITAL_TYPE_WEIGHT, 2);
    map.set(VitalMeasurementConstants.VITAL_TYPE_BMI, 3);
    map.set(VitalMeasurementConstants.VITAL_TYPE_TEMPERATURE, 4);
    map.set(VitalMeasurementConstants.VITAL_TYPE_RESPERATORY_RATE, 5);
    map.set(VitalMeasurementConstants.VITAL_TYPE_PULSE, 6);
    map.set(VitalMeasurementConstants.VITAL_TYPE_SPO2, 7);
    map.set(
      VitalMeasurementConstants.VITAL_TYPE_BLOOD_GLUCOSE +
        VitalMeasurementConstants.FASTING,
      8
    );
    map.set(
      VitalMeasurementConstants.VITAL_TYPE_BLOOD_GLUCOSE +
        VitalMeasurementConstants.BEFORE_BREAKFAST,
      8.1
    );
    map.set(
      VitalMeasurementConstants.VITAL_TYPE_BLOOD_GLUCOSE +
        VitalMeasurementConstants.BEFORE_LUNCH,
      8.2
    );
    map.set(
      VitalMeasurementConstants.VITAL_TYPE_BLOOD_GLUCOSE +
        VitalMeasurementConstants.BEFORE_DINNER,
      8.3
    );
    map.set(
      VitalMeasurementConstants.VITAL_TYPE_BLOOD_GLUCOSE +
        VitalMeasurementConstants.AFTER_BREAKFAST,
      8.4
    );
    map.set(
      VitalMeasurementConstants.VITAL_TYPE_BLOOD_GLUCOSE +
        VitalMeasurementConstants.AFTER_LUNCH,
      8.5
    );
    map.set(
      VitalMeasurementConstants.VITAL_TYPE_BLOOD_GLUCOSE +
        VitalMeasurementConstants.AFTER_DINNER,
      8.6
    );
    map.set(
      VitalMeasurementConstants.VITAL_TYPE_BLOOD_GLUCOSE +
        VitalMeasurementConstants.BEDTIME,
      8.7
    );
    map.set(
      VitalMeasurementConstants.VITAL_TYPE_BLOOD_GLUCOSE +
        VitalMeasurementConstants.RANDOM,
      8.8
    );
    map.set(
      VitalMeasurementConstants.VITAL_TYPE_BLOOD_PRESSURE +
        VitalMeasurementConstants.SITTING,
      9.0
    );
    map.set(
      VitalMeasurementConstants.VITAL_TYPE_BLOOD_PRESSURE +
        VitalMeasurementConstants.STANDING,
      9.1
    );
    map.set(
      VitalMeasurementConstants.VITAL_TYPE_BLOOD_PRESSURE +
        VitalMeasurementConstants.SUPINE,
      9.2
    );
    this.vitalSamples.sort((a, b) => {
      if (a["key"] && map.has(a["key"]) && b["key"] && map.has(b["key"])) {
        return map.get(a["key"]) - map.get(b["key"]);
      } else {
        return 0;
      }
    });
  }
}

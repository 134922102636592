import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {VitalMeasurement} from '../../models/Vitals/Vital Measurement';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class PatientVitalModalService {

  private subjectModalOpenedAgain = new Subject<any>();

  constructor() { }

  public open(vital?: VitalMeasurement) {
    $('#patient-vital-modal').modal("show");
    this.subjectModalOpenedAgain.next(vital);
  }

  public clear(): void {
    this.subjectModalOpenedAgain.next();
  }

  public close(): void {
    $('#patient-vital-modal').modal("hide");
  }

  public modalOpenedAgain(): Observable<any> {
    return this.subjectModalOpenedAgain.asObservable();
  }

}

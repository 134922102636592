import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import {
  Medication,
  MedicationStatus
} from "src/app/shared/models/Medication/Medication";
import { SelectizeConfigs } from "src/app/shared/constants/object-keys";
import { MedicationConstants } from "src/app/shared/models/Medication/Constants/Medication";
import { MedicationSpecificTimingConstants } from "src/app/shared/models/Medication/Constants/Specific Timing";
import {
  VoiceService,
  VoiceAssistantStates
} from "src/app/shared/services/voice/voice.service";
import { SideSectionDialogManagementService } from "../../services/component-communication/side-section-dialog-management.service";
import { AssistantStateManagementService } from "../../services/component-communication/asssitant-state-management.service";

declare var jQuery: any;

@Component({
  selector: "app-medication-new",
  templateUrl: "./medication-new.component.html",
  styleUrls: ["./medication-new.component.css"]
})
export class MedicationNewComponent implements OnInit, OnChanges {
  medication: Medication;
  @Input() medicineForEditing: Medication;
  @Output() savedMedication = new EventEmitter<Medication>();
  @Output() close = new EventEmitter<boolean>();

  singleSelectConfig = SelectizeConfigs.singleSelectConfig;
  singleSelectWithCreateConfig = SelectizeConfigs.singleSelectWithCreateConfig;
  medicine_types = MedicationConstants.DOSAGE_FORM_TYPES;
  dose_types = MedicationConstants.DOSAGE_UNITS;
  frequency_text_options = Object.keys(
    MedicationConstants.FREQUENCY_TEXT_VALUE_OPTIONS_MAP
  ).map(object => {
    return { label: object, value: object };
  });
  duration_shorthand_options = Object.keys(
    MedicationConstants.DURATION_OPTIONS_MAP
  );

  route_types = MedicationConstants.ROUTE_TYPES;
  duration_units_singular = MedicationConstants.DURATION_UNITS;

  emptyStomach = MedicationSpecificTimingConstants.EMPTY_STOMACH;
  bedtime = MedicationSpecificTimingConstants.AT_BEDTIME;
  beforeMeals = MedicationSpecificTimingConstants.BEFORE_MEALS;
  afterMeals = MedicationSpecificTimingConstants.AFTER_MEALS;

  voiceAssistantActiveForComments = false;
  medicationInstructionBackupString = ""; //This is to handle partial voice commands

  // Frequency Dependent Variables to control UI

  timingShorthandSelected = false;

  // Variables for Duration
  selectedDurationText: string = "";

  constructor(
    private _voiceService: VoiceService,
    private _assistantStateManagementService: AssistantStateManagementService,
    private _sideSectionDialogManagementService: SideSectionDialogManagementService
  ) {}

  isDurationEqualToSelected(duration: string) {
    if (this.selectedDurationText == duration) {
      return true;
    } else {
      return false;
    }
  }

  //Close this new medicine form
  onCloseClicked() {
    // this.close.emit(true);
    // this._sideSectionDialogManagementService.hide();
    this._assistantStateManagementService.closeMedicationForm();
  }

  selectedPossibleTimingOption(i: number) {
    this.timingShorthandSelected = true;
    this.medication.setTimingFromPossibleOptions(i);
  }

  setFrequencyDependentUItoDefault() {
    // this.medication.resetFrequencyDependentUIVariables();
    this.timingShorthandSelected = false;
  }

  setDurationVariablestoDefault() {
    this.medication.resetDurationSelection();
    this.selectedDurationText = "";
  }

  observeForVoiceCommand() {
    this._voiceService.getMedicationJSON().subscribe(json => {
      if (json != null) {
        this.medication.initFromJSON(json);
        // Setting this again, as ngModel UI bindings resets these variables after initialisation
        setTimeout(time => {
          this.medication.initFromJSON(json);
          this._voiceService.clearMedicationJSON();
        }, 110);
        // console.log("Received Medication For Edit: ", this.medication);
      } else {
      }
    });

    this._voiceService.getMedicationComments().subscribe(voiceTextObject => {
      if (
        voiceTextObject &&
        ((voiceTextObject.partial_text &&
          voiceTextObject.partial_text.length > 0) ||
          (voiceTextObject.final_text && voiceTextObject.final_text.length > 0))
      ) {
        if (
          voiceTextObject.partial_text &&
          voiceTextObject.partial_text.length > 0
        ) {
          let instructions = this.medicationInstructionBackupString;
          if (!instructions) {
            instructions = "";
          }
          instructions += " " + voiceTextObject.partial_text;
          this.medication.instructions = instructions;
        } else if (
          voiceTextObject.final_text &&
          voiceTextObject.final_text.length > 0
        ) {
          let instructions = this.medicationInstructionBackupString;
          if (!instructions) {
            instructions = "";
          }
          instructions += " " + voiceTextObject.final_text;
          this.medication.instructions = instructions;
          this.medicationInstructionBackupString = instructions;
        }
      }
    });

    this._voiceService.observe().subscribe(state => {
      if (state != VoiceAssistantStates.medicationComment) {
        this.voiceAssistantActiveForComments = false;
      } else {
        this.voiceAssistantActiveForComments = true;
        this.medicationInstructionBackupString = this.medication.instructions;
      }
    });
  }

  invokeVoiceAssistantForComments() {
    if (!this.voiceAssistantActiveForComments) {
      this._voiceService.startPlainText();
      this.voiceAssistantActiveForComments = true;
    } else {
      this._voiceService.stop();
      this.voiceAssistantActiveForComments = false;
    }
  }

  medicationDurationChanged() {
    if (this.medication.durationIsNotEmpty()) {
      let foundIndex = -1;
      let potentialMatch = Object.values(
        MedicationConstants.DURATION_OPTIONS_MAP
      ).find((element, index) => {
        if (element.milis == this.medication.durationValue) {
          foundIndex = index;
          return true;
        }
      });
      if (potentialMatch && foundIndex > -1) {
        this.selectedDurationText = this.duration_shorthand_options[foundIndex];
      } else {
        this.selectedDurationText = "";
      }
    } else {
      this.selectedDurationText = "";
    }
  }

  setAllDynamicDropdownsToDefault() {
    this.route_types = MedicationConstants.ROUTE_TYPES;
    this.dose_types = MedicationConstants.DOSAGE_UNITS;
  }

  ngOnInit() {
    // if a new medication
    this.medication = new Medication();
    this.medication.durationUnit = "daily";
    this.observeForVoiceCommand();
    // console.log("Frequency Texts Array:", this.frequency_text_options);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.medicineForEditing) {
      this.medication = new Medication();
      let jsonForInitialization = this.medicineForEditing.toJSON();
      this.medication.initFromJSON(jsonForInitialization);
      console.log("Medication editing", this.medicineForEditing);
      // Setting this again, as ngModel UI bindings resets these variables after initialisation
      setTimeout(time => {
        this.medication.initFromJSON(jsonForInitialization);
        this.medicationInstructionBackupString = this.medication.instructions;
      }, 110);
      // console.log("Received Medication For Edit: ", this.medication);
    }
  }

  medicationSelectedViaSearch(event: {
    nameOrComposition: boolean;
    selectedMedication: Medication;
  }) {
    if (event.nameOrComposition) {
      this.medication.name = event.selectedMedication.name;
    }
    if (event.selectedMedication.composition) {
      this.medication.composition = event.selectedMedication.composition;
    }
    this.medication.route = event.selectedMedication.route;
    this.medication.dosageForm = event.selectedMedication.dosageForm;
  }

  compositionSelectedViaSearch(compositionText: string) {
    this.medication.composition = compositionText;
  }

  setDefaultsForFrequencyText() {
    this.setFrequencyDependentUItoDefault();
    // Rest of the logic is automatically handled in medication setter
  }

  setDefaultsForMedicationForm() {
    if (
      this.medicineForEditing == null ||
      this.medicineForEditing.dosageForm == null
    ) {
      console.log("Setting Route Defaults");
      if (this.medication.dosageForm && this.medication.dosageForm.length > 0) {
        // Pass the Route Filter
        this.setRouteDefaults();

        // Pass the Dose Unit Filter
        this.setDoseValueAndUnitDefaults();
      } else if (
        this.medication.dosageForm == null ||
        this.medication.dosageForm.length == 0
      ) {
        this.medication = new Medication();
        // this.medication.modeOfIntake = undefined;
        // this.medication.dosageTrend.dosageUnit = undefined;
        // this.medication.dosageTrend.dosageVal = undefined;
        // this.medication.frequencyText = undefined;
        this.medication.resetFrequencySelection();
        this.medication.resetTimingDosages();
        // this.medication.comments = undefined;
        this.setAllDynamicDropdownsToDefault();
        this.setFrequencyDependentUItoDefault();
        this.setDurationVariablestoDefault();
      }
    } else {
      console.log("Not Setting Route Defaults");
      this.medicineForEditing = null;
    }
  }

  private setRouteDefaults() {
    let {
      isFirstRouteDefault,
      filteredRouteArray
    } = this.medication.getPossibleRoutes();
    if (!this.medication.route) {
      this.route_types = filteredRouteArray;
      if (isFirstRouteDefault)
        this.medication.route = filteredRouteArray[0].value;
    } else {
      var foundInFilteredArray = filteredRouteArray.find(element => {
        return element.value == this.medication.route;
      });
      if (!foundInFilteredArray) {
        this.medication.route = undefined;
        setTimeout(() => {
          this.route_types = filteredRouteArray;
          if (isFirstRouteDefault)
            this.medication.route = filteredRouteArray[0].value;
        }, 100);
      } else {
        this.route_types = filteredRouteArray;
      }
    }
  }

  private setDoseValueAndUnitDefaults() {
    // console.log("Setting dose defaults for: ", this.medication.medicineType);
    let filteredDoseUnitArray = this.medication.setDefaultDoseValueAndGetFilteredDoseUnits();

    if (!this.medication.masterDosageTrend.dosageUnit) {
      this.dose_types = filteredDoseUnitArray;
      this.medication.masterDosageTrend.dosageUnit =
        filteredDoseUnitArray[0].value;
    } else {
      var foundInFilteredArray = filteredDoseUnitArray.find(element => {
        return element.value == this.medication.masterDosageTrend.dosageUnit;
      });
      if (!foundInFilteredArray) {
        this.medication.masterDosageTrend.dosageUnit = undefined;
        setTimeout(() => {
          this.dose_types = filteredDoseUnitArray;
          this.medication.masterDosageTrend.dosageUnit =
            filteredDoseUnitArray[0].value;
        }, 100);
      } else {
        this.dose_types = filteredDoseUnitArray;
      }
    }
  }

  saveMedication() {
    this._sideSectionDialogManagementService.sendBackToPrescription(
      this.medication,
      MedicationStatus.form_typed
    );
    this._sideSectionDialogManagementService.hide();
    this.clearNewMedicationForm();
  }

  clearNewMedicationForm() {
    this.medication = new Medication();
  }
}

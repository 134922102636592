import { BasicModelInterface } from "../BasicModelInterface";
import { ObjectKeys, AppointmentStatus } from "../../constants/object-keys";
import { Person } from "../Person";
import * as moment from "moment";

export class Appointment implements BasicModelInterface {
  private _appointmentId: string;
  private _date: string;
  private _doctorId: string;
  private _doctorName: string;
  private _locationId: string;
  private _locationName: string;
  private _locationGoogleMapsLink: string;
  private _organisationId: string;
  private _organisationName: string;
  private _timeslot: string;
  private _purposeOfVisitTitle: string;
  private _purposeOfVisitId: string;
  private _timeOfArrival: number;
  private _status: string;
  private _queueNumber: number;
  private _noteFromPatient: string;
  private _reasonForDecliningRequest: string;
  private _patientId: string;
  private _patientName: string;
  private _patientAge: number;
  private _patientAgeObject: {} = {};
  private _patientGender: string;
  private _patientPrimaryContactNumber: string;
  private _patientPrimaryEmail: string;
  private _billingId: string;
  private _totalAmountPaid: number;
  private _token: string;

  // private _transactionsArray:string; LATER. Make Object

  constructor() {}
  public toJSON(): {} {
    let json = {};
    if (this.appointmentId) {
      json[ObjectKeys.appointmentId] = this.appointmentId;
    }
    if (this.date) {
      json[ObjectKeys.date] = this.date;
    }
    if (this.doctorId) {
      json[ObjectKeys.doctorId] = this.doctorId;
    }
    if (this.doctorName) {
      json[ObjectKeys.doctorName] = this.doctorName;
    }
    if (this.locationId) {
      json[ObjectKeys.locationId] = this.locationId;
    }
    if (this.locationName) {
      json[ObjectKeys.locationName] = this.locationName;
    }
    if (this.locationGoogleMapsLink) {
      json[ObjectKeys.locationGoogleMapsLink] = this.locationGoogleMapsLink;
    }
    if (this.organisationId) {
      json[ObjectKeys.organisationId] = this.organisationId;
    }
    if (this.organisationName) {
      json[ObjectKeys.organisationName] = this.organisationName;
    }
    if (this.timeslot) {
      json[ObjectKeys.timeslot] = this.timeslot;
    }
    if (this.timeOfArrival) {
      json[ObjectKeys.timeOfArrival] = this.timeOfArrival;
    }
    if (this.purposeOfVisitId) {
      json[ObjectKeys.purposeOfVisitId] = this.purposeOfVisitId;
    }
    if (this.purposeOfVisitTitle) {
      json[ObjectKeys.purposeOfVisitTitle] = this.purposeOfVisitTitle;
    }
    if (this.status) {
      json[ObjectKeys.status] = this.status;
    }
    if (this.queueNumber) {
      json[ObjectKeys.queueNumber] = this.queueNumber;
    }
    if (this.noteFromPatient) {
      json[ObjectKeys.noteFromPatient] = this.noteFromPatient;
    }
    if (this.reasonForDecliningRequest) {
      json[
        ObjectKeys.reasonForDecliningRequest
      ] = this.reasonForDecliningRequest;
    }
    if (this.patientId) {
      json[ObjectKeys.patientId] = this.patientId;
    }
    if (this.patientName) {
      json[ObjectKeys.patientName] = this.patientName;
    }
    if (this.patientAge) {
      json[ObjectKeys.patientAge] = this.patientAge;
    }
    if (this.patientAgeObject) {
      json[ObjectKeys.patientAgeObject] = this.patientAgeObject;
    }
    if (this.patientGender) {
      json[ObjectKeys.patientGender] = this.patientGender;
    }
    if (this.patientPrimaryContactNumber) {
      json[
        ObjectKeys.patientPrimaryContactNumber
      ] = this.patientPrimaryContactNumber;
    }
    if (this.patientPrimaryEmail) {
      json[ObjectKeys.patientPrimaryEmail] = this.patientPrimaryEmail;
    }
    if (this.billingId) {
      json[ObjectKeys.billingId] = this.billingId;
    }
    if (this.totalAmountPaid) {
      json[ObjectKeys.totalAmountPaid] = this.totalAmountPaid;
    }
    if (this.token) {
      json[ObjectKeys.token] = this.token;
    }
    return json;
  }

  public initFromJSON(json: {}): void {
    if (json[ObjectKeys.appointmentId]) {
      this.appointmentId = json[ObjectKeys.appointmentId];
    }
    if (json[ObjectKeys.date]) {
      this.date = json[ObjectKeys.date];
    }
    if (json[ObjectKeys.doctorId]) {
      this.doctorId = json[ObjectKeys.doctorId];
    }
    if (json[ObjectKeys.doctorName]) {
      this.doctorName = json[ObjectKeys.doctorName];
    }
    if (json[ObjectKeys.locationId]) {
      this.locationId = json[ObjectKeys.locationId];
    }
    if (json[ObjectKeys.locationName]) {
      this.locationName = json[ObjectKeys.locationName];
    }
    if (json[ObjectKeys.locationGoogleMapsLink]) {
      this.locationGoogleMapsLink = json[ObjectKeys.locationGoogleMapsLink];
    }
    if (json[ObjectKeys.organisationId]) {
      this.organisationId = json[ObjectKeys.organisationId];
    }
    if (json[ObjectKeys.organisationName]) {
      this.organisationName = json[ObjectKeys.organisationName];
    }
    if (json[ObjectKeys.timeslot]) {
      this.timeslot = json[ObjectKeys.timeslot];
    }
    if (json[ObjectKeys.purposeOfVisitId]) {
      this.purposeOfVisitId = json[ObjectKeys.purposeOfVisitId];
    }
    if (json[ObjectKeys.purposeOfVisitTitle]) {
      this.purposeOfVisitTitle = json[ObjectKeys.purposeOfVisitTitle];
    }
    if (json[ObjectKeys.timeOfArrival]) {
      this.timeOfArrival = json[ObjectKeys.timeOfArrival];
    }
    if (json[ObjectKeys.status]) {
      this.status = json[ObjectKeys.status];
    }
    if (json[ObjectKeys.queueNumber]) {
      this.queueNumber = json[ObjectKeys.queueNumber];
    }
    if (json[ObjectKeys.noteFromPatient]) {
      this.noteFromPatient = json[ObjectKeys.noteFromPatient];
    }
    if (json[ObjectKeys.reasonForDecliningRequest]) {
      this.reasonForDecliningRequest =
        json[ObjectKeys.reasonForDecliningRequest];
    }
    if (json[ObjectKeys.patientId]) {
      this.patientId = json[ObjectKeys.patientId];
    }
    if (json[ObjectKeys.patientName]) {
      this.patientName = json[ObjectKeys.patientName];
    }
    if (json[ObjectKeys.patientGender]) {
      this.patientGender = json[ObjectKeys.patientGender];
    }
    if (json[ObjectKeys.patientAge]) {
      this.patientAge = json[ObjectKeys.patientAge];
    }
    if (json[ObjectKeys.patientAgeObject]) {
      this.patientAgeObject = json[ObjectKeys.patientAgeObject];
    }
    if (json[ObjectKeys.patientPrimaryContactNumber]) {
      this.patientPrimaryContactNumber =
        json[ObjectKeys.patientPrimaryContactNumber];
    }
    if (json[ObjectKeys.patientPrimaryEmail]) {
      this.patientPrimaryEmail = json[ObjectKeys.patientPrimaryEmail];
    }
    if (json[ObjectKeys.billingId]) {
      this.billingId = json[ObjectKeys.billingId];
    }
    if (json[ObjectKeys.totalAmountPaid]) {
      this.totalAmountPaid = json[ObjectKeys.totalAmountPaid];
    }
    if (json[ObjectKeys.token]) {
      this.token = json[ObjectKeys.token];
    }
  }

  /**
   * Getter billingId
   * @return {string}
   */
  public get billingId(): string {
    return this._billingId;
  }

  /**
   * Setter billingId
   * @param {string} value
   */
  public set billingId(value: string) {
    this._billingId = value;
  }

  /**
   * Getter totalAmountPaid
   * @return {number}
   */
  public get totalAmountPaid(): number {
    return this._totalAmountPaid;
  }

  /**
   * Setter totalAmountPaid
   * @param {number} value
   */
  public set totalAmountPaid(value: number) {
    this._totalAmountPaid = value;
  }

  /**
   * Getter appointmentId
   * @return {string}
   */
  public get appointmentId(): string {
    return this._appointmentId;
  }

  /**
   * Getter date
   * @return {string}
   */
  public get date(): string {
    return this._date;
  }

  /**
   * Getter doctorId
   * @return {string}
   */
  public get doctorId(): string {
    return this._doctorId;
  }

  /**
   * Getter doctorName
   * @return {string}
   */
  public get doctorName(): string {
    return this._doctorName;
  }

  /**
   * Getter locationId
   * @return {string}
   */
  public get locationId(): string {
    return this._locationId;
  }

  /**
   * Getter locationName
   * @return {string}
   */
  public get locationName(): string {
    return this._locationName;
  }

  /**
   * Getter locationGoogleMapsLink
   * @return {string}
   */
  public get locationGoogleMapsLink(): string {
    return this._locationGoogleMapsLink;
  }

  /**
   * Getter organisationId
   * @return {string}
   */
  public get organisationId(): string {
    return this._organisationId;
  }

  /**
   * Getter organisationName
   * @return {string}
   */
  public get organisationName(): string {
    return this._organisationName;
  }

  /**
   * Getter timeslot
   * @return {string}
   */
  public get timeslot(): string {
    return this._timeslot;
  }

  /**
   * Getter purposeOfVisitTitle
   * @return {string}
   */
  public get purposeOfVisitTitle(): string {
    return this._purposeOfVisitTitle;
  }

  /**
   * Getter purposeOfVisitId
   * @return {string}
   */
  public get purposeOfVisitId(): string {
    return this._purposeOfVisitId;
  }

  /**
   * Getter timeOfArrival
   * @return {number}
   */
  public get timeOfArrival(): number {
    return this._timeOfArrival;
  }

  /**
   * Getter status
   * @return {string}
   */
  public get status(): string {
    return this._status;
  }

  /**
   * Getter queueNumber
   * @return {number}
   */
  public get queueNumber(): number {
    return this._queueNumber;
  }

  /**
   * Getter noteFromPatient
   * @return {string}
   */
  public get noteFromPatient(): string {
    return this._noteFromPatient;
  }

  /**
   * Getter reasonForDecliningRequest
   * @return {string}
   */
  public get reasonForDecliningRequest(): string {
    return this._reasonForDecliningRequest;
  }

  /**
   * Getter patientId
   * @return {string}
   */
  public get patientId(): string {
    return this._patientId;
  }

  /**
   * Getter patientName
   * @return {string}
   */
  public get patientName(): string {
    return this._patientName;
  }

  /**
   * Getter patientAge
   * @return {number}
   */
  public get patientAge(): number {
    return this._patientAge;
  }

  /**
   * Getter patientAgeObject
   * @return {{}}
   */
  public get patientAgeObject() {
    return this._patientAgeObject;
  }

  /**
   * Getter patientGender
   * @return {string}
   */
  public get patientGender(): string {
    return this._patientGender;
  }

  /**
   * Getter patientPrimaryContactNumber
   * @return {string}
   */
  public get patientPrimaryContactNumber(): string {
    return this._patientPrimaryContactNumber;
  }

  /**
   * Getter patientPrimaryEmail
   * @return {string}
   */
  public get patientPrimaryEmail(): string {
    return this._patientPrimaryEmail;
  }

  /**
   * Setter appointmentId
   * @param {string} value
   */
  public set appointmentId(value: string) {
    this._appointmentId = value;
  }

  /**
   * Setter date
   * @param {string} value
   */
  public set date(value: string) {
    this._date = value;
  }

  /**
   * Setter doctorId
   * @param {string} value
   */
  public set doctorId(value: string) {
    this._doctorId = value;
  }

  /**
   * Setter doctorName
   * @param {string} value
   */
  public set doctorName(value: string) {
    this._doctorName = value;
  }

  /**
   * Setter locationId
   * @param {string} value
   */
  public set locationId(value: string) {
    this._locationId = value;
  }

  /**
   * Setter locationName
   * @param {string} value
   */
  public set locationName(value: string) {
    this._locationName = value;
  }

  /**
   * Setter locationGoogleMapsLink
   * @param {string} value
   */
  public set locationGoogleMapsLink(value: string) {
    this._locationGoogleMapsLink = value;
  }

  /**
   * Setter organisationId
   * @param {string} value
   */
  public set organisationId(value: string) {
    this._organisationId = value;
  }

  /**
   * Setter organisationName
   * @param {string} value
   */
  public set organisationName(value: string) {
    this._organisationName = value;
  }

  /**
   * Setter timeslot
   * @param {string} value
   */
  public set timeslot(value: string) {
    this._timeslot = value;
  }

  /**
   * Setter purposeOfVisitTitle
   * @param {string} value
   */
  public set purposeOfVisitTitle(value: string) {
    this._purposeOfVisitTitle = value;
  }

  /**
   * Setter purposeOfVisitId
   * @param {string} value
   */
  public set purposeOfVisitId(value: string) {
    this._purposeOfVisitId = value;
  }

  /**
   * Setter timeOfArrival
   * @param {number} value
   */
  public set timeOfArrival(value: number) {
    this._timeOfArrival = value;
  }

  /**
   * Setter status
   * @param {string} value
   */
  public set status(value: string) {
    this._status = value;
  }

  /**
   * Setter queueNumber
   * @param {number} value
   */
  public set queueNumber(value: number) {
    this._queueNumber = value;
  }

  /**
   * Setter noteFromPatient
   * @param {string} value
   */
  public set noteFromPatient(value: string) {
    this._noteFromPatient = value;
  }

  /**
   * Setter reasonForDecliningRequest
   * @param {string} value
   */
  public set reasonForDecliningRequest(value: string) {
    this._reasonForDecliningRequest = value;
  }

  /**
   * Setter patientId
   * @param {string} value
   */
  public set patientId(value: string) {
    this._patientId = value;
  }

  /**
   * Setter patientName
   * @param {string} value
   */
  public set patientName(value: string) {
    this._patientName = value;
  }

  /**
   * Setter patientAge
   * @param {number} value
   */
  public set patientAge(value: number) {
    this._patientAge = value;
  }

  /**
   * Setter patientAgeObject
   * @param { Object } value
   */
  public set patientAgeObject(value: {}) {
    this._patientAgeObject = value;
  }

  /**
   * Setter patientGender
   * @param {string} value
   */
  public set patientGender(value: string) {
    this._patientGender = value;
  }

  /**
   * Setter patientPrimaryContactNumber
   * @param {string} value
   */
  public set patientPrimaryContactNumber(value: string) {
    this._patientPrimaryContactNumber = value;
  }

  /**
   * Getter token
   * @return {string}
   */
  public get token(): string {
    return this._token;
  }

  /**
   * Setter token
   * @param {string} value
   */
  public set token(value: string) {
    this._token = value;
  }

  /**
   * Setter patientPrimaryEmail
   * @param {string} value
   */
  public set patientPrimaryEmail(value: string) {
    this._patientPrimaryEmail = value;
  }

  /**
   * This is to display patint's age and gender
   */

  public get sex_age() {
    let str = "";
    if (this.patientGender != null) {
      str += Person.genderCamelCaseConversion(this.patientGender);
    }
    if (this.patientAgeObject != null && this.patientAgeObject["years"] >= 0) {
      if (this.patientAgeObject["years"] > 0) {
        str += ", " + this.patientAgeObject["years"] + " Yr";
      }
      if (this.patientAgeObject["years"] <= 0) {
        if (this.patientAgeObject["months"] > 0) {
          str += ", " + this.patientAgeObject["months"] + " Mn";
        }
        if (this.patientAgeObject["months"] <= 0) {
          if (this.patientAgeObject["days"] > 0) {
            str += ", " + this.patientAgeObject["days"] + " D";
          }
        }
      }
    }
    return str;
  }

  public getContactNumber() {
    if (this.patientPrimaryContactNumber) {
      if (
        this.patientPrimaryContactNumber["countryCode"] &&
        this.patientPrimaryContactNumber["number"]
      ) {
        return (
          this.patientPrimaryContactNumber["countryCode"] +
          this.patientPrimaryContactNumber["number"]
        );
      } else if (
        this.patientPrimaryContactNumber["countryCode"] &&
        !this.patientPrimaryContactNumber["number"]
      ) {
        return null;
      } else {
        return this.patientPrimaryContactNumber;
      }
    } else {
      return null;
    }
  }

  public getTimeToDisplay() {
    if (this.timeslot) {
      return this.timeslot.substring(0, 5) + " (Scheduled)";
    } else if (this.timeOfArrival) {
      let m = moment(this.timeOfArrival);
      let aTime = m.format("HH:mm") + " (Walk In)";
      return aTime;
    } else {
      return null;
    }
  }

  public getOnlyTimeToDisplay() {
    if (this.timeslot) {
      return this.timeslot.substring(0, 5) + "";
    } else if (this.timeOfArrival) {
      let m = moment(this.timeOfArrival);
      let aTime = m.format("HH:mm");
      return aTime;
    } else {
      return null;
    }
  }

  public tweleveHoursFormat() {
    if (this.timeslot) {
      let m = moment(this.timeslot.substring(0, 5) + "", "hh:mm");
      let aTime = m.format("hh:mm a");
      return aTime;
    } else if (this.timeOfArrival) {
      let m = moment(this.timeOfArrival);
      let aTime = m.format("hh:mm a");
      return aTime;
    } else {
      return null;
    }
  }

  public getOnlyTime() {
    if (this.timeslot) {
      return this.timeslot.substring(0, 5) + "";
    } else if (this.timeOfArrival) {
      let m = moment(this.timeOfArrival);
      let aTime = m.format("HH:mm");
      return aTime;
    } else {
      return null;
    }
  }

  public getStatusForDisplay() {
    switch (this.status) {
      case AppointmentStatus.APPOINTMENT_CONFIRMED:
        return "SCHEDULED";
      default:
        return this.status;
    }
  }

  public isScheduled(): boolean {
    return this.status == AppointmentStatus.APPOINTMENT_CONFIRMED;
  }

  public isCancelled(): boolean {
    return this.status == AppointmentStatus.APPOINTMENT_CANCELLED;
  }

  public isInQueue(): boolean {
    return this.status == AppointmentStatus.APPOINTMENT_ARRIVED;
  }
  public isCompleted(): boolean {
    return this.status == AppointmentStatus.APPOINTMENT_COMPLETED;
  }
  public getBadgeText(): string {
    return this.timeslot != null ? "SCHEDULED" : "WALK-IN";
  }
}

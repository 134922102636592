import { Component, OnInit } from "@angular/core";
import { GlobalVariables } from "../../globar-var/globarVariables";
import {
  PlanInfo,
  OrganisationMember,
  Organisation
} from "../../shared/models/MD - Member";
import { DateActions } from "../../shared/models/Demographics";
import { OrganisationProfileOperationsService } from "../../shared/services/organisation/organisation-profile-operations.service";
import { Router } from "@angular/router";
import * as firebase from "firebase";
import { NotifyService } from "src/app/shared/services/notify/notify.service";
import { TokenRegistrationService } from "src/app/shared/services/token-registration/token-registration.service";
import { Intercom } from "ng-intercom";
import { AppointmentOperationsService } from "../../shared/services/opd/appointment-operations.service";
import { Patient } from "../../shared/models/Patient/Patient";
import { ScheduleAppointmentModalService } from "../../shared/services/modalStateManagement/schedule-appointment-modal.service";

@Component({
  selector: "[app-header]",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"]
})
export class HeaderComponent implements OnInit {
  defaultImagePath: string = "assets/images/img-2.jpg";
  memberName: string = "";
  memberEmail: string = "";
  memberPhotoUrl: string;
  currentActivePlan: PlanInfo = new PlanInfo();

  constructor(
    private _organisationProfileOperationsService: OrganisationProfileOperationsService,
    private _scheduleAppointmentModalService: ScheduleAppointmentModalService,
    private _router: Router,
    private _notifyService: NotifyService,
    private _tokenRegistrationService: TokenRegistrationService,
    private _intercom: Intercom,
    private _appointmentOperationsService: AppointmentOperationsService
  ) {
    this.memberName = GlobalVariables.getMemberName();
    this.memberEmail = GlobalVariables.getMember().primaryEmail;
    this.memberPhotoUrl =
      GlobalVariables.getMember().photoUrl || this.defaultImagePath;
  }

  ngOnInit() {
    this.fetchLoggedInMemberProfile();
    this._intercom.boot({
      custom_launcher_selector: "#customIntercomButton",
      name: this.memberName,
      email: this.memberEmail
    });
  }

  private currentPlanActive() {
    this.currentActivePlan = GlobalVariables.getOrganisation().currentActivePlan(
      DateActions.getDateString(new Date())
    );
  }

  private fetchLoggedInMemberProfile() {
    if (GlobalVariables.getMemberId()) {
      this._organisationProfileOperationsService
        .getMemberInfo(GlobalVariables.getMemberId())
        .then(response => {
          if (response) {
            let statusCode = response["statusCode"];
            if (statusCode && statusCode == 200) {
              let body = response["body"];
              let dataJSON = body["data"];
              let member: OrganisationMember = new OrganisationMember();
              member.initFromJSON(dataJSON);
              GlobalVariables.setMember(member);
              this.memberPhotoUrl = member.photoUrl || this.defaultImagePath;
              let organisationJSON = body["organisation"];
              let organisation: Organisation = new Organisation();
              organisation.initFromJSON(organisationJSON);
              GlobalVariables.setOrganisation(organisation);

              let membersJSONArray = body["members"];
              for (let memJSON of membersJSONArray) {
                let member: OrganisationMember = new OrganisationMember();
                member.initFromJSON(memJSON);
                GlobalVariables.setMemberInMap(member);
              }
              //TODO: check if the plan is not selected then redirect it to plan selection page.
              this.checkForPlans();
              this.currentPlanActive();
              // console.log(
              //   "MEMBER PROFILE RESPONSE",
              //   GlobalVariables.member,
              //   GlobalVariables.organisation,
              //   GlobalVariables.membersMap
              // );
            } else {
              //handle error code
            }
          }
        })
        .catch(err => {
          console.error(err);
        });
    }
  }

  private checkForPlans() {
    // console.log(
    //   "PLAN INFO",
    //   GlobalVariables.getOrganisation().planInfo == null,
    //   GlobalVariables.getOrganisation().planInfo.size == 0,
    //   !GlobalVariables.getOrganisation().hasActivePlan(
    //     DateActions.getDateString(new Date())
    //   )
    // );
    if (
      GlobalVariables.getOrganisation().planInfo == null ||
      GlobalVariables.getOrganisation().planInfo.size == 0 ||
      !GlobalVariables.getOrganisation().hasActivePlan(
        DateActions.getDateString(new Date())
      )
    ) {
      this._router.navigateByUrl("plan-and-billing/choose-plan");
    }
  }

  goToSettings() {
    this._router.navigateByUrl("settings");
  }

  goToProfile() {
    this._router.navigateByUrl(
      "settings/user-accounts-list/" + GlobalVariables.getMemberId()
    );
  }

  bookAppointment(patient: Patient) {
    this._scheduleAppointmentModalService.openWithJSON({
      type: "patientId",
      patientId: patient.docvitaId
    });
  }

  requestToAddToQueue(map: Map<string, any>) {
    let patient = <Patient>map.get("patient");
    let todayDate = DateActions.getDateString(new Date());
    let selectedLocationId = <string>map.get("locationId");
    let selectedDoctorId = <string>map.get("selectedDoctorId");
    let selectedDoctorName;
    let selectedLocationName;
    if (GlobalVariables.getOrganisation().locations.size > 0) {
      GlobalVariables.getOrganisation().locations.forEach((val, key) => {
        if (selectedLocationId == val.organisationLocationId) {
          selectedLocationName = val.title;
        }
      });
    }
    GlobalVariables.getOpdScheduleAvailableMembersMap(
      GlobalVariables.getOrganisationId()
    ).forEach((val, key) => {
      if (selectedDoctorId == val.docvitaId) {
        selectedDoctorName = val.name;
      }
    });
    if (
      selectedDoctorId &&
      selectedDoctorName &&
      selectedLocationId &&
      selectedLocationName &&
      patient
    ) {
      this._appointmentOperationsService
        .addToQueue(
          GlobalVariables.getOrganisationId(),
          GlobalVariables.getOrganisationName(),
          selectedDoctorId,
          selectedDoctorName,
          patient,
          todayDate,
          selectedLocationId,
          selectedLocationName
        )
        .then(resp => {
          if (resp) {
            this._notifyService.showSuccessMessage("Patient added to queue");
          } else {
            this._notifyService.showErrorMessage("Please try again");
          }
        })
        .catch(err => {
          console.error(err);
          this._notifyService.showErrorMessage("Please try again");
        });
    } else {
      this._notifyService.showErrorMessage("Please try again");
    }
  }

  logout() {
    firebase
      .messaging()
      .getToken()
      .then(token => {
        this.signout(token);
      })
      .catch(err => {
        this.signout();
      });
  }

  signout(token?: string) {
    if (token) {
      this._tokenRegistrationService.deregisterToken(
        GlobalVariables.getMemberId(),
        GlobalVariables.getOrganisationId(),
        token
      );
    }
    this._router.navigateByUrl("auth");
    firebase.auth().signOut();
    GlobalVariables.clearValues();
    this._notifyService.showSuccessMessage("Signing out...");
  }
}

export class DentalConstants {
  public static examinationSelectGroups: any = [
    {
      group: "regressiveChanges",
      label: "Regressive Changes"
    },
    {
      group: "impacted",
      label: "Impacted"
    },
    {
      group: "toothInvestigation",
      label: "Tooth Investigation"
    },
    {
      group: "cariesSurfaceInvolved",
      label: "Caries Surface Involved"
    },
    {
      group: "fracture",
      label: "Fracture"
    },
    {
      group: "restoration",
      label: "Restoration"
    },
    {
      group: "endodontic",
      label: "Endodontic"
    },
    {
      group: "extraction",
      label: "Extraction"
    },
    {
      group: "denture",
      label: "Denture"
    },
    {
      group: "retained",
      label: "Retained"
    },
    {
      group: "supraEruption",
      label: "Supra-Eruption"
    }
  ];

  public static examinationSelectOptions: any = [
    {
      group: "retained",
      groupLabel: "Retained",
      value: "retained",
      label: "Retained"
    },
    {
      group: "supraEruption",
      groupLabel: "Supra-Eruption",
      value: "supraEruption",
      label: "Supra-Eruption"
    },
    {
      group: "regressiveChanges",
      groupLabel: "Regressive Changes",
      value: "attrition",
      label: "Attrition"
    },
    {
      group: "regressiveChanges",
      groupLabel: "Regressive Changes",
      value: "abrasion",
      label: "Abrasion"
    },
    {
      group: "regressiveChanges",
      groupLabel: "Regressive Changes",
      value: "abfraction",
      label: "Abfraction"
    },
    {
      group: "regressiveChanges",
      groupLabel: "Regressive Changes",
      value: "erosion",
      label: "Erosion"
    },
    {
      group: "impacted",
      groupLabel: "Impacted",
      value: "partiallyImpacted",
      label: "Partially Impacted"
    },
    {
      group: "impacted",
      groupLabel: "Impacted",
      value: "completelyImpacted",
      label: "Completely Impacted"
    },
    {
      group: "toothInvestigation",
      groupLabel: "Tooth Investigation",
      value: "missingTooth",
      label: "Missing Tooth"
    },
    {
      group: "toothInvestigation",
      groupLabel: "Tooth Investigation",
      value: "implant",
      label: " Implant"
    },
    {
      group: "toothInvestigation",
      groupLabel: "Tooth Investigation",
      value: "periapicalAbcess",
      label: "Periapical Abcess"
    },
    {
      group: "cariesSurfaceInvolved",
      groupLabel: "Caries Surface Involved",
      value: "mesial",
      label: "Mesial"
    },
    {
      group: "cariesSurfaceInvolved",
      groupLabel: "Caries Surface Involved",
      value: "distal",
      label: "Distal"
    },
    {
      group: "cariesSurfaceInvolved",
      groupLabel: "Caries Surface Involved",
      value: "buccal",
      label: "Buccal"
    },
    {
      group: "cariesSurfaceInvolved",
      groupLabel: "Caries Surface Involved",
      value: "ligual/Palatal",
      label: "Ligual/Palatal"
    },
    {
      group: "cariesSurfaceInvolved",
      groupLabel: "Caries Surface Involved",
      value: "occlusal",
      label: "Occlusal"
    },
    {
      group: "fracture",
      groupLabel: "Fracture",
      value: "root",
      label: "Root"
    },
    {
      group: "fracture",
      groupLabel: "Fracture",
      value: "crown",
      label: "Crown"
    },
    {
      group: "fracture",
      groupLabel: "Fracture",
      value: "cervical",
      label: "Cervical"
    },
    {
      group: "fracture",
      groupLabel: "Fracture",
      value: "insical",
      label: "Insical"
    },
    {
      group: "restoration",
      groupLabel: "Restoration",
      value: "crown",
      label: "Crown"
    },
    {
      group: "restoration",
      groupLabel: "Restoration",
      value: "bridge",
      label: "Bridge"
    },
    {
      group: "restoration",
      groupLabel: "Restoration",
      value: "defectiveCrown",
      label: "Defective Crown"
    },
    {
      group: "restoration",
      groupLabel: "Restoration",
      value: "amalgamFilling",
      label: "Amalgam Filling"
    },
    {
      group: "restoration",
      groupLabel: "Restoration",
      value: "compositeFilling",
      label: "Composite Filling"
    },
    {
      group: "restoration",
      groupLabel: "Restoration",
      value: "gicFilling",
      label: "GIC Filling"
    },
    {
      group: "endodontic",
      groupLabel: "Endodontic",
      value: "rootCanal",
      label: "Root Canal"
    },
    {
      group: "endodontic",
      groupLabel: "Endodontic",
      value: "ImproperObturation",
      label: "Improper Obturation"
    },
    {
      group: "endodontic",
      groupLabel: "Endodontic",
      value: "postAndCore",
      label: "Post and Core"
    },
    {
      group: "extraction",
      groupLabel: "Extraction",
      value: "properlyExtracted",
      label: "Properly Extracted"
    },
    {
      group: "extraction",
      groupLabel: "Extraction",
      value: "rootPiece",
      label: "Root Piece"
    },
    {
      group: "denture",
      groupLabel: "Denture",
      value: "rpd",
      label: "RPD"
    },
    {
      group: "denture",
      groupLabel: "Denture",
      value: "castPartial",
      label: "Cast Partial"
    },
    {
      group: "denture",
      groupLabel: "Denture",
      value: "completeDenture",
      label: "Complete Denture"
    }
  ];

  public static treatmentSelectGroups: any = [
    {
      group: "scaling",
      label: "Scaling"
    },
    {
      group: "fillings",
      label: "Fillings"
    },
    {
      group: "rpd",
      label: "RPD"
    },
    {
      group: "ulCompleteDenture",
      label: "U/L Complete Denture"
    },
    {
      group: "orthodonticTreatment",
      label: "Orthodontic Treatment"
    },
    {
      group: "smileDesign",
      label: "Smile Design"
    },
    {
      group: "bleaching",
      label: "Bleaching"
    },
    {
      group: "extraction",
      label: "Extraction"
    },
    {
      group: "disimpaction",
      label: "Disimpaction"
    },
    {
      group: "implants",
      label: "Implants"
    },
    {
      group: "periodontalSurgery",
      label: "Periodontal Surgery"
    },
    {
      group: "otherMinorSurgicalProcedures",
      label: "Other Minor Surgical Procedures"
    },
    {
      group: "temporaryCrown",
      label: "Temporary Crown"
    },
    {
      group: "fluorideTreatment",
      label: "Fluoride Treatment"
    },
    {
      group: "pitAndFissureSealant",
      label: "Pit And Fissure Sealant"
    },
    {
      group: "nightGuard",
      label: "Night Guard"
    },
    {
      group: "rct",
      label: "RCT"
    },
    {
      group: "rerct",
      label: "Re-RCT"
    },
    {
      group: "postCore",
      label: "Post & Core"
    },
    {
      group: "crownBridge",
      label: "Crown / Bridge"
    }
  ];

  public static treatmentSelectOptions: any = [
    {
      group: "scaling",
      value: "partial",
      label: "Partial",
      groupLabel: "Scaling"
    },
    {
      group: "scaling",
      value: "full",
      label: "Full",
      groupLabel: "Scaling"
    },
    {
      group: "fillings",
      value: "composite",
      label: "Composite",
      groupLabel: "Fillings"
    },
    {
      group: "fillings",
      value: "gic",
      label: "GIC",
      groupLabel: "Fillings"
    },
    {
      group: "fillings",
      value: "silverFilling",
      label: "Silver Filling",
      groupLabel: "Fillings"
    },
    {
      group: "rpd",
      value: "acrylic",
      label: "Acrylic",
      groupLabel: "RPD"
    },
    {
      group: "rpd",
      value: "flexible",
      label: "Flexible",
      groupLabel: "RPD"
    },
    {
      group: "rpd",
      value: "castPD",
      label: "Cast PD",
      groupLabel: "RPD"
    },
    {
      group: "ulCompleteDenture",
      groupLabel: "U/L Complete Denture",
      value: "ulCompleteDentureAcrylic",
      label: "Acrylic"
    },
    {
      group: "ulCompleteDenture",
      groupLabel: "U/L Complete Denture",
      value: "ulCompleteDentureFlexible",
      label: "Flexible"
    },
    {
      group: "orthodonticTreatment",
      groupLabel: "Orthodontic Treatment",
      value: "metal",
      label: "Metal"
    },
    {
      group: "orthodonticTreatment",
      groupLabel: "Orthodontic Treatment",
      value: "lingual",
      label: "Lingual"
    },
    {
      group: "orthodonticTreatment",
      groupLabel: "Orthodontic Treatment",
      value: "ceramic",
      label: "Ceramic"
    },
    {
      group: "orthodonticTreatment",
      groupLabel: "Orthodontic Treatment",
      value: "activeOrtho",
      label: "Active Ortho"
    },
    {
      group: "orthodonticTreatment",
      groupLabel: "Orthodontic Treatment",
      value: "invisalign",
      label: "Invisalign"
    },
    {
      group: "smileDesign",
      groupLabel: "Smile Design",
      value: "smileDesign",
      label: "Smile Design"
    },
    {
      group: "bleaching",
      groupLabel: "Bleaching",
      value: "bleaching",
      label: "Bleaching"
    },
    {
      group: "extraction",
      groupLabel: "Extraction",
      value: "extraction",
      label: "Extraction"
    },
    {
      group: "disimpaction",
      groupLabel: "Disimpaction",
      value: "disimpaction",
      label: "Disimpaction"
    },
    {
      group: "implants",
      groupLabel: "Implants",
      value: "implants",
      label: "Implants"
    },
    {
      group: "periodontalSurgery",
      groupLabel: "Periodontal Surgery",
      value: "periodontalSurgery",
      label: "Periodontal Surgery"
    },
    {
      group: "otherMinorSurgicalProcedures",
      groupLabel: "Other Minor Surgical Procedures",
      value: "otherMinorSurgicalProcedures",
      label: "Other Minor Surgical Procedures"
    },
    {
      group: "temporaryCrown",
      groupLabel: "Temporary Crown",
      value: "temporaryCrown",
      label: "Temporary Crown"
    },
    {
      group: "fluorideTreatment",
      groupLabel: "Fluoride Treatment",
      value: "fluorideTreatment",
      label: "Fluoride Treatment"
    },
    {
      group: "pitAndFissureSealant",
      groupLabel: "Pit And Fissure Sealant",
      value: "pitAndFissureSealant",
      label: "Pit And Fissure Sealant"
    },
    {
      group: "nightGuard",
      groupLabel: "Night Guard",
      value: "nightGuard",
      label: "Night Guard"
    },
    {
      group: "rct",
      groupLabel: "RCT",
      value: "rct",
      label: "RCT"
    },
    {
      group: "rerct",
      groupLabel: "Re-RCT",
      value: "rerct",
      label: "Re-RCT"
    },
    {
      group: "postCore",
      groupLabel: "Post & Core",
      value: "postCore",
      label: "Post & Core"
    },
    {
      group: "crownBridge",
      groupLabel: "Crown / Bridge",
      value: "crownBridge",
      label: "Crown / Bridge"
    }
  ];
}

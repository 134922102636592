import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgSelectizeModule } from "ng-selectize";
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import { environment } from "../environments/environment";

//Layout Modules
import { CommonLayoutComponent } from "./common/common-layout.component";

//Directives
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { Sidebar_Directives } from "./shared/directives/side-nav.directive";
import { Cards_Directives } from "./shared/directives/cards.directive";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

// Routing Module
import { AppRoutes } from "./app.routing";

// Firebase
import * as firebase from "firebase";

// App Component
import { AppComponent } from "./app.component";
import { ToastyModule } from "ng2-toasty";
import { PatientRegistrationComponent } from "./patient-registration/patient-registration.component";
import { SharedModule } from "./shared/shared.module";
import { NewAppointmentComponent } from "./new-appointment/new-appointment.component";
import { HttpTokenInterceptorService } from "./shared/services/auth/http-token-interceptor.service";
import { DragulaModule } from "ng2-dragula";
import { LottieAnimationViewModule } from "ng-lottie";
import { ServiceWorkerModule } from "@angular/service-worker";
import { HeaderComponent } from "./common/header/header.component";
import { TopNavbarComponent } from "./common/top-navbar/top-navbar.component";
import { PatientHeaderComponent } from "./common/patient-header/patient-header.component";
import { PatientChartNavbarComponent } from "./common/patient-chart-navbar/patient-chart-navbar.component";
import { DocvitaAssistantComponent } from "./common/docvita-assistant/docvita-assistant.component";
import { VoiceAssistantMessageCellComponent } from "./common/voice-assistant-message-cell/voice-assistant-message-cell.component";
import { AssistantQuickActionsComponent } from "./common/assistant-quick-actions/assistant-quick-actions.component";
import { IntercomModule } from "ng-intercom";
import * as env from "../environments/environment";
import { FullCalendarModule } from "@fullcalendar/angular";
import { BillingComponentComponent } from "./billing-component/billing-component.component";
import { NgxMaskModule, IConfig } from "ngx-mask";
import { AudioRecordingService } from "./shared/services/audioRecorder/audio-recording.service";
import { HotkeysService } from "./shared/services/hotkeys/hotkeys.service";
import { AutofocusModule } from "angular-autofocus-fix";
import { AutofocusDirective } from "./shared/directives/autofocus.directive";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";

export var options: Partial<IConfig> | (() => Partial<IConfig>);
declare global {
  interface Window {
    analytics: any;
  }
}

@NgModule({
  imports: [
    BrowserModule,
    RouterModule.forRoot(AppRoutes, { useHash: true }),
    NgbModule.forRoot(),
    ToastyModule.forRoot(),
    FormsModule,
    NgSelectizeModule,
    HttpClientModule,
    SharedModule,
    PerfectScrollbarModule,
    ReactiveFormsModule,
    DragulaModule.forRoot(),
    LottieAnimationViewModule.forRoot(),
    ServiceWorkerModule.register("/combined-worker.js", { enabled: true }),
    IntercomModule.forRoot({
      appId: env.environment.intercomId, // from your Intercom config
      updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    }),
    NgxMaskModule.forRoot(options),
    FullCalendarModule,

    NoopAnimationsModule
  ],
  declarations: [
    AppComponent,
    PatientRegistrationComponent,
    CommonLayoutComponent,
    Sidebar_Directives,
    Cards_Directives,
    NewAppointmentComponent,
    HeaderComponent,
    TopNavbarComponent,
    PatientHeaderComponent,
    PatientChartNavbarComponent,
    DocvitaAssistantComponent,
    VoiceAssistantMessageCellComponent,
    AssistantQuickActionsComponent,
    AutofocusDirective
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptorService,
      multi: true
    },
    AudioRecordingService,
    HotkeysService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    this.initializeApp();
  }
  initializeApp(): void {
    firebase.initializeApp(environment.firebaseConfig);
    if (firebase.messaging.isSupported()) {
      firebase.messaging().usePublicVapidKey(environment.firebaseMessagingKey);
    }
  }
}

import { Person } from "../Person";
import { BasicModelInterface } from "../BasicModelInterface";
import { ObjectKeys } from "../../constants/object-keys";
import { Allergy } from "./Allergy";
import { PastMedicalHistory } from "./PastMedicalHistory";
import { DentalHistory } from "./DentalHistory";
import { MedicalHistory } from "./MedicalHistory";
import { Habit } from "./Habit";
import { CurrentMedication } from "./CurrentMedication";

export class Patient extends Person implements BasicModelInterface {
  // Unique Identifiers for our system
  private _docvitaId: string;

  // Basic Info - Inherit from Person Object

  // Health Information
  private _bloodGroup: string;
  private _allergies: Map<string, Allergy> = new Map(); // Object Maps
  private _pastMedicalHistory: Map<string, PastMedicalHistory> = new Map(); // Object Map
  private _dentalHistory: Map<string, DentalHistory> = new Map();
  private _medicalHistory: Map<string, MedicalHistory> = new Map();
  private _habits: Map<string, Habit> = new Map();
  private _currentMedication: Map<string, CurrentMedication> = new Map();

  // Latest Vitals
  private _weight: string; // Object
  private _height: string; // Object
  private _bmi: string; // Object

  // Connections
  private _organisationConnections: Map<string, ConnectedOrganisationInfo>;

  constructor() {
    super();
  }

  toJSON(): {} {
    let json = super.toJSON();
    if (this.docvitaId) {
      json[ObjectKeys.docvitaId] = this.docvitaId;
    }
    if (this.bloodGroup) {
      json[ObjectKeys.bloodGroup] = this.bloodGroup;
    }
    if (this.allergies) {
      let temp = {};
      this.allergies.forEach((val, key) => {
        temp[key] = val.toJSON();
      });
      json[ObjectKeys.allergies] = temp;
    }
    if (this.pastMedicalHistory) {
      let temp = {};
      this.pastMedicalHistory.forEach((val, key) => {
        temp[key] = val.toJSON();
      });
      json[ObjectKeys.pastMedicalHistory] = temp;
    }
    // other vitals...
    if (this.organisationConnections) {
      let tempJSON = {};
      this.organisationConnections.forEach((val, key) => {
        tempJSON[key] = val.toJSON();
      });
      json[ObjectKeys.organisationConnections] = tempJSON;
    }
    return json;
  }

  initFromJSON(json: {}): void {
    super.initFromJSON(json);
    if (json[ObjectKeys.docvitaId]) {
      this.docvitaId = json[ObjectKeys.docvitaId];
    }
    if (json[ObjectKeys.bloodGroup]) {
      this.bloodGroup = json[ObjectKeys.bloodGroup];
    }
    if (json[ObjectKeys.allergies]) {
      let temp = json[ObjectKeys.allergies];
      let map: Map<string, Allergy> = new Map();
      for (let k in temp) {
        let a = new Allergy();
        a.initFromJSON(temp[k]);
        map.set(k, a);
      }
      this.allergies = map;
    }
    if (json[ObjectKeys.pastMedicalHistory]) {
      let temp = json[ObjectKeys.pastMedicalHistory];
      let map: Map<string, PastMedicalHistory> = new Map();
      for (let k in temp) {
        let a = new PastMedicalHistory();
        a.initFromJSON(temp[k]);
        map.set(k, a);
      }
      this.pastMedicalHistory = map;
    }
    //Habits
    if (json[ObjectKeys.habits]) {
      let temp = json[ObjectKeys.habits];
      let map: Map<string, Habit> = new Map();
      for (let k in temp) {
        let a = new Habit();
        a.initFromJSON(temp[k]);
        map.set(k, a);
      }
      this.habits = map;
    }
    //Current Medications
    if (json[ObjectKeys.currentMedication]) {
      let temp = json[ObjectKeys.currentMedication];
      let map: Map<string, CurrentMedication> = new Map();
      for (let k in temp) {
        let a = new CurrentMedication();
        a.initFromJSON(temp[k]);
        map.set(k, a);
      }
      this.currentMedication = map;
    }
    //Dental History
    if (json[ObjectKeys.dentalHistory]) {
      let temp = json[ObjectKeys.dentalHistory];
      let map: Map<string, DentalHistory> = new Map();
      for (let k in temp) {
        let a = new DentalHistory();
        a.initFromJSON(temp[k]);
        map.set(k, a);
      }
      this.dentalHistory = map;
    }
    //Medical History
    if (json[ObjectKeys.medicalHistory]) {
      let temp = json[ObjectKeys.medicalHistory];
      let map: Map<string, MedicalHistory> = new Map();
      for (let k in temp) {
        let a = new MedicalHistory();
        a.initFromJSON(temp[k]);
        map.set(k, a);
      }
      this.medicalHistory = map;
    }
    // other vitals...
    if (json[ObjectKeys.organisationConnections]) {
      let map: Map<string, ConnectedOrganisationInfo> = new Map();
      let tempJSON = json[ObjectKeys.organisationConnections];
      for (let k in tempJSON) {
        let c: ConnectedOrganisationInfo = new ConnectedOrganisationInfo();
        c.initFromJSON(tempJSON[k]);
        map.set(k, c);
      }
      this.organisationConnections = map;
    }
  }

  /**
   * Getter docvitaId
   * @return {string}
   */
  public get docvitaId(): string {
    return this._docvitaId;
  }

  /**
   * Getter bloodGroup
   * @return {string}
   */
  public get bloodGroup(): string {
    return this._bloodGroup;
  }

  /**
   * Getter weight
   * @return {string}
   */
  public get weight(): string {
    return this._weight;
  }

  /**
   * Getter height
   * @return {string}
   */
  public get height(): string {
    return this._height;
  }

  /**
   * Getter bmi
   * @return {string}
   */
  public get bmi(): string {
    return this._bmi;
  }

  /**
   * Getter organisationConnections
   * @return {Map<string, ConnectedOrganisationInfo>}
   */
  public get organisationConnections(): Map<string, ConnectedOrganisationInfo> {
    return this._organisationConnections;
  }

  /**
   * Setter docvitaId
   * @param {string} value
   */
  public set docvitaId(value: string) {
    this._docvitaId = value;
  }

  /**
   * Setter bloodGroup
   * @param {string} value
   */
  public set bloodGroup(value: string) {
    this._bloodGroup = value;
  }

  /**
   * Setter weight
   * @param {string} value
   */
  public set weight(value: string) {
    this._weight = value;
  }

  /**
   * Setter height
   * @param {string} value
   */
  public set height(value: string) {
    this._height = value;
  }

  /**
   * Setter bmi
   * @param {string} value
   */
  public set bmi(value: string) {
    this._bmi = value;
  }

  /**
   * Setter organisationConnections
   * @param {Map<string, ConnectedOrganisationInfo>} value
   */
  public set organisationConnections(
    value: Map<string, ConnectedOrganisationInfo>
  ) {
    this._organisationConnections = value;
  }

  /**
   * Getter allergies
   * @return {Map<string,Allergy> }
   */
  public get allergies(): Map<string, Allergy> {
    return this._allergies;
  }

  /**
   * Setter allergies
   * @param {Map<string,Allergy> } value
   */
  public set allergies(value: Map<string, Allergy>) {
    this._allergies = value;
  }

  /**
   * Getter pastMedicalHistory
   * @return {Map<string,PastMedicalHistory> }
   */
  public get pastMedicalHistory(): Map<string, PastMedicalHistory> {
    return this._pastMedicalHistory;
  }

  /**
   * Setter pastMedicalHistory
   * @param {Map<string,PastMedicalHistory> } value
   */
  public set pastMedicalHistory(value: Map<string, PastMedicalHistory>) {
    this._pastMedicalHistory = value;
  }

  /**
   * Getter currentMedication
   * @return {Map<string,CurrentMedication> }
   */
  public get currentMedication(): Map<string, CurrentMedication> {
    return this._currentMedication;
  }

  /**
   * Setter currentMedication
   * @param {Map<string,CurrentMedication> } value
   */
  public set currentMedication(value: Map<string, CurrentMedication>) {
    this._currentMedication = value;
  }

  /**
   * Getter medicalHistory
   * @return {Map<string,MedicalHistory> }
   */
  public get medicalHistory(): Map<string, MedicalHistory> {
    return this._medicalHistory;
  }

  /**
   * Setter medicalHistory
   * @param {Map<string,MedicalHistory> } value
   */
  public set medicalHistory(value: Map<string, MedicalHistory>) {
    this._medicalHistory = value;
  }

  /**
   * Getter dentalHistory
   * @return {Map<string,DentalHistory> }
   */
  public get dentalHistory(): Map<string, DentalHistory> {
    return this._dentalHistory;
  }

  /**
   * Setter dentalHistory
   * @param {Map<string,DentalHistory> } value
   */
  public set dentalHistory(value: Map<string, DentalHistory>) {
    this._dentalHistory = value;
  }

  /**
   * Getter habits
   * @return {Map<string,Habit> }
   */
  public get habits(): Map<string, Habit> {
    return this._habits;
  }

  /**
   * Setter habits
   * @param {Map<string,Habit> } value
   */
  public set habits(value: Map<string, Habit>) {
    this._habits = value;
  }
}

export class ConnectedOrganisationInfo implements BasicModelInterface {
  private _connectionId: string;
  private _organisationId: string;
  private _connectedById: string;
  private _connectedByName: string;
  private _connectedOn: number; // timestamp
  private _lastInteractedOn: number; // timestamp
  private _healthInfoAccessStatus: boolean;
  private _healthInfoAccessUpdatedOn: number; // timestamp
  private _uhid: string;

  toJSON(): {} {
    let json = {};
    if (this.connectionId) {
      json[ObjectKeys.connectionId] = this.connectionId;
    }
    if (this.organisationId) {
      json[ObjectKeys.organisationId] = this.organisationId;
    }
    if (this.connectedById) {
      json[ObjectKeys.connectedById] = this.connectedById;
    }
    if (this.connectedByName) {
      json[ObjectKeys.connectedByName] = this.connectedByName;
    }
    if (this.connectedOn) {
      json[ObjectKeys.connectedOn] = this.connectedOn;
    }
    if (this.lastInteractedOn) {
      json[ObjectKeys.lastInteractedOn] = this.lastInteractedOn;
    }
    if (this.healthInfoAccessStatus) {
      json[ObjectKeys.healthInfoAccessStatus] = this.healthInfoAccessStatus;
    }
    if (this.uhid) {
      json[ObjectKeys.uhid] = this.uhid;
    }
    if (this.healthInfoAccessUpdatedOn) {
      json[
        ObjectKeys.healthInfoAccessUpdatedOn
      ] = this.healthInfoAccessUpdatedOn;
    }
    return json;
  }
  initFromJSON(json: {}): void {
    if (json[ObjectKeys.connectionId]) {
      this.connectionId = json[ObjectKeys.connectionId];
    }
    if (json[ObjectKeys.uhid]) {
      this.uhid = json[ObjectKeys.uhid];
    }
    if (json[ObjectKeys.organisationId]) {
      this.organisationId = json[ObjectKeys.organisationId];
    }
    if (json[ObjectKeys.connectedById]) {
      this.connectedById = json[ObjectKeys.connectedById];
    }
    if (json[ObjectKeys.connectedByName]) {
      this.connectedByName = json[ObjectKeys.connectedByName];
    }
    if (json[ObjectKeys.connectedOn]) {
      this.connectedOn = json[ObjectKeys.connectedOn];
    }
    if (json[ObjectKeys.lastInteractedOn]) {
      this.lastInteractedOn = json[ObjectKeys.lastInteractedOn];
    }
    if (json[ObjectKeys.healthInfoAccessStatus]) {
      this.healthInfoAccessStatus = json[ObjectKeys.healthInfoAccessStatus];
    }
    if (json[ObjectKeys.healthInfoAccessUpdatedOn]) {
      this.healthInfoAccessUpdatedOn =
        json[ObjectKeys.healthInfoAccessUpdatedOn];
    }
  }

  /**
   * Getter uhid
   * @return {string}
   */
  public get uhid(): string {
    return this._uhid;
  }

  /**
   * Setter uhid
   * @param {string} value
   */
  public set uhid(value: string) {
    this._uhid = value;
  }

  /**
   * Getter connectionId
   * @return {string}
   */
  public get connectionId(): string {
    return this._connectionId;
  }

  /**
   * Setter connectionId
   * @param {string} value
   */
  public set connectionId(value: string) {
    this._connectionId = value;
  }

  /**
   * Getter organisationId
   * @return {string}
   */
  public get organisationId(): string {
    return this._organisationId;
  }

  /**
   * Setter organisationId
   * @param {string} value
   */
  public set organisationId(value: string) {
    this._organisationId = value;
  }

  /**
   * Getter connectedById
   * @return {string}
   */
  public get connectedById(): string {
    return this._connectedById;
  }

  /**
   * Setter connectedById
   * @param {string} value
   */
  public set connectedById(value: string) {
    this._connectedById = value;
  }

  /**
   * Getter connectedByName
   * @return {string}
   */
  public get connectedByName(): string {
    return this._connectedByName;
  }

  /**
   * Setter connectedByName
   * @param {string} value
   */
  public set connectedByName(value: string) {
    this._connectedByName = value;
  }

  /**
   * Getter connectedOn
   * @return {number}
   */
  public get connectedOn(): number {
    return this._connectedOn;
  }

  /**
   * Setter connectedOn
   * @param {number} value
   */
  public set connectedOn(value: number) {
    this._connectedOn = value;
  }

  /**
   * Getter lastInteractedOn
   * @return {number}
   */
  public get lastInteractedOn(): number {
    return this._lastInteractedOn;
  }

  /**
   * Setter lastInteractedOn
   * @param {number} value
   */
  public set lastInteractedOn(value: number) {
    this._lastInteractedOn = value;
  }

  /**
   * Getter healthInfoAccessStatus
   * @return {boolean}
   */
  public get healthInfoAccessStatus(): boolean {
    return this._healthInfoAccessStatus;
  }

  /**
   * Setter healthInfoAccessStatus
   * @param {boolean} value
   */
  public set healthInfoAccessStatus(value: boolean) {
    this._healthInfoAccessStatus = value;
  }

  /**
   * Getter healthInfoAccessUpdatedOn
   * @return {number}
   */
  public get healthInfoAccessUpdatedOn(): number {
    return this._healthInfoAccessUpdatedOn;
  }

  /**
   * Setter healthInfoAccessUpdatedOn
   * @param {number} value
   */
  public set healthInfoAccessUpdatedOn(value: number) {
    this._healthInfoAccessUpdatedOn = value;
  }
}

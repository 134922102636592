import { Component, OnInit, Input } from "@angular/core";
import { ViewAppointmentDialogService } from "../../services/modalStateManagement/view-appointment-dialog.service";
import { Appointment } from "../../models/Scheduling/Appointment";
import { AppointmentStatus } from "../../constants/object-keys";
import { AppointmentOperationsService } from "../../services/opd/appointment-operations.service";
import { NotifyService } from "../../services/notify/notify.service";
import { ScheduleAppointmentModalService } from "../../services/modalStateManagement/schedule-appointment-modal.service";
import { SideSectionDialogManagementService } from "../../services/component-communication/side-section-dialog-management.service";
import { AssistantStateManagementService } from "../../services/component-communication/asssitant-state-management.service";
import { Router, ActivatedRoute } from "@angular/router";
import { ConfirmationDialogService } from "../../services/confirmation-dialog/confirmation-dialog.service";

@Component({
  selector: "app-view-appointment-dialog",
  templateUrl: "./view-appointment-dialog.component.html",
  styleUrls: ["./view-appointment-dialog.component.css"]
})
export class ViewAppointmentDialogComponent implements OnInit {
  @Input() appointment: Appointment = new Appointment();
  isRefreshing = false;
  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _viewAppointmentDialogService: ViewAppointmentDialogService,
    private _appointmentOperationsService: AppointmentOperationsService,
    private _notifyService: NotifyService,
    private _scheduleAppointmentModalService: ScheduleAppointmentModalService,
    private _assistantStateManagementService: AssistantStateManagementService,
    private _confirmationDialogService: ConfirmationDialogService
  ) {}

  ngOnInit() {
    this._viewAppointmentDialogService.listener().subscribe(val => {
      if (val) {
        console.log("val===>", val);
        this.appointment = val;
      }
    });
  }

  closeThisModal() {
    this._viewAppointmentDialogService.close();
  }

  onCompleteClicked() {
    this.isRefreshing = true;
    this._appointmentOperationsService
      .complete(this.appointment)
      .then(resp => {
        this.isRefreshing = false;
        if (resp && resp["statusCode"] && resp["statusCode"] == 200) {
          this._notifyService.showSuccessMessage("Appointment completed");
          this.closeThisModal();
        } else {
          this._notifyService.showErrorMessage("Please try again");
        }
      })
      .catch(err => {
        this._notifyService.showErrorMessage("Please try again");
        this.isRefreshing = false;
      });
    this.closeThisModal();
  }

  onCancelClicked() {
    this.isRefreshing = true;
    this._appointmentOperationsService
      .cancel(this.appointment)
      .then(resp => {
        this.isRefreshing = false;
        if (resp && resp["statusCode"] && resp["statusCode"] == 200) {
          this._notifyService.showSuccessMessage("Appointment cancelled");
          this.closeThisModal();
        } else {
          this._notifyService.showErrorMessage("Please try again");
        }
      })
      .catch(err => {
        this._notifyService.showErrorMessage("Please try again");
        this.isRefreshing = false;
      });
  }

  onDeleteClicked() {
    this._appointmentOperationsService
      .delete(this.appointment)
      .then(res => {
        console.log("deleted");
      })
      .catch(() => console.log("error while deleting the appointment"));
  }

  onArriveClicked() {
    this.isRefreshing = true;
    this._appointmentOperationsService
      .arrive(this.appointment)
      .then(resp => {
        this.isRefreshing = false;
        if (resp && resp["statusCode"] && resp["statusCode"] == 200) {
          this._notifyService.showSuccessMessage("Patient arrived");
          this.closeThisModal();
        } else {
          this._notifyService.showErrorMessage("Please try again");
        }
      })
      .catch(err => {
        this._notifyService.showErrorMessage("Please try again");
        this.isRefreshing = false;
      });
    this.closeThisModal();
  }

  onViewProfileClicked() {
    //Here go to patient profile
    this.closeThisModal();
    let path =
      this._router.url +
      "/patient-detail/" +
      this.appointment.patientId +
      "/home";
    this._router.navigateByUrl(path);
    // this._notifyService.showSuccessMessage("Coming soon: Take the user to patient's profile")
  }

  onRescheduleClicked() {
    this._scheduleAppointmentModalService.selectedAppointment(this.appointment);
    this._scheduleAppointmentModalService.openForRescheduling();
    this.closeThisModal();
  }

  addToQueueVisibility() {
    if (
      this.appointment &&
      this.appointment.status &&
      this.appointment.status == AppointmentStatus.APPOINTMENT_CONFIRMED
    ) {
      return true;
    } else {
      return false;
    }
  }
  completeVisibility() {
    if (
      this.appointment &&
      this.appointment.status &&
      (this.appointment.status == AppointmentStatus.APPOINTMENT_CONFIRMED ||
        this.appointment.status == AppointmentStatus.APPOINTMENT_ARRIVED)
    ) {
      return true;
    } else {
      return false;
    }
  }
  cancelVisibility() {
    if (
      this.appointment &&
      this.appointment.status &&
      (this.appointment.status == AppointmentStatus.APPOINTMENT_CONFIRMED ||
        this.appointment.status == AppointmentStatus.APPOINTMENT_ARRIVED)
    ) {
      return true;
    } else {
      return false;
    }
  }
  rescheduleVisibility() {
    if (
      this.appointment &&
      this.appointment.status &&
      (this.appointment.status == AppointmentStatus.APPOINTMENT_CONFIRMED ||
        this.appointment.status == AppointmentStatus.APPOINTMENT_ARRIVED)
    ) {
      return true;
    } else {
      return false;
    }
  }
}

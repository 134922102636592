import { Injectable } from "@angular/core";
import { GetTokenService } from "./get-token.service";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from "@angular/common/http";
import { Observable, Observer, from } from "rxjs";
import { switchMap } from "rxjs/operators";
import * as env from "../../../../environments/environment";
@Injectable({
  providedIn: "root"
})
export class HttpTokenInterceptorService implements HttpInterceptor {
  constructor(public _getTokenService: GetTokenService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return from(this._getTokenService.token()).pipe(
      switchMap(token => {
        let headersConfig = {};
        // console.log(req.headers);
        if (req.headers == null || req.headers["Content-Type"] == null) {
          headersConfig = {
            "Content-Type": "application/json",
            Accept: "application/json"
          };
        }

        if (token) {
          headersConfig["Authorization"] = "provider " + token;
        }
        const request = req.clone({ setHeaders: headersConfig });
        return next.handle(request);
      })
    );
  }
}

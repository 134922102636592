import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
  AfterViewInit,
  OnChanges
} from "@angular/core";
import * as moment from "moment";
import { ActivatedRoute, Router, NavigationEnd } from "@angular/router";
import {
  DateActions,
  OrganisationLocationObject
} from "src/app/shared/models/Demographics";
import { DateChangedNotifierService } from "src/app/shared/services/component-communication/date-changed-notifier.service";
import { Subscription } from "rxjs";
import { OrganisationMember } from "src/app/shared/models/MD - Member";
import { SelectizeConfigs } from "src/app/shared/constants/object-keys";
import { GlobalVariables } from "src/app/globar-var/globarVariables";
import { NotifyService } from "../../shared/services/notify/notify.service";
@Component({
  selector: "[app-top-navbar]",
  templateUrl: "./top-navbar.component.html",
  styleUrls: ["./top-navbar.component.css"]
})
export class TopNavbarComponent implements OnInit, OnDestroy, AfterViewInit {
  start_date: any;
  selectedDate: string;
  @Output() dateChanged: EventEmitter<string> = new EventEmitter();
  today: string;
  displayDate: boolean = false;
  routerSubscription: Subscription;

  locationObject: OrganisationLocationObject;
  locationOptions = [];
  location: string = ""; //This is for saving selected location id
  doctors: Map<string, OrganisationMember> = new Map();
  selectedDoctorId = "";
  loadingDoctorsList: boolean = false;
  availableDoctorOptions = [];
  singleSelectConfig = SelectizeConfigs.singleSelectConfig;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _notifyService: NotifyService
  ) {}

  ngOnInit() {
    this.today = DateActions.getDateString(new Date());
    this.initializeDate();
    this.routerSubscription = this._router.events.subscribe(val => {
      if (val instanceof NavigationEnd) {
        if (this.pathHasDoctorId()) {
          this.selectDoctor(this.pathHasDoctorId());
        }
        if (this.pathHasLocationId()) {
          this.location = this.pathHasLocationId();
        }
      }
    });
    this.loadAvailableLocations();
    this.getDoctorsList();
  }

  ngAfterViewInit() {
    $(".selectize-input input").attr("readonly", "readonly");
    this.onDoctorChanged();
    this.onLocationChange();
  }

  /**
   * Available doctor list for selected date and location
   * @param date
   * @param locationId
   */
  getDoctorsList() {
    this.doctors.clear();
    this.availableDoctorOptions.splice(0, this.doctors.size);
    GlobalVariables.getOpdScheduleAvailableMembersMap(
      GlobalVariables.getOrganisationId()
    ).forEach((val, key) => {
      let temp = {};
      temp["label"] = val.name;
      temp["value"] = key;
      this.availableDoctorOptions.push(temp);
      this.doctors.set(key, val);
    });
    this.availableDoctorOptions.sort((a, b) => {
      return (a.value + "").localeCompare(b.value + "");
    });
    let docId = GlobalVariables.getMemberId();
    if (
      !GlobalVariables.getMember().getOpdScheduleAvailability(
        GlobalVariables.getOrganisationId()
      ) &&
      this.availableDoctorOptions.length > 0
    ) {
      docId = this.availableDoctorOptions[0]["value"];
    }
    if (this.pathHasDoctorId()) {
      this.selectDoctor(this.pathHasDoctorId());
    } else {
      this.selectDoctor(docId);
    }
  }

  currentRouteIsOPD() {
    let url = this._router.url;
    return url && url.indexOf("opd") > -1;
  }

  pathHasDoctorId() {
    let id = null;
    let url = this._router.url;
    if (
      url &&
      (url.indexOf("opd/today") > -1 || url.indexOf("opd/calendar") > -1)
    ) {
      let localStrArray = url.split("/");
      // console.log(localStrArray);
      if (localStrArray && localStrArray.length >= 4) {
        id = localStrArray[4];
      }
    }
    return id;
  }

  pathHasLocationId() {
    let id = null;
    let url = this._router.url;
    if (
      url &&
      (url.indexOf("opd/today") > -1 || url.indexOf("opd/calendar") > -1)
    ) {
      let localStrArray = url.split("/");
      // console.log(localStrArray);
      if (localStrArray && localStrArray.length >= 4) {
        id = localStrArray[3];
      }
    }
    return id;
  }

  selectDoctor(memberId: string) {
    if (this.availableDoctorOptions.length > 0) {
      for (let a of this.availableDoctorOptions) {
        if (a["value"] == memberId) {
          this.selectedDoctorId = memberId;
        }
      }
    }
  }

  onDoctorChanged() {
    if (this.location && this.selectedDoctorId) {
      let url = this._router.url + "";
      if (url != null && url.search("opd/today") > -1) {
        let dateVal = DateActions.getDateString(new Date());
        let splitURL = url.split("/");
        if (splitURL && splitURL.length > 5) {
          dateVal = splitURL[5];
        }
        this._router.navigateByUrl(
          "opd/today/" +
            this.location +
            "/" +
            this.selectedDoctorId +
            "/" +
            dateVal
        );
      } else if (url != null && url.search("opd/calendar") > -1) {
        this._router.navigateByUrl(
          "opd/calendar/" + this.location + "/" + this.selectedDoctorId
        );
      }
    }
  }

  onLocationChange() {
    if (this.location && this.selectedDoctorId) {
      let url = this._router.url + "";
      if (url != null && url.search("opd/today") > -1) {
        let dateVal = DateActions.getDateString(new Date());
        let splitURL = url.split("/");
        if (splitURL && splitURL.length > 5) {
          dateVal = splitURL[5];
        }
        this._router.navigateByUrl(
          "opd/today/" +
            this.location +
            "/" +
            this.selectedDoctorId +
            "/" +
            dateVal
        );
      } else if (url != null && url.search("opd/calendar") > -1) {
        this._router.navigateByUrl(
          "opd/calendar/" + this.location + "/" + this.selectedDoctorId
        );
      }
    }
  }

  /**
   * Load locations from organisation obejct
   */
  loadAvailableLocations() {
    GlobalVariables.getOrganisation().locations.forEach((val, key) => {
      this.location = key;
      let json = {};
      json["label"] = val.title;
      json["value"] = key;
      this.locationOptions.push(json);
    });

    if (this.pathHasLocationId()) {
      this.location = this.pathHasLocationId();
    } else {
      if (this.locationOptions.length > 0) {
        this.location = this.locationOptions[0]["value"];
      }
    }
  }

  dateDisplayCheck() {
    let url = this._router.url + "";
    if (url != null && url.search("opd/today") > -1) {
      this.displayDate = true;
    } else {
      this.displayDate = false;
    }
  }

  initializeDate() {
    this.dateDisplayCheck();
    if (this.displayDate) {
      let url = this._router.url + "";
      let path = "opd/today";
      url = url.substring(url.indexOf(path) + path.length);
      if (url.length > 10) {
        let dateInURL = url.substring(1, 11);
        let d = moment(dateInURL, "YYYY-MM-DD");
        this.selectedDate = dateInURL;
        this.start_date = {
          day: d.get("date"),
          month: d.get("month") + 1,
          year: d.get("year")
        };
      }
    } else {
      let today = new Date(Date.now());
      this.selectedDate = DateActions.getDateString(today);
      this.start_date = {
        day: today.getDate(),
        month: today.getMonth() + 1,
        year: today.getFullYear()
      };
    }
    // this.dateChangedNotifierService.onDateChanged(this.selectedDate);
  }

  selectThisDate() {
    this.selectedDate = DateActions.getDateString(
      new Date(
        this.start_date.year,
        this.start_date.month - 1,
        this.start_date.day
      )
    );
    // this.dateChangedNotifierService.onDateChanged(this.selectedDate);
  }

  calendarDateSelected(date: string) {
    let m = moment(date, "YYYY-MM-DD");
    this.selectedDate = m.format("YYYY-MM-DD");
    // update start_date here
    this.start_date = {
      year: m.year(),
      month: m.month() + 1,
      day: m.date()
    };
    this.selectThisDate();
  }

  loadPreviousDate() {
    let m = moment(this.selectedDate, "YYYY-MM-DD");
    m.add("days", -1);
    this.selectedDate = m.format("YYYY-MM-DD");
    // update start_date here
    this.start_date = {
      year: m.year(),
      month: m.month() + 1,
      day: m.date()
    };
    this.selectThisDate();
  }
  loadNextDate() {
    let m = moment(this.selectedDate, "YYYY-MM-DD");
    m.add("days", 1);
    this.selectedDate = m.format("YYYY-MM-DD");
    // update start_date here
    this.start_date = {
      year: m.year(),
      month: m.month() + 1,
      day: m.date()
    };
    this.selectThisDate();
  }

  hiddenDatePickerChanged() {
    this.selectThisDate();
  }

  ngOnDestroy() {
    if (this.routerSubscription) this.routerSubscription.unsubscribe();
  }

  comingSoon() {
    this._notifyService.showSuccessMessage("Coming Soon");
  }
}

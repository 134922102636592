import { Component, OnInit } from "@angular/core";
import { AssistantStateManagementService } from "../../services/component-communication/asssitant-state-management.service";

@Component({
  selector: "app-meds-top-fifty",
  templateUrl: "./meds-top-fifty.component.html",
  styleUrls: ["./meds-top-fifty.component.css"]
})
export class MedsTopFiftyComponent implements OnInit {
  constructor(
    private _assistantStateManagementService: AssistantStateManagementService
  ) {}

  ngOnInit() {}

  onCloseClicked() {
    this._assistantStateManagementService.closeTop50MedsList();
  }
}

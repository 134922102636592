import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { SpeechRecognizerService } from "../../web-speech/services/speech-recognizer.service";
import { Subscription } from "rxjs";
import { SpeechNotification } from "../../web-speech/model/speech-notification";
import { NotifyService } from "../../services/notify/notify.service";
import {
  AssistantStateManagementService,
  CurrentAssistantState
} from "../../services/component-communication/asssitant-state-management.service";
import { SideSectionDialogManagementService } from "../../services/component-communication/side-section-dialog-management.service";
import {
  VoiceAssistantMessage,
  VoiceAssistantMessageStatus
} from "../../models/VoiceAssistantMessage";
import { Patient } from "../../models/Patient/Patient";
import { PatientProfileSyncService } from "../../services/component-communication/patient-profile-sync.service";
import { Router } from "@angular/router";
import { ViewAppointmentDialogService } from "../../services/modalStateManagement/view-appointment-dialog.service";
import { AudioRecordingService } from "../../services/audioRecorder/audio-recording.service";
import { DomSanitizer } from "@angular/platform-browser";
import { GlobalVariables } from "../../../globar-var/globarVariables";
import { Medication } from "../../models/Medication/Medication";
import { Appointment } from "../../models/Scheduling/Appointment";
import { SpeechError } from "../../web-speech/model/speech-error";
import { DictationCaller, DictationHandlerService } from "../../services/dictation-handler.service";

@Component({
  selector: "app-dictation",
  templateUrl: "./dictation.component.html",
  styleUrls: ["./dictation.component.css"]
})
export class DictationComponent implements OnInit {
  isVoiceModeON = false;
  startAnimating: boolean = true;
  lottieConfig: Object;
  liveSpokenText: string = "";
  finalTranscript: string = "";

  newDentalChartEntryShowBool: boolean = false;
  viewAppointmentShowBool: boolean = false;

  patient: Patient = new Patient();
  patientDocVitaId: string;

  assistantStateManagementSubscriotion: Subscription;
  patientProfileSyncSubscription: Subscription;

  @Output() finalText: EventEmitter<string> = new EventEmitter();
  @Output() liveText: EventEmitter<string> = new EventEmitter();
  @Input() dictactionCaller: DictationCaller;

  isBrowserSupportedForVoice: boolean = false;

  constructor(
    private _notifyService: NotifyService,
    private _dictationHandlerService:DictationHandlerService
  ) {}

  ngOnInit() {
    
  }

  toggleVoiceState() {
    if (this.isBrowserSupportedForVoice) {
      if (!this.isVoiceModeON) {
        alert();
        setTimeout(time => {
          
          this.isVoiceModeON = true;
        }, 200);
      } else {
        setTimeout(time => {
          this.stopVoiceAssistant();
        }, 150);
      }
    } else {
      this._notifyService.showErrorMessage(
        "Voice functionality is not supported by this browser. Upgrade to Google Chrome version 25 or later."
      );
    }
  }

  stopVoiceAssistant() {
    if (this.isBrowserSupportedForVoice) {
      
    }
    this.isVoiceModeON = false;
  }

  startVoiceAssistant(): boolean {
    if (this.isBrowserSupportedForVoice) {
      

      return true;
    } else {
      this._notifyService.showErrorMessage(
        "Voice functionality is not supported by this browser. We recommend using Google Chrome version 25 or later on PC / Android devices."
      );
      return false;
    }
  }

  getLottieConfig(): {} {
    return {
      path: "../../../assets/lottie/google-voice-assistant.json",
      renderer: "canvas",
      autoplay: true,
      loop: true
    };
  }

  ngOnDestroy() {
    if (this.patientProfileSyncSubscription)
      this.patientProfileSyncSubscription.unsubscribe();
    if (this.assistantStateManagementSubscriotion)
      this.assistantStateManagementSubscriotion.unsubscribe();
  }
}

import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ObjectKeys } from "../../constants/object-keys";
import * as urls from "../../../../environments/environment";
import { OrganisationMember } from "../../models/MD - Member";
import { GlobalVariables } from "src/app/globar-var/globarVariables";

@Injectable({
  providedIn: "root"
})
export class OrganisationProfileOperationsService {
  constructor(private _http: HttpClient) {}

  /**
   * Fetch member object
   */
  public getMemberInfo(memberId: string) {
    return new Promise((resolve, reject) => {
      let options = {};
      let params = {};
      params["memberId"] = memberId;
      params[ObjectKeys.organisationId] = GlobalVariables.getOrganisationId();
      options["params"] = params;
      let val = this._http
        .get(urls.environment["member-profile"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public getOpdSmsStatus(memberId: string) {
    return new Promise((resolve, reject) => {
      let options = {};
      let params = {};
      params["memberId"] = memberId;
      params[ObjectKeys.organisationId] = GlobalVariables.getOrganisationId();
      options["params"] = params;
      console.log("Url is=>", urls.environment["opd-sms-status"], options);
      let val = this._http
        .get(urls.environment["opd-sms-status"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public setOpdSmsStatus(memberId: string, organisationId: string, receiveDailyOPDSMS: boolean) {
    return new Promise((resolve, reject) => {
      let body = {}
      body["memberId"] = memberId;
      body["organisationId"] = organisationId;
      body["receiveDailyOPDSMS"] = receiveDailyOPDSMS;
      let val = this._http
        .post(urls.environment["opd-sms-status"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) { 
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Create member object
   */
  public createMemberInfo(member:OrganisationMember,designation:string, organisationId: string, organisationName:string) {
    return new Promise((resolve, reject) => {
      let body = member.toJSON()
      body["organisationId"] = organisationId
      body["organisationName"] = organisationName
      if(designation){
        body["designation"] = designation
      }
      let val = this._http
        .post(urls.environment["member-profile"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Update member object
   */
  public updateMemberInfo(memberId:string,name:string, organisationId: string, designation:string) {
    return new Promise((resolve, reject) => {
      let body = {}
      body["organisationId"] = organisationId
      body["memberId"] = memberId
      body["name"] = name
      if(designation){
        body["designation"] = designation
      }
      let val = this._http
        .put(urls.environment["member-profile"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }
}

import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  AfterViewInit,
  ElementRef,
  AfterViewChecked
} from "@angular/core";
import { Patient } from "src/app/shared/models/Patient/Patient";
import { PatientProfileSyncService } from "src/app/shared/services/component-communication/patient-profile-sync.service";
import { Router } from "@angular/router";
import { SpeechRecognizerService } from "src/app/shared/web-speech/services/speech-recognizer.service";
import { NotifyService } from "src/app/shared/services/notify/notify.service";
import { Subscription, Observable, SubscriptionLike } from "rxjs";
import { SpeechError } from "src/app/shared/web-speech/model/speech-error";
import { VoiceAssistantStates } from "src/app/shared/services/voice/voice.service";
import { SpeechNotification } from "src/app/shared/web-speech/model/speech-notification";
import {
  VoiceAssistantMessage,
  VoiceAssistantMessageStatus
} from "src/app/shared/models/VoiceAssistantMessage";
import * as firebase from "firebase";
import { timeout } from "rxjs/operators";
import { GlobalVariables } from "src/app/globar-var/globarVariables";
import { Medication } from "../../shared/models/Medication/Medication";
import { Appointment } from "../../shared/models/Scheduling/Appointment";
import { ViewAppointmentDialogService } from "../../shared/services/modalStateManagement/view-appointment-dialog.service";
import { SideSectionDialogManagementService } from "../../shared/services/component-communication/side-section-dialog-management.service";
import {
  AssistantStateManagementService,
  CurrentAssistantState
} from "../../shared/services/component-communication/asssitant-state-management.service";
import { HotkeysService } from "../../shared/services/hotkeys/hotkeys.service";
import { Subject } from "rxjs";
import { AudioRecordingService } from "../../shared/services/audioRecorder/audio-recording.service";
import { DomSanitizer } from "@angular/platform-browser";
import {
  DictationHandlerService,
  DictationCaller
} from "src/app/shared/services/dictation-handler.service";
import { ScrollToBottomDirective } from "./scroll-to-bottom.directive";
@Component({
  selector: "app-docvita-assistant",
  templateUrl: "./docvita-assistant.component.html",
  styleUrls: ["./docvita-assistant.component.css"]
})
export class DocvitaAssistantComponent implements OnInit, OnDestroy {
  // recorder
  isRecording = false;
  recordedTime;
  blobUrl;
  // end recorder
  // scroll
  @ViewChild(ScrollToBottomDirective, { static: false })
  scroll: ScrollToBottomDirective;
  // end scroll
  CurrentAssistantState = CurrentAssistantState;

  dictactionSubscription: Subscription;

  currentState: CurrentAssistantState = CurrentAssistantState.HomeSweetHome;

  @Input() enterFullScreenMode: boolean;
  @Input() medicineForEditing: Medication;
  appointmentForView: Appointment = new Appointment();

  isVoiceModeON = false;
  isKeyboardModeON = false;
  startAnimating: boolean = true;
  lottieConfig: Object;
  manualInputText: string = "";
  liveSpokenText: string = "";
  finalTranscript: string = "";

  newDentalChartEntryShowBool: boolean = false;
  viewAppointmentShowBool: boolean = false;

  patient: Patient = new Patient();
  patientDocVitaId: string;

  assistantStateManagementSubscriotion: Subscription;
  patientProfileSyncSubscription: Subscription;

  private messages: VoiceAssistantMessage[] = [];

  isBrowserSupportedForVoice: boolean = false;

  constructor(
    private _patientProfileSyncService: PatientProfileSyncService,
    private _speechRecognizerService: SpeechRecognizerService,
    private _notifyService: NotifyService,
    private _router: Router,
    private _assistantStateManagementService: AssistantStateManagementService,
    private _audioRecordingService: AudioRecordingService,
    private _sanitizer: DomSanitizer,
    private _dictationHandlerService: DictationHandlerService
  ) {
    // start audio
    this._audioRecordingService.recordingFailed().subscribe(() => {
      this.isRecording = false;
    });
    this._audioRecordingService.getRecordedTime().subscribe(time => {
      this.recordedTime = time;
    });
    this._audioRecordingService.getRecordedBlob().subscribe(data => {
      this.blobUrl = this._sanitizer.bypassSecurityTrustUrl(
        URL.createObjectURL(data.blob)
      );
    });
    // end audio
    this.lottieConfig = this.getLottieConfig();
  }

  ngOnInit() {
    // this.scrollToBottom();
    this.dictationListener();
    this.patientSelectionListener();
    let self = this;
    setTimeout(() => {
      let v = new VoiceAssistantMessage();
      v.initialize(
        "welcome",
        VoiceAssistantMessageStatus.SENT_BY_ASSISTANT,
        "Hi, " +
          GlobalVariables.getMember().name +
          ". I'm your assistant, here to help you throughout your day. Here are some things you can try saying to get started."
      );
      self.messages.push(v);

      let suggestedActions = new VoiceAssistantMessage();
      suggestedActions.initialize(
        "suggested_action",
        VoiceAssistantMessageStatus.ASSISTANT_SUGGESTED_COMMAND_ACTIONS
      );
      self.messages.push(suggestedActions);
    }, 1000);

    this.isBrowserSupportedForVoice = this._speechRecognizerService.isBrowserSupported();
    if (!this.isBrowserSupportedForVoice) {
      this.isKeyboardModeON = true;
      this.isVoiceModeON = false;
    }

    this.listenToServiceForStateManagement();
  }

  listenToServiceForStateManagement() {
    var self = this;
    self.assistantStateManagementSubscriotion = self._assistantStateManagementService
      .listener()
      .subscribe(
        (incomingInstruction: {
          state: CurrentAssistantState;
          meta: Object;
        }) => {
          if (incomingInstruction) {
            switch (incomingInstruction.state) {
              case CurrentAssistantState.MedicationForm:
                // do something here
                self.currentState = CurrentAssistantState.MedicationForm;
                self.medicineForEditing = <Medication>incomingInstruction.meta;
                break;
              case CurrentAssistantState.DentalChartEntry:
                self.currentState = CurrentAssistantState.DentalChartEntry;
                // do something here
                break;
              case CurrentAssistantState.ViewAppointment:
                self.currentState = CurrentAssistantState.ViewAppointment;
                self.appointmentForView = <Appointment>incomingInstruction.meta;
                // do something here
                break;
              case CurrentAssistantState.HomeSweetHome:
                // do something here
                self.currentState = CurrentAssistantState.HomeSweetHome;
                if (
                  incomingInstruction.meta &&
                  <string>incomingInstruction.meta == "activate_voice_mode"
                ) {
                  self.toggleVoiceState();
                }
                self.medicineForEditing = new Medication();
                self.appointmentForView = new Appointment();
                break;
              case CurrentAssistantState.Top50MedsList:
                self.currentState = CurrentAssistantState.Top50MedsList;

              default:
                // do something here too
                break;
            }
          }
        }
      );
  }

  private dictationListener() {
    this.dictactionSubscription = this._dictationHandlerService
      .assistantListener()
      .subscribe(val => {
        if (val) {
          if (val["finalText"] && val["finalText"].length > 0) {
            this.finalTranscript = val["finalText"];
            this.isVoiceModeON = false;
            this.userMessageViaVoice();
          } else if (val["interimText"] && val["interimText"].length > 0) {
            this.liveSpokenText = val["interimText"];
          }
        }
      });
  }

  patientSelectionListener() {
    this.patientProfileSyncSubscription = this._patientProfileSyncService
      .listener()
      .subscribe(map => {
        if (map) {
          this.patientDocVitaId = this._patientProfileSyncService.extractPatientIdFromPath(
            this._router.url
          );
          if (
            this.patientDocVitaId &&
            this.patientDocVitaId.length > 0 &&
            map.has(this.patientDocVitaId)
          ) {
            this.patient = map.get(this.patientDocVitaId);
          } else {
            this.patient = new Patient();
          }
        }
      });
  }

  toggleVoiceState() {
    if (this.isBrowserSupportedForVoice) {
      this.isKeyboardModeON = false;
      if (!this.isVoiceModeON) {
        setTimeout(time => {
          this.startVoiceAssistant();
        }, 200);
      } else {
        setTimeout(time => {
          this.stopVoiceAssistant();
        }, 150);
      }
    } else {
      this._notifyService.showErrorMessage(
        "Voice functionality is not supported by this browser. Upgrade to Google Chrome version 25 or later."
      );
    }
  }

  stopVoiceAssistant() {
    if (this.isBrowserSupportedForVoice) {
      this._dictationHandlerService.stopListening(DictationCaller.ASSISTANT);
    }
    this.isVoiceModeON = false;
  }

  startVoiceAssistant() {
    if (this.isBrowserSupportedForVoice) {
      this._dictationHandlerService.startListening(DictationCaller.ASSISTANT);
      this.isVoiceModeON = true;
    } else {
      this._notifyService.showErrorMessage(
        "Voice functionality is not supported by this browser. We recommend using Google Chrome version 25 or later on PC / Android devices."
      );
    }
  }

  onKeyboardClicked() {
    this.stopVoiceAssistant();
    this.isKeyboardModeON = true;
  }

  getLottieConfig(): {} {
    return {
      path: "../../../assets/lottie/google-voice-assistant.json",
      renderer: "canvas",
      autoplay: true,
      loop: true
    };
  }

  sendManualMessage() {
    this.userMessageViaKeyboard();
  }

  userMessageViaKeyboard() {
    if (this.manualInputText && this.manualInputText.length > 0) {
      let v = new VoiceAssistantMessage();
      v.initialize(
        firebase
          .database()
          .ref()
          .push().key,
        VoiceAssistantMessageStatus.SENT_BY_USER,
        this.manualInputText
      );
      this.messages.push(v);
      this.manualInputText = "";
      this.addNetworkCallMessage(v);
    }
  }

  userMessageViaVoice() {
    if (this.finalTranscript && this.finalTranscript.length > 0) {
      let v = new VoiceAssistantMessage();
      v.initialize(
        firebase
          .database()
          .ref()
          .push().key,
        VoiceAssistantMessageStatus.SENT_BY_USER,
        this.finalTranscript
      );
      this.messages.push(v);
      this.finalTranscript = "";
      this.liveSpokenText = "";
      this.addNetworkCallMessage(v);
    }
  }

  addNetworkCallMessage(v: VoiceAssistantMessage) {
    let v1 = new VoiceAssistantMessage();
    v1.initialize(
      firebase
        .database()
        .ref()
        .push().key,
      VoiceAssistantMessageStatus.WAITING_FOR_RESPONSE,
      v.text
    );
    let json = {};
    json["text"] = v.text;
    json["id"] = v.id;
    v1.networkCallJSON = json;
    this.messages.push(v1);
  }

  ngOnDestroy() {
    if (this.dictactionSubscription) {
      this.dictactionSubscription.unsubscribe();
    }
    if (this.patientProfileSyncSubscription)
      this.patientProfileSyncSubscription.unsubscribe();
    if (this.assistantStateManagementSubscriotion)
      this.assistantStateManagementSubscriotion.unsubscribe();
    this.abortRecording();
  }
  // audio recorder
  startRecording() {
    if (!this.isRecording) {
      this.isRecording = true;
      this._audioRecordingService.startRecording();
      console.log("recording");
    }
  }
  abortRecording() {
    if (this.isRecording) {
      this.isRecording = false;
      this._audioRecordingService.abortRecording();
    }
  }
  stopRecording() {
    if (this.isRecording) {
      this._audioRecordingService.stopRecording();
      this.isRecording = false;
      console.log("stopped");
    }
  }
  clearRecordedData() {
    this.blobUrl = null;
  }
  // ngAfterViewChecked() {
  //   this.scrollToBottom();
  // }
  // scrollToBottom(): void {
  //   try {
  //     this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
  //   } catch (err) {}
  // }
}

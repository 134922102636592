import { Component, OnInit, Input } from "@angular/core";
import { DentalChartEntry } from "../../models/Patient/Dental/DentalChartEntry";
import { DentalConstants } from "../../models/Patient/Dental/DentalConstants";
import { Subscription } from "rxjs";
import { SideSectionDialogManagementService } from "../../services/component-communication/side-section-dialog-management.service";
import { AssistantStateManagementService } from "../../services/component-communication/asssitant-state-management.service";

@Component({
  selector: "app-dental-chart-entry",
  templateUrl: "./dental-chart-entry.component.html",
  styleUrls: ["./dental-chart-entry.component.css"]
})
export class DentalChartEntryComponent implements OnInit {
  isEditingEntry: boolean = false; //To decide if the component is performing editing or adding new data
  dentalChartEntry: DentalChartEntry = new DentalChartEntry();
  caller: string; //To decide who is calling this component
  examinationSelectConfig: any = {
    create: true,
    optgroupField: "group",
    optgroupLabelField: "label",
    labelField: "label",
    valueField: "value",
    highlight: true,
    searchField: ["label"],
    maxItems: 100
  };

  treatmentSelectConfig: any = {
    create: true,
    optgroupField: "group",
    optgroupLabelField: "label",
    labelField: "label",
    valueField: "value",
    highlight: true,
    searchField: ["label"],
    sortField: [{ field: "label", direction: "asc" }],
    maxItems: 100
  };

  examinationSelectGroups: any = DentalConstants.examinationSelectGroups;
  examinationSelectOptions: any = DentalConstants.examinationSelectOptions;
  examinationSelectValue: string[] = [];
  tempExaminationsMap = new Map<string, string>();

  treatmentSelectGroups: any = DentalConstants.treatmentSelectGroups;
  treatmentSelectOptions: any = DentalConstants.treatmentSelectOptions;
  treatmentSelectValue: string[] = [];
  tempTreatmentsMap = new Map<string, string>();

  isGroupTeeth: boolean = false;
  remark: string = "";

  sub: Subscription;
  constructor(
    private _assistantStateManagementService: AssistantStateManagementService,
    private _sideSectionDialogManagementService: SideSectionDialogManagementService
  ) {}

  ngOnInit() {
    this.sub = this._sideSectionDialogManagementService
      .listener()
      .subscribe(map => {
        if (map && map.has("type")) {
          let type = map.get("type");
          if (type == "new_dental_entry") {
            this.isEditingEntry = <boolean>map.get("isEditing");
            if (
              map.has("dentalChartEntry") &&
              map.get("dentalChartEntry") != null
            ) {
              this.dentalChartEntry = <DentalChartEntry>(
                map.get("dentalChartEntry")
              );
              this.onEditClicked();
            } else {
              this.dentalChartEntry = new DentalChartEntry();
              this.examinationSelectValue = [];
              this.tempExaminationsMap.clear();
              this.treatmentSelectValue = [];
              this.tempTreatmentsMap.clear();
            }
          }
        }
      });
  }

  onEditClicked() {
    const that = this;
    this.remark = this.dentalChartEntry.remark;
    this.dentalChartEntry.teeth.forEach(value => {});
    let temp: string[] = [];
    this.tempExaminationsMap = new Map<string, string>();
    this.dentalChartEntry.examinations.forEach((value, key) => {
      temp.push(value.value);
      this.tempExaminationsMap.set(value.group + value.value, key);
    });
    this.examinationSelectValue = temp;
    temp = [];
    this.tempTreatmentsMap = new Map<string, string>();
    this.dentalChartEntry.treatments.forEach((value, key) => {
      temp.push(value.value);
      this.tempTreatmentsMap.set(value.group + value.value, key);
    });
    this.treatmentSelectValue = temp;
  }

  close() {
    this.onDestroyListener();
    this._assistantStateManagementService.closeDentalChartForm();
    // this._sideSectionDialogManagementService.hideDentalChartEntry();
  }

  onDestroyListener() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.onDestroyListener();
  }

  discardNewDentalChartEntry() {
    this.dentalChartEntry = new DentalChartEntry();
    this.examinationSelectValue = [];
    this.tempExaminationsMap.clear();
    this.treatmentSelectValue = [];
    this.tempTreatmentsMap.clear();
    this.remark = "";
  }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgSelectizeModule } from "ng-selectize";
// Components
import { PatientReusableCellComponent } from "./components/patient-reusable-cell/patient-reusable-cell.component";

// Pipes
import { CustomDateFilterPipe } from "./pipes/custom-date-filter.pipe";
import { CustomTimeslotFilterPipe } from "./pipes/custom-timeslot-filter.pipe";
import { CustomTimestampFilterPipe } from "./pipes/custom-timestamp-filter.pipe";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TimestampToDateFilterPipe } from "./pipes/timestamp-to-date-filter.pipe";
import { DiagnosisTypePipe } from "./pipes/diagnosis-type.pipe";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { ReusableDateNavigatorComponent } from "./components/reusable-date-navigator/reusable-date-navigator.component";
import { ExpandedDateFilterPipe } from "./pipes/expanded-date-filter.pipe";
import { FilterByDoctorIdPipe } from "./pipes/filter-by-doctor-id.pipe";
import { FilterByStatusPipe } from "./pipes/filter-by-status.pipe";
import { PatientSearchComponent } from "./components/patient-search/patient-search.component";
import { ClickOutsideDirective } from "./directives/click-outside.directive";
import { MedSearchListComponent } from "./components/med-search-list/med-search-list.component";
import { SendPatientCommunicationComponent } from "./components/send-patient-communication/send-patient-communication.component";
import { ShortDateWithoutYearFilterPipe } from "./pipes/short-date-without-year.pipe";
import { DateFilterPipe } from "./pipes/date-filter.pipe";
import { PatientProfileDialogComponent } from "./components/patient-profile-dialog/patient-profile-dialog.component";
import { ViewAppointmentDialogComponent } from "./components/view-appointment-dialog/view-appointment-dialog.component";
import { MedicationNewComponent } from "./components/medication-new/medication-new.component";
import { MedicationSearchComponent } from "./components/medication-search/medication-search.component";
import { ImportProtocolComponent } from "./components/import-protocol/import-protocol.component";
import { DentalChartEntryComponent } from "./components/dental-chart-entry/dental-chart-entry.component";
import { PatientProfileComponent } from "./components/patient-profile/patient-profile.component";
import { MedsTopFiftyComponent } from "./components/meds-top-fifty/meds-top-fifty.component";
import { BillingComponentComponent } from "../billing-component/billing-component.component";
import { AppointmentListSearchPipe } from "./pipes/appointment-list-search.pipe";
// import { AutofocusDirective } from "./directives/autofocus.directive";
import { ConfirmationDialogComponent } from "./components/confirmation-dialog/confirmation-dialog.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { DictationComponent } from "./components/dictation/dictation.component";
import { PatientVitalsSummaryCardComponent } from "./components/patient-vitals-summary-card/patient-vitals-summary-card.component";

@NgModule({
  declarations: [
    PatientReusableCellComponent,
    ReusableDateNavigatorComponent,
    PatientSearchComponent,
    CustomTimeslotFilterPipe,
    CustomDateFilterPipe,
    CustomTimestampFilterPipe,
    DateFilterPipe,
    TimestampToDateFilterPipe,
    AppointmentListSearchPipe,
    DiagnosisTypePipe,
    ExpandedDateFilterPipe,
    ShortDateWithoutYearFilterPipe,
    FilterByDoctorIdPipe,
    FilterByStatusPipe,
    ClickOutsideDirective,
    MedSearchListComponent,
    SendPatientCommunicationComponent,
    PatientProfileDialogComponent,
    ViewAppointmentDialogComponent,
    MedicationNewComponent,
    MedicationSearchComponent,
    ImportProtocolComponent,
    DentalChartEntryComponent,
    PatientProfileComponent,
    MedsTopFiftyComponent,
    // AutofocusDirective
    BillingComponentComponent,
    ConfirmationDialogComponent,
    DictationComponent,
    PatientVitalsSummaryCardComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectizeModule,
    PerfectScrollbarModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule
  ],
  exports: [
    PatientReusableCellComponent,
    ReusableDateNavigatorComponent,
    MedicationNewComponent,
    PatientSearchComponent,
    MedicationSearchComponent,
    CustomTimeslotFilterPipe,
    CustomDateFilterPipe,
    CustomTimestampFilterPipe,
    TimestampToDateFilterPipe,
    DateFilterPipe,
    DiagnosisTypePipe,
    ExpandedDateFilterPipe,
    ShortDateWithoutYearFilterPipe,
    FilterByDoctorIdPipe,
    FilterByStatusPipe,
    PerfectScrollbarModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectizeModule,
    CommonModule,
    ClickOutsideDirective,
    MedSearchListComponent,
    SendPatientCommunicationComponent,
    PatientProfileDialogComponent,
    ViewAppointmentDialogComponent,
    ImportProtocolComponent,
    DentalChartEntryComponent,
    MedsTopFiftyComponent,
    AppointmentListSearchPipe,
    ConfirmationDialogComponent,
    DictationComponent,
    PatientVitalsSummaryCardComponent
  ],
  entryComponents: [ConfirmationDialogComponent]
})
export class SharedModule {}

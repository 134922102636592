import { Organisation, OrganisationMember } from "../shared/models/MD - Member";
import { ObjectKeys } from "../shared/constants/object-keys";
import { PrescriptionTemplate } from "../shared/models/Prescription/PrescriptionTemplate";
import { QueueTabOption } from "../shared/models/Scheduling/SchedulingConstants";

export class GlobalVariables {
  static memberId: string;
  static memberName: string;
  static organisationId: string;
  static organisationName: string;
  static organisation: Organisation;
  static member: OrganisationMember;
  static membersMap: Map<string, OrganisationMember> = new Map(); //Map of members who belong to same organisation
  static prescriptionTampletes: Map<string, PrescriptionTemplate> = new Map();
  static currentSelectedTabInQueue: QueueTabOption;

  static setMemberInMap = (memberVal: OrganisationMember) => {
    GlobalVariables.membersMap.set(memberVal.docvitaId, memberVal);
    let jsonStr = localStorage.getItem("membersMap");
    let jsonForLocalStorage = {};
    if (jsonStr) {
      jsonForLocalStorage = JSON.parse(jsonStr);
    }
    // console.log("setMembersMap:",memberVal.docvitaId)
    let temp = memberVal.toJSON();
    temp[ObjectKeys.docvitaId] = memberVal.docvitaId;
    jsonForLocalStorage[memberVal.docvitaId] = temp;
    // console.log("setMembersMap:",jsonForLocalStorage)
    localStorage.setItem("membersMap", JSON.stringify(jsonForLocalStorage));
  };

  static setCurrentTabInQueueScreen = (tab: QueueTabOption) => {
    GlobalVariables.currentSelectedTabInQueue = tab;
    localStorage.setItem("currentSelectedTabInQueue", tab.toString());
  };
  static setMember = (memberVal: OrganisationMember) => {
    GlobalVariables.member = memberVal;
    localStorage.setItem("member", JSON.stringify(memberVal.toJSON()));
  };

  static setOrganisation = (organisationVal: Organisation) => {
    GlobalVariables.organisation = organisationVal;
    localStorage.setItem(
      "organisation",
      JSON.stringify(organisationVal.toJSON())
    );
  };

  static setMemberId = id => {
    GlobalVariables.memberId = id;
    localStorage.setItem("memberId", id);
  };
  static setMemberName = name => {
    GlobalVariables.memberName = name;
    localStorage.setItem("memberName", name);
  };
  static setOrganisationId = id => {
    GlobalVariables.organisationId = id;
    localStorage.setItem("organisationId", id);
  };
  static setOrganisationName = name => {
    GlobalVariables.organisationName = name;
    localStorage.setItem("organisationName", name);
  };

  static getCurrentTabInQueueScreen = () => {
    // GlobalVariables.currentSelectedTabInQueue = tab;
    let tabString =
      GlobalVariables.currentSelectedTabInQueue ||
      localStorage.getItem("currentSelectedTabInQueue");
    if (tabString && tabString.length > 0) {
      GlobalVariables.currentSelectedTabInQueue = <QueueTabOption>tabString;
    } else {
      GlobalVariables.currentSelectedTabInQueue = QueueTabOption.Default;
    }
    // console.log(
    //   "Currently Selected Tab in Queue: ",
    //   GlobalVariables.currentSelectedTabInQueue
    // );
    return GlobalVariables.currentSelectedTabInQueue;
  };

  static getMembersMap = () => {
    let str = localStorage.getItem("membersMap");
    if (str) {
      let json = JSON.parse(str);
      // console.log("YOO",json)
      let map: Map<string, OrganisationMember> = new Map();
      for (let k in json) {
        let o: OrganisationMember = new OrganisationMember();
        o.initFromJSON(json[k]);
        map.set(k, o);
      }
      return map;
    } else {
      return GlobalVariables.membersMap;
    }
  };

  static getOpdScheduleAvailableMembersMap = organisationId => {
    let map: Map<string, OrganisationMember> = new Map();
    let finalMap: Map<string, OrganisationMember> = new Map();
    let str = localStorage.getItem("membersMap");
    if (str) {
      let json = JSON.parse(str);
      // console.log("YOO",json)
      for (let k in json) {
        let o: OrganisationMember = new OrganisationMember();
        o.initFromJSON(json[k]);
        map.set(k, o);
      }
    } else {
      map = GlobalVariables.membersMap;
    }
    map.forEach((val, key) => {
      if (val.getOpdScheduleAvailability(organisationId)) {
        finalMap.set(key, val);
      }
    });
    return finalMap;
  };

  static getMember = () => {
    if (GlobalVariables.member && GlobalVariables.member.docvitaId) {
      return GlobalVariables.member;
    } else {
      let json = localStorage.getItem("member");
      if (json) {
        let m: OrganisationMember = new OrganisationMember();
        m.initFromJSON(JSON.parse(json));
        return m;
      } else {
        return null;
      }
    }
  };

  static getOrganisation = () => {
    if (
      GlobalVariables.organisation &&
      GlobalVariables.organisation.organisationId
    ) {
      return GlobalVariables.organisation;
    } else {
      let json = localStorage.getItem("organisation");
      if (json) {
        let m: Organisation = new Organisation();
        m.initFromJSON(JSON.parse(json));
        return m;
      } else {
        return null;
      }
    }
  };

  static getMemberId = () => {
    return GlobalVariables.memberId || localStorage.getItem("memberId");
  };
  static getMemberName = () => {
    return GlobalVariables.memberName || localStorage.getItem("memberName");
  };
  static getOrganisationId = () => {
    return (
      GlobalVariables.organisationId || localStorage.getItem("organisationId")
    );
  };
  static getOrganisationName = () => {
    return (
      GlobalVariables.organisationName ||
      localStorage.getItem("organisationName")
    );
  };
  static clearValues = () => {
    localStorage.removeItem("memberId");
    localStorage.removeItem("memberName");
    localStorage.removeItem("organisationId");
    localStorage.removeItem("organisationName");
    localStorage.removeItem("membersMap");
    localStorage.removeItem("member");
    localStorage.removeItem("organisation");
  };
}

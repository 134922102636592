import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { VitalMeasurement } from "../../models/Vitals/Vital Measurement";
import * as urls from "../../../../environments/environment";
import { ObjectKeys } from "../../constants/object-keys";
import { LatestVitalSnapshot } from "../../models/Vitals/Latest Vital Snapshot";
import { GlobalVariables } from "src/app/globar-var/globarVariables";

@Injectable({
  providedIn: "root"
})
export class VitalService {
  constructor(private _http: HttpClient) {}

  public createOrUpdate = ( latestVitalSnapshot: LatestVitalSnapshot) => {
    return new Promise((resolve, reject) => {
      let body = {};
      body["vitalArray"] = latestVitalSnapshot.pushVitalArray()
      body[ObjectKeys.organisationId] = GlobalVariables.getOrganisationId();
      let url = urls.environment.vitals;
      let val = this._http.post(url, body).subscribe(
        (res: Response) => {
          if (val != null) {
            resolve(res);
          }
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  };

  public createOrUpdateSingleVital = ( vitalMeasurement?: VitalMeasurement, vitalArray?:VitalMeasurement[]) => {
    return new Promise((resolve, reject) => {
      let body = {};
      if(vitalMeasurement){
        body["vitalArray"] = [vitalMeasurement.toJSON()]
        body[ObjectKeys.organisationId] = GlobalVariables.getOrganisationId();
      }else if(vitalArray){
        let tempJSONArray = []
        for(let v of vitalArray){
          tempJSONArray.push(v.toJSON())
        }
        body[ObjectKeys.organisationId] = GlobalVariables.getOrganisationId();
        body["vitalArray"] = tempJSONArray
      }
      let url = urls.environment.vitals;
      let val = this._http.post(url, body).subscribe(
        (res: Response) => {
          if (val != null) {
            resolve(res);
          }
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  };

  public delete = (vitalMeasurementId: string, patientId: string) => {
    return new Promise((resolve, reject) => {
      let body = {};
      body[ObjectKeys.vitalMeasurementId] = vitalMeasurementId;
      body[ObjectKeys.patientId] = patientId;
      body[ObjectKeys.organisationId] = GlobalVariables.getOrganisationId();
      let options = {};
      options["params"] = body;
      let url = urls.environment.vitals;
      let val = this._http.delete(url, options).subscribe(
        (res: Response) => {
          if (val != null) {
            resolve(res);
          }
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  };

  public fetchAll = (patientId: string) => {
    let options = {};
    let params = {};
    params[ObjectKeys.patientId] = patientId;
    params[ObjectKeys.organisationId] = GlobalVariables.getOrganisationId();
    options["params"] = params;
    return new Promise((resolve, reject) => {
      let val = this._http.get(urls.environment["vitals"], options).subscribe(
        (res: Response) => {
          if (val != null) {
            resolve(res);
          }
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  };
}

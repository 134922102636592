import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import * as urls from "../../../../environments/environment";
import { ObjectKeys } from "../../constants/object-keys";
import { Prescription } from "../../models/Prescription/Prescription";
import { PrescriptionTemplate } from "../../models/Prescription/PrescriptionTemplate";
import * as elasticsearch from "elasticsearch-browser";
import { GlobalVariables } from "src/app/globar-var/globarVariables";

@Injectable({
  providedIn: "root"
})
export class PrescriptionService {
  private client: elasticsearch.Client;
  private PRESCRIPTION_TEMPLATE_INDEX: string =
    "prescription_templates_text_index";
  private TYPE = "_doc";
  constructor(private _http: HttpClient) {
    if (!this.client) {
      this.createClient();
    }
  }

  public createClient() {
    // console.log("creating");
    this.client = new elasticsearch.Client({
      host: urls.environment.elasticSearch
      // log: "trace"
    });
  }

  public diagnosisTextSearch = _queryText => {
    return new Promise((resolve, reject) => {
      if (_queryText === "") {
        resolve([]);
      } else {
        let DIAGNOSIS_INDEX: string = "diagnosis_text_index";
        this.client
          .search({
            index: DIAGNOSIS_INDEX,
            type: this.TYPE,
            filterPath: ["hits.hits._source", "hits.total", "_scroll_id"],
            body: {
              query: {
                match_phrase_prefix: {
                  text: _queryText
                }
              }
            }
          })
          .then(response => {
            console.log("RESP DIAGN", response);
            if (response.hits.hits)
              resolve(response.hits.hits.map(a => a._source.text));
            else resolve([]);
          });
      }
    });
  };

  public prescriptionTitleSearch(_queryText, organisationId): any {
    return this.client.search({
      index: this.PRESCRIPTION_TEMPLATE_INDEX,
      type: this.TYPE,
      filterPath: ["hits.hits._source", "hits.total", "_scroll_id"],
      body: {
        query: {
          bool: {
            must: {
              multi_match: {
                query: _queryText,
                type: "cross_fields",
                operator: "and",
                fields: ["title"]
              }
            },
            filter: {
              term: {
                organisationId: organisationId
              }
            }
          }
        }
      }
    });
  }

  /**
   * Create
   * @param prescriptionObject
   */
  public create(prescriptionObject: Prescription) {
    return new Promise((resolve, reject) => {
      let body = prescriptionObject.toJSON();
      let val = this._http.post(urls.environment.prescription, body).subscribe(
        (res: Response) => {
          if (val != null) {
            resolve(res);
          }
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }
  /**
   * update
   * @param prescriptionObject
   */
  public update(prescriptionObject: Prescription) {
    return new Promise((resolve, reject) => {
      let body = prescriptionObject.toJSON();
      let val = this._http.put(urls.environment.prescription, body).subscribe(
        (res: Response) => {
          if (val != null) {
            resolve(res);
          }
        },
        err => {
          console.log(err);
          reject(err);
        }
      );
    });
  }

  public get(user_id: string, prescription_id: string) {
    let options = {};
    let params = {};
    params[ObjectKeys.user_id] = user_id;
    params[ObjectKeys.organisationId] = GlobalVariables.getOrganisationId();
    params[ObjectKeys.prescription_id] = prescription_id;
    options["params"] = params;
    return new Promise((resolve, reject) => {
      let val = this._http
        .get(urls.environment.prescription, options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  public getPdf(prescriptionId: string, language: string) {
    return new Promise((resolve, reject) => {
      let params = {};
      params["prescriptionId"] = prescriptionId;
      params[ObjectKeys.organisationId] = GlobalVariables.getOrganisationId();
      params["language"] = language;
      let headers = {
        "Content-Type": "application/pdf",
        Accept: "application/pdf"
      };
      let val = this._http
        .get(urls.environment.prescription + ["/generate-pdf"], {
          params: params,
          headers: headers,
          responseType: "arraybuffer"
        })
        .subscribe(
          res => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Get all prescription for a patient
   */
  public getAll(user_id: string) {
    let options = {};
    let params = {};
    params[ObjectKeys.user_id] = user_id;
    params[ObjectKeys.organisationId] = GlobalVariables.getOrganisationId();
    options["params"] = params;
    return new Promise((resolve, reject) => {
      let val = this._http
        .get(urls.environment["patient-prescription"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Create/update prescription template
   * @param
   */
  public createOrUpdatePrescriptionTemplate(
    prescriptionTemplate: PrescriptionTemplate
  ) {
    return new Promise((resolve, reject) => {
      let body = prescriptionTemplate.toJSON();
      body[ObjectKeys.organisationId] = GlobalVariables.getOrganisationId();
      let val = this._http
        .post(urls.environment["prescription-template"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Create/update prescription template
   * @param
   */
  public fetchPrescriptionTemplate(
    prescriptionTemplateId: string,
    organisationId: string
  ) {
    return new Promise((resolve, reject) => {
      let params = {};
      params["organisationId"] = organisationId;
      params["prescriptionTemplateId"] = prescriptionTemplateId;
      let option = {};
      option["params"] = params;
      let val = this._http
        .get(urls.environment["prescription-template"], option)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Create/update prescription template
   * @param
   */
  public deletePrescriptionTemplate(
    prescriptionTemplate: PrescriptionTemplate
  ) {
    return new Promise((resolve, reject) => {
      let body = {};
      body[ObjectKeys.prescriptionTemplateId] =
        prescriptionTemplate.prescriptionTemplateId;
      body[ObjectKeys.organisationId] = GlobalVariables.getOrganisationId();
      let options = {};
      options["params"] = body;
      let val = this._http
        .delete(urls.environment["prescription-template"], options)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }

  /**
   * Create/update prescription template
   * @param
   */
  public sharePrescriptionWithPatient(
    prescriptionId: string,
    organisationId: string,
    type: string,
    patientId: string,
    allowedMobileNumbers: String[]
  ) {
    return new Promise((resolve, reject) => {
      let body = {};
      body["prescriptionId"] = prescriptionId;
      body["organisationId"] = organisationId;
      body["type"] = type;
      body["patientId"] = patientId;
      body["allowedMobileNumbers"] = allowedMobileNumbers;
      let val = this._http
        .post(urls.environment["share-prescription-with-patient"], body)
        .subscribe(
          (res: Response) => {
            if (val != null) {
              resolve(res);
            }
          },
          err => {
            console.log(err);
            reject(err);
          }
        );
    });
  }
}

import {BasicModelInterface} from '../../BasicModelInterface';
import {ObjectKeys} from '../../../constants/object-keys';

export class Examination implements BasicModelInterface {

  private _examinationId: string;
  private _label: string;
  private _value: string;
  private _group: string;
  private _groupLabel: string;
  private _teeth: string[] = [];
  private _options: string[] = [];
  private _currentStatus: string;
  private _createdOn: string;
  private _date: string;
  private _createdBy: string;
  private _createdByName: string;
  private _currentlyLoggedInMember: string;
  private _currentlyLoggedInMemberName: string;
  private _updates: {}[] = [];                // {status, date} eg - {status: 'completed', date: '2019-08-01'}
  private _caseHistoryId: string;
  private _dentalChartEntryId: string;

  constructor() {}

  get examinationId(): string {
    return this._examinationId;
  }

  set examinationId(value: string) {
    this._examinationId = value;
  }

  get label(): string {
    return this._label;
  }

  set label(value: string) {
    this._label = value;
  }

  get value(): string {
    return this._value;
  }

  set value(value: string) {
    this._value = value;
  }

  get group(): string {
    return this._group;
  }

  set group(value: string) {
    this._group = value;
  }

  get groupLabel(): string {
    return this._groupLabel;
  }

  set groupLabel(value: string) {
    this._groupLabel = value;
  }

  get teeth(): string[] {
    return this._teeth;
  }

  set teeth(value: string[]) {
    this._teeth = value;
  }

  get options(): string[] {
    return this._options;
  }

  set options(value: string[]) {
    this._options = value;
  }

  get currentStatus(): string {
    return this._currentStatus;
  }

  set currentStatus(value: string) {
    this._currentStatus = value;
  }

  get createdOn(): string {
    return this._createdOn;
  }

  set createdOn(value: string) {
    this._createdOn = value;
  }

  get date(): string {
    return this._date;
  }

  set date(value: string) {
    this._date = value;
  }

  get createdBy(): string {
    return this._createdBy;
  }

  set createdBy(value: string) {
    this._createdBy = value;
  }

  get createdByName(): string {
    return this._createdByName;
  }

  set createdByName(value: string) {
    this._createdByName = value;
  }

  get currentlyLoggedInMember(): string {
    return this._currentlyLoggedInMember;
  }

  set currentlyLoggedInMember(value: string) {
    this._currentlyLoggedInMember = value;
  }

  get currentlyLoggedInMemberName(): string {
    return this._currentlyLoggedInMemberName;
  }

  set currentlyLoggedInMemberName(value: string) {
    this._currentlyLoggedInMemberName = value;
  }

  get updates(): {}[] {
    return this._updates;
  }

  set updates(value: {}[]) {
    this._updates = value;
  }

  get caseHistoryId(): string {
    return this._caseHistoryId;
  }

  set caseHistoryId(value: string) {
    this._caseHistoryId = value;
  }

  get dentalChartEntryId(): string {
    return this._dentalChartEntryId;
  }

  set dentalChartEntryId(value: string) {
    this._dentalChartEntryId = value;
  }

  initFromJSON(json: {}): void {
    if (json[ObjectKeys.id]) {
      this.examinationId = json[ObjectKeys.id];
    }
    if (json[ObjectKeys.label]) {
      this.label = json[ObjectKeys.label];
    }
    if (json[ObjectKeys.value]) {
      this.value = json[ObjectKeys.value];
    }
    if (json[ObjectKeys.group]) {
      this.group = json[ObjectKeys.group];
    }
    if (ObjectKeys.groupLabel) {
      this.groupLabel = json[ObjectKeys.groupLabel];
    }
    if (json[ObjectKeys.teeth]) {
      this.teeth = json[ObjectKeys.teeth];
    }
    if (json[ObjectKeys.options]) {
      this.options = json[ObjectKeys.options];
    }
    if (json[ObjectKeys.status]) {
      this.currentStatus = json[ObjectKeys.status];
    }
    if (json[ObjectKeys.createdOn]) {
      this.createdOn = json[ObjectKeys.createdOn];
    }
    if (json[ObjectKeys.date]) {
      this.date = json[ObjectKeys.date];
    }
    if (json[ObjectKeys.createdBy]) {
      this.createdBy = json[ObjectKeys.createdBy];
    }
    if (json[ObjectKeys.createdByName]) {
      this.createdByName = json[ObjectKeys.createdByName];
    }
    if (json[ObjectKeys.currentlyLoggedInMemberId]) {
      this.currentlyLoggedInMember = json[ObjectKeys.currentlyLoggedInMemberId];
    }
    if (json[ObjectKeys.currentlyLoggedInMemberName]) {
      this.currentlyLoggedInMemberName = json[ObjectKeys.currentlyLoggedInMemberName];
    }
    if (json[ObjectKeys.updates]) {
      this.updates = json[ObjectKeys.updates];
    }
    if (json[ObjectKeys.caseHistoryId]) {
      this.caseHistoryId = json[ObjectKeys.caseHistoryId];
    }
    if (json[ObjectKeys.dentalChartEntryId]) {
      this.dentalChartEntryId = json[ObjectKeys.dentalChartEntryId];
    }
  }

  toJSON(): {} {
    const json = {};
    if (this.examinationId) {
      json[ObjectKeys.id] = this.examinationId;
    }
    if (this.label) {
      json[ObjectKeys.label] = this.label;
    }
    if (this.value) {
      json[ObjectKeys.value] = this.value;
    }
    if (this.group) {
      json[ObjectKeys.group] = this.group;
    }
    if (this.groupLabel) {
      json[ObjectKeys.groupLabel] = this.groupLabel;
    }
    if (this.teeth) {
      json[ObjectKeys.teeth] = this.teeth;
    }
    if (this.options) {
      json[ObjectKeys.options] = this.options;
    }
    if (this.currentStatus) {
      json[ObjectKeys.status] = this.currentStatus;
    }
    if (this.createdOn) {
      json[ObjectKeys.createdOn] = this.createdOn;
    }
    if (this.date) {
      json[ObjectKeys.date] = this.date;
    }
    if (this.createdBy) {
      json[ObjectKeys.createdBy] = this.createdBy;
    }
    if (this.createdByName) {
      json[ObjectKeys.createdByName] = this.createdByName;
    }
    if (this.currentlyLoggedInMember) {
      json[ObjectKeys.currentlyLoggedInMemberId] = this.currentlyLoggedInMember;
    }
    if (this.currentlyLoggedInMemberName) {
      json[ObjectKeys.currentlyLoggedInMemberName] = this.currentlyLoggedInMemberName;
    }
    if (this.updates) {
      json[ObjectKeys.updates] = this.updates;
    }
    if (this.caseHistoryId) {
      json[ObjectKeys.caseHistoryId] = this.caseHistoryId;
    }
    if (this.dentalChartEntryId) {
      json[ObjectKeys.dentalChartEntryId] = this.dentalChartEntryId;
    }
    return json;
  }
}

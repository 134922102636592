import { Component, OnInit, EventEmitter, Output } from "@angular/core";
import { Billing } from "../shared/models/Billing/Billing";
import { ActivatedRoute, Router } from "@angular/router";
import * as uuid from "uuid";
import { BillingItem } from "../shared/models/Billing/Billing";
import { Observable, from, of, Subscription } from "rxjs";
import { NotifyService } from "../shared/services/notify/notify.service";
import { GlobalVariables } from "../globar-var/globarVariables";
import { PatientProfileSyncService } from "../shared/services/component-communication/patient-profile-sync.service";
import { Appointment } from "../shared/models/Scheduling/Appointment";
import { DateActions } from "../shared/models/Demographics";
import { FormControl } from "@angular/forms";

import { map, startWith } from "rxjs/operators";
import * as moment from "moment";
import { OPDTypeAndFee } from "../shared/models/MD - Member";
import { ConfirmationDialogService } from "../shared/services/confirmation-dialog/confirmation-dialog.service";
import { BillingService } from "../shared/services/billing/billing.service";

@Component({
  selector: "app-billing-component",
  templateUrl: "./billing-component.component.html",
  styleUrls: ["./billing-component.component.css"]
})
export class BillingComponentComponent implements OnInit {
  billing: Billing = new Billing();
  // appointment: Appointment;
  invoiceNumber: string;
  i: number = 0;
  invoiceDate: string;
  selectedOPD: string;
  selectedLab: string;
  discount: number;
  patientName: string;
  patientSexAgeString: string;
  patientGender: string;
  patientAge: any;
  patientMobile: any;
  discountType: number;
  adjustment: string;
  isRefreshing: boolean = false;
  total: string;
  items: BillingItem[] = [];
  itemsMap: Map<string, BillingItem> = new Map();
  activatedRouteSubscription: Subscription;
  billingSubsription: Subscription;
  billingJSON: {} = {};
  today = new Date();
  dd = String(this.today.getDate()).padStart(2, "0");
  mm = String(this.today.getMonth() + 1).padStart(2, "0"); //January is 0!
  yyyy = this.today.getFullYear();
  todaysDate;
  dateValue: {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private _router: Router,
    private _notifyService: NotifyService,
    private _confirmationDialogService: ConfirmationDialogService,
    private _patientProfileSyncService: PatientProfileSyncService,
    private _billingService: BillingService
  ) {}

  ngOnInit() {
    // autosuggest
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(""),
      map(value => this._filter(value))
    );
    //Init date for billing
    this.initialiseDate();
    this.activatedRouteSubscription = this.activatedRoute.params.subscribe(
      params => {
        console.log(params);
      }
    );

    this.addDefaultItems();
    this.billingSubsription = this._billingService
      .getAppointment()
      .subscribe(val => {
        // console.log(val);
        if (val) {
          let a = new Appointment();
          a.initFromJSON(val);
          if (a) {
            // this.appointment = a;
            if (a.organisationId) {
              this.billing.organisationId = a.organisationId;
            }
            if (a.organisationName) {
              this.billing.organisationName = a.organisationName;
            }
            if (a.patientId) {
              this.billing.patientId = a.patientId;
            }
            if (a.patientAge) {
              // console.log("Age =>", a.patientAgeObject);
              this.patientAge = a.patientAge;
              let i: any = a.patientAge;
              let j: string = i;
              this.billing.patientAge = j;
            }
            if (a.sex_age) {
              this.patientSexAgeString = a.sex_age;
            }
            if (a.patientGender) {
              this.patientGender = a.patientGender;
              this.billing.patientGender = a.patientGender;
            }
            if (a.patientName) {
              this.patientName = a.patientName;
              this.billing.patientName = a.patientName;
            }
            if (a.patientPrimaryContactNumber) {
              this.patientMobile = a.patientPrimaryContactNumber;
              this.billing.patientMobileNumber = a.patientPrimaryContactNumber;
            }
            if (a.patientPrimaryEmail) {
              this.billing.patientEmailAddress = a.patientPrimaryEmail;
            }
            if (a.doctorId) {
              this.billing.receiverId = a.doctorId;
            }
            if (a.doctorName) {
              this.billing.receiverName = a.doctorName;
            }
            if (a.billingId) {
              this.billing.billingId = a.billingId;
              //Fetch billing info
              this.fetchBillingDetails(this.billing.billingId);
            }else{
              this.addDefaultItems(a.doctorId);
            }
            this.billing.appointmentDate = a.date;
            this.billing.appointmentId = a.appointmentId;
          }
        }
      });
  }
  fetchBillingDetails(billingId) {
    this.items = [];
    this.billing.itemsMap.clear();
    this.quantityChanged();
    this.isRefreshing = true;
    this._billingService
      .getBill(billingId)
      .then(resp => {
        this.isRefreshing = false;
        if (
          resp &&
          resp["statusCode"] == 200 &&
          resp["body"] &&
          resp["body"]["success"] &&
          resp["body"]["data"]
        ) {
          this.billing.initFromJSON(resp["body"]["data"]);
          this.billing.itemsMap.forEach((val, key) => {
            this.items.push(val);
          });
          let dateTemp = new Date(this.billing.date);
          this.dateValue = {
            day: dateTemp.getDate(),
            month: dateTemp.getMonth() + 1,
            year: dateTemp.getFullYear()
          };
        } else {
        }
        this.quantityChanged();
      })
      .catch(err => {
        this.isRefreshing = false;
        console.error(err);
      });
  }
  validateDateValue() {}

  saveDateValueIntoBillingObject() {}

  initialiseDate() {
    let today = new Date(Date.now());
    this.billing.date = DateActions.getDateString(today);
    this.dateValue = {
      day: today.getDate(),
      month: today.getMonth() + 1,
      year: today.getFullYear()
    };
  }

  datePickerSelectionChanged() {
    this.billing.date = DateActions.getDateString(
      new Date(
        this.dateValue["year"],
        this.dateValue["month"] - 1,
        this.dateValue["day"]
      )
    );
  }

  serviceSelectionChanged(
    option: OPDTypeAndFee,
    currentItem: BillingItem,
    i: number
  ) {
    if (option.serviceName) {
      currentItem.title = option.serviceName;
    }else{
      currentItem.title = ""
    }
    if (option.serviceDescription) {
      currentItem.description = option.serviceDescription;
    } else {
      currentItem.description = "";
    }
    if (option.price) {
      currentItem.price = option.price;
    }
    this.items[i]= currentItem;
    this.quantityChanged();
  }

  addDefaultItems(doctorId?:string) {
    if (
      GlobalVariables.getOrganisation().opdCharges &&
      GlobalVariables.getOrganisation().opdCharges.size > 0
    ) {
      let defaultServiceItem: OPDTypeAndFee;
      let tempServiceItem:OPDTypeAndFee;//This is a temp. var for holding the backup fees object
      GlobalVariables.getOrganisation().opdCharges.forEach((val, key) => {
        console.log(doctorId , val.doctorId , doctorId == val.doctorId)
        if(doctorId && val.doctorId && doctorId == val.doctorId){
          defaultServiceItem = val;
        }
        tempServiceItem = val;
      });
      if(!defaultServiceItem){
        defaultServiceItem = tempServiceItem;
      }
      let item1 = new BillingItem();
      let newKey = uuid.v4();
      item1.billingItemId = newKey;
      item1.quantity = 1.0;
      item1.price = defaultServiceItem.price;
      item1.title = defaultServiceItem.serviceName;
      if(defaultServiceItem.doctorId){
        item1.doctorId = defaultServiceItem.doctorId
      }
      if (defaultServiceItem.serviceDescription) {
        item1.description = defaultServiceItem.serviceDescription;
      }
      this.items.splice(0,this.items.length);
      this.items.push(item1);
      this.quantityChanged();
    }
  }

  onAddClicked() {
    // this.i = this.i + 1;
    // let j = this.i.toString()
    // for (j in this.itemsMap) {
    //   let item = new BillingItem();
    //   this.itemsMap.set(j, item);
    // }
    // console.log(this.itemsMap)

    let newKey = uuid.v4();
    let item = new BillingItem();
    item.billingItemId = newKey;
    item.quantity = 1.0;
    item.price = 0.0;
    this.items.push(item);
    // console.log(this.items);
  }

  onDeleteClicked(index) {
    let key: any;
    key = this.items[index]["key"];
    this.billing.itemsMap.delete(key);
    const valueToRemove = this.items[index];
    this.items = this.items.filter(function(item) {
      return item !== valueToRemove;
    });
    this.quantityChanged();
    // console.log("Items =>", this.items);
    // console.log(this.billing);
  }

  getDate() {
    return this.dd + "/" + this.mm + "/" + this.yyyy;
  }

  quantityChanged() {
    this.itemsMap.clear();
    for (let i = 0; i < this.items.length; i++) {
      this.itemsMap.set(
        this.items[i].billingItemId,
        this.items[i]
      );
    }
    this.billing.itemsMap = this.itemsMap;
    // this.billing.initFromJSON(this.billing);
    // console.log(this.billing.toJSON());
  }

  onSaveClicked() {
    if (this.dateValue)
      this.billing.date = DateActions.getDateJSONToStringWithMonthDiff(
        this.dateValue
      );
    this.isRefreshing = true;
    let request;
    if (!this.billing.billingId) {
      request = this._billingService.createBillingRecipt(
        GlobalVariables.getOrganisationId(),
        GlobalVariables.getOrganisation().organisationName,
        GlobalVariables.getMemberId(),
        GlobalVariables.getMemberName(),
        this.billing
      );
    } else {
      request = this._billingService.updateBillingRecipt(
        GlobalVariables.getOrganisationId(),
        GlobalVariables.getOrganisation().organisationName,
        GlobalVariables.getMemberId(),
        GlobalVariables.getMemberName(),
        this.billing
      );
    }
    request
      .then(resp => {
        this.isRefreshing = false;
        if (
          resp &&
          resp["statusCode"] &&
          resp["statusCode"] == 200 &&
          resp["body"] &&
          resp["body"]["success"]
        ) {
          let billingId = this.billing.billingId;
          if (!billingId && resp["body"]["billingId"]) {
            billingId = resp["body"]["billingId"];
          }
          if (billingId) {
            this.printDialog(billingId);
          } else {
            //Route to main screen
            this.goBack();
          }
        }
      })
      .catch(err => {
        console.error(err);
        this._notifyService.showErrorMessage("Please try again");
      });
  }

  printDialog(billingId) {
    this._confirmationDialogService
      .confirm("Print", "Do you want to print the receipt?", "Print", "BACK")
      .then(confirmed => {
        if (confirmed) {
          return this._billingService
            .fetchInvoicePDF(billingId, GlobalVariables.getOrganisationId())
            .then((data: BlobPart) => {
              if (data != null) {
                let file = new Blob([data], {
                  type: "application/pdf"
                });
                let fileURL = URL.createObjectURL(file);
                // this.isRefreshing = false;
                window.open(fileURL).print();
              }
              this.isRefreshing = false;
              //Route to main screen
              this.goBack();
              this._notifyService.showSuccessMessage("Saved successfully");
            })
            .catch(err => {
              console.error(err);
              this._notifyService.showErrorMessage("Please try again");
            });
        } else {
          this.isRefreshing = false;
          //Route to main screen
          this.goBack();
          this._notifyService.showSuccessMessage("Saved successfully");
          console.log("cancelled");
        }
      })
      .catch(err => {});
  }

  goBack() {
    this._router.navigate(["../../"], { relativeTo: this.activatedRoute });
  }

  // autosuggest
  myControl = new FormControl();
  options: OPDTypeAndFee[] = Array.from(
    GlobalVariables.getOrganisation().opdCharges.values()
  );
  filteredOptions: Observable<OPDTypeAndFee[]>;

  private _filter(value: OPDTypeAndFee): OPDTypeAndFee[] {
    let val = value.serviceName || "";
    const filterValue = val.toLowerCase();
    return this.options.filter(
      option => option.serviceName.toLowerCase().indexOf(filterValue) === 0
    );
  }
}

import {
  Component,
  OnInit,
  Input,
  AfterViewChecked,
  AfterViewInit,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
  ViewChild,
  OnDestroy,
  ElementRef,
  HostListener
} from "@angular/core";
import { SelectizeConfigs } from "../shared/constants/object-keys";
import { PersonConstants } from "../shared/models/Person";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import * as moment from "moment";
import * as firebase from "firebase";
import {
  FirestoreCollections,
  AppointmentStatus
} from "src/app/shared/constants/object-keys";
import { PatientSearchComponent } from "../shared/components/patient-search/patient-search.component";
import {
  NgbDateParserFormatter,
  NgbDatepicker,
  NgbModal
} from "@ng-bootstrap/ng-bootstrap";
import { NgbDateFRParserFormatter } from "../shared/services/datePicker/NgbDateFRParserFormatter";
import { Patient } from "../shared/models/Patient/Patient";
import {
  ContactNumber,
  DateActions,
  OrganisationLocationObject
} from "../shared/models/Demographics";
import { PatientProfileService } from "../shared/services/patient/patient-profile.service";
import { GlobalVariables } from "../globar-var/globarVariables";
import { NotifyService } from "../shared/services/notify/notify.service";
import {
  OrganisationMember,
  OPDTypeAndFee
} from "../shared/models/MD - Member";
import { OpdScheduleOperationsService } from "../shared/services/opd/opd-schedule-operations.service";
import { ScheduleAppointmentModalService } from "../shared/services/modalStateManagement/schedule-appointment-modal.service";
import { AppointmentOperationsService } from "../shared/services/opd/appointment-operations.service";
import { Appointment } from "../shared/models/Scheduling/Appointment";
import { VoiceService } from "../shared/services/voice/voice.service";
import { ViewAppointmentDialogService } from "src/app/shared/services/modalStateManagement/view-appointment-dialog.service";
import { app } from "firebase";
import { Subscription } from "rxjs";
import { PatientRegistrationModalService } from "../shared/services/modalStateManagement/patient-registration-modal.service";
import { FullCalendarComponent } from "@fullcalendar/angular";

declare var $: any;

export enum KEY_CODE {

  ESC = 27
}

@Component({
  selector: "app-new-appointment",
  templateUrl: "./new-appointment.component.html",
  styleUrls: ["./new-appointment.component.css"],
  providers: [
    { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter }
  ]

  // encapsulation: ViewEncapsulation.None
})
export class NewAppointmentComponent implements OnInit, OnDestroy {
  @ViewChild(PatientSearchComponent, { static: false })
  patientSearchComponent: PatientSearchComponent;

  @ViewChild("searchTextInputElement", { static: false })
  searchTextInputElement: ElementRef;

  singleSelectConfig = SelectizeConfigs.singleSelectConfig;
  datePickerDate: string;
  start_date: any;
  isRefreshingTimeSlots = false;
  isSavingAppointment = false;
  calendarPlugins = [interactionPlugin, dayGridPlugin, timeGridPlugin];
  @ViewChild("calendar", { static: false })
  calendarComponent: FullCalendarComponent;
  maxAllowedAppointments = 1;
  reloadCalendarBool = false;
  // Variables for Time Picker
  isEnteringCustomTime: boolean = false; // display the time slots
  scheduleAppointmentSubscription: Subscription;
  voiceSubscription: Subscription;
  scheduleAppointmentDoctorSelectionSubscription: Subscription;
  scheduleAppointmentModalOpenSubscription: Subscription;

  time = {};
  meridian = true;
  dateTimeSlotJSON = {}; //This will maintain date wise timeslots received from server to preload the data
  toggleMeridian() {
    this.meridian = !this.meridian;
  }
  eventsArray = [];
  selectedDoctorId = "";
  location: string = ""; //This is for saving selected location id
  selectedPurposeOfVisitId: string;
  // selectedLocation: OrganisationLocationObject;

  // appointmentSchedulingForm: FormGroup;

  @Input() selectedPatients: Array<Patient> = [];

  voiceAppointmentTime: string;
  voiceAppointmentDate: string;

  patientIdToFollowUpIdMap = {};

  // Form Controls
  // patientSearchTextFC = new FormControl();
  // doctorFC = new FormControl(null, [Validators.required]);
  // locationFC = new FormControl(null, [Validators.required]);
  // purposeOfVisitFC = new FormControl();
  // durationOfVisitFC = new FormControl();

  locationObject: OrganisationLocationObject;

  doctors: Map<string, OrganisationMember> = new Map();
  loadingDoctorsList: boolean = false;
  availableDoctorOptions = [];
  appointmentsMap = {};
  //calendar properties
  selectable = true;
  droppable = false;
  draggable = false;
  headerJSON = {
    left: "today prev,next",
    center: "title",
    right: "week"
  };
  firstDay = 1;
  displayEventTime = false;
  slotDuration = "00:30:00";
  // scrollTime = moment().format("HH:mm:ss");
  //TODO: Customize the min and max time for each doctor
  minTime = "08:00:00";
  maxTime = "23:59:00";
  eventLimit: true; // for all non-TimeGrid views
  views: {
    dayGrid: {
      // options apply to dayGridMonth, dayGridWeek, and dayGridDay views
      eventLimit: 1;
    };
    timeGrid: {
      // options apply to timeGridWeek and timeGridDay views
      eventLimit: 1;
    };
    week: {
      // options apply to dayGridWeek and timeGridWeek views
      eventLimit: 1;
    };
    day: {
      // options apply to dayGridDay and timeGridDay views
      eventLimit: 1;
    };
  };

  today = moment();
  startDate: string = this.today.startOf("week").format("YYYY-MM-DD");
  endDate: string = this.today.endOf("week").format("YYYY-MM-DD");
  appointment_listener;

  selectedSlotLength = 30;

  dateSelected: string;
  timeSlotSelected = {};
  threeDaysSelected: boolean = true; //Always for now

  locationOptions = [];

  availableVisitTypes = [];
  opdChargesMap: Map<string, OPDTypeAndFee> = new Map();

  availableDurations = [
    {
      label: "15 min.",
      value: 15
    },
    {
      label: "30 min.",
      value: 30
    },
    {
      label: "1 hour",
      value: 60
    },
    {
      label: "1.5 hour",
      value: 90
    },
    {
      label: "2 hour",
      value: 120
    },
    {
      label: "2.5 hour",
      value: 150
    },
    {
      label: "3 hour",
      value: 180
    }
  ];

  isRescheduling = true;
  isCalledOnce = false;
  oldAppointment: Appointment;

  patientSearchString: string = "";

  pageTitle = "New Appointment";

  timeSlotsToDisplay = [];
  timeSlotsToDisplayOne = [];
  timeSlotsToDisplayTwo = [];
  timeSlotsToDisplayThree = [];
  threeDaysTimeSlotsToDisplay = {
    one: {},
    two: {},
    three: {}
  };
  constructor(
    private _appointmentOperationsService: AppointmentOperationsService,
    private _opdScheduleOperationsService: OpdScheduleOperationsService,
    private _scheduleAppointmentModalService: ScheduleAppointmentModalService,
    private _notifyService: NotifyService,
    private _voiceService: VoiceService,
    private _patientProfileService: PatientProfileService,
    private _viewAppointmentDialogService: ViewAppointmentDialogService
  ) {}

  clearDateTimeSlotJSON() {
    this.dateTimeSlotJSON = {};
  }

  calendarDatesChanged(cal) {
    let startDateMoment = moment(cal["view"]["activeStart"]);
    let endDateMoment = moment(cal["view"]["activeEnd"]);
    this.startDate = startDateMoment.format("YYYY-MM-DD");
    this.endDate = endDateMoment.format("YYYY-MM-DD");
    // this.scrollTime = moment().format("HH:mm:ss");
    this.destroyListener();
    this.activeListener();
  }

  calendarDateClick(dateClickedInfo) {
    let dateValue = dateClickedInfo["date"];
    this.datePickerDate = dateValue;
    if (dateValue) {
      let temp = {
        type: "book_appointment_with_json",
        appointment_doctor_id: this.selectedDoctorId,
        appointment_location_id: this.location
      };
      let m = moment(dateValue);
      let selectedDate = m.format("YYYY-MM-DD");

      if (selectedDate != null) {
        temp["appointment_date"] = selectedDate;
      }
      let selectedTime = m.format("HH:mm");
      if (selectedTime && selectedTime != "00:00") {
        temp["appointment_time_slot"] = selectedTime;
      }
      console.log("temp", temp);
      this._scheduleAppointmentModalService.openWithJSON(temp);
    }
  }

  eventClicked(cal) {
    $(cal.el).tooltip("hide");
    let json = cal.event.extendedProps;
    let appointment = new Appointment();
    appointment.initFromJSON(json);
    if (appointment.getOnlyTime()) {
      this.onTimeSlotClicked(
        { start_time: appointment.getOnlyTime() },
        appointment.date
      );
    }
  }

  activeListener() {
    firebase
      .firestore()
      .collection(FirestoreCollections.ORGANISATION_DATA)
      .doc(GlobalVariables.getOrganisationId())
      .collection(FirestoreCollections.ORGANISATION_APPOINTMENTS)
      .where(firebase.firestore.FieldPath.documentId(), ">=", this.startDate)
      .where(firebase.firestore.FieldPath.documentId(), "<=", this.endDate)
      .onSnapshot(snaps => {
        this.eventsArray = [];
        if (!snaps.empty) {
          snaps.docs.forEach(doc => {
            this.appointmentsMap[doc.id] = doc.data();
          });
          this.filterAppointment();
        } else {
          this.appointmentsMap = {};
          this.eventsArray = [];
        }
      });
  }

  destroyListener() {
    if (this.appointment_listener) {
      this.appointment_listener();
    }
  }

  filterAppointment() {
    this.eventsArray = [];
    for (let k in this.appointmentsMap) {
      let jsonVal = this.appointmentsMap[k];
      for (let j in jsonVal) {
        let json = jsonVal[j];
        if (
          json["doctorId"] == this.selectedDoctorId &&
          json["locationId"] == this.location &&
          json["status"] != AppointmentStatus.APPOINTMENT_CANCELLED
        ) {
          let temp = {};
          temp["title"] = json["patientName"];
          temp["extendedProps"] = json;
          if (json["status"]) {
            let status = json["status"];
            switch (status) {
              case AppointmentStatus.APPOINTMENT_CONFIRMED: {
                // temp["backgroundColor"] = "#6c757d";
                break;
              }
              case AppointmentStatus.APPOINTMENT_ARRIVED: {
                temp["backgroundColor"] = "#5c6bc0";
                break;
              }
              case AppointmentStatus.APPOINTMENT_COMPLETED: {
                temp["backgroundColor"] = "#18c5a9";
                break;
              }
              case AppointmentStatus.APPOINTMENT_CANCELLED: {
                temp["backgroundColor"] = "#f75a5f";
                break;
              }
            }
          }
          if (json["timeslot"]) {
            temp["title"] =
              temp["title"] +
              ", " +
              moment(json["timeslot"].substr(0, 5), "HH:mm").format("h:mma");
            temp["start"] =
              json["date"] + "T" + json["timeslot"].substr(0, 5) + ":00";
            temp["end"] =
              json["date"] + "T" + json["timeslot"].substr(6, 10) + ":00";
          }
          this.eventsArray = this.eventsArray.concat(temp);
        }
      }
    }
  }

  clearPatientSearchTextController: boolean = false;
  fetchingPatientProfiltFromId: boolean = false;
  ngOnInit() {
    this.opdChargesMap.clear();
    this.opdChargesMap = GlobalVariables.getOrganisation().opdCharges;
    this.availableVisitTypes = [];
    this.opdChargesMap.forEach(val => {
      this.availableVisitTypes.push({
        value: val.serviceId,
        label: val.serviceName
      });
    });
    if (this.availableVisitTypes.length > 0) {
      this.selectedPurposeOfVisitId = this.availableVisitTypes[0]["value"];
    }
    if (GlobalVariables.getOrganisation().slotLength) {
      this.selectedSlotLength = GlobalVariables.getOrganisation().slotLength;
    }
    this.initializeDate();
    this.loadAvailableLocations();
    // this.scrollTime = moment().format("HH:mm:ss");
    this.slotDuration =
      "00:" + GlobalVariables.getOrganisation().slotLength + ":00";
    this.getDoctorsList();
    this.scheduleAppointmentSubscription = this._scheduleAppointmentModalService
      .getSelectedAppointment()
      .subscribe(appt => {
        if (appt && appt.appointmentId) {
          this._scheduleAppointmentModalService.selectedAppointment(null);
          this.oldAppointment = appt;
          this.isRescheduling = true;
          this.pageTitle = "Reschedule Appointment";
        }
      });
    this.scheduleAppointmentDoctorSelectionSubscription = this._scheduleAppointmentModalService
      .getSelectedDoctor()
      .subscribe(val => {
        if (val != null) {
          this._scheduleAppointmentModalService.selectDoctor(null);
          this.selectDoctor(val.docvitaId);
          this.onDoctorChanged();
        }
      });
    this.scheduleAppointmentModalOpenSubscription = this._scheduleAppointmentModalService
      .modalOpenedAgain()
      .subscribe(val => {
        this.reloadCalendarBool = false;
        // this.patientSearchString = "";
        this.clearPatientSearchTextController = true;
        setTimeout(() => {
          this.clearPatientSearchTextController = false;
        }, 100);

        //Handle follow up id map
        // re render the calendar here
        // this.calendar.render();
        if (val && val["patientIdToFollowUpIdMap"]) {
          this.patientIdToFollowUpIdMap = val["patientIdToFollowUpIdMap"];
        } else {
          this.patientIdToFollowUpIdMap = {};
        }
        if (!val) {
          this.isRescheduling = false;
          this.pageTitle = "New Appointment";
          this.oldAppointment = null;
          // this.selectedPatients = [];
          this.voiceAppointmentDate = null;
          this.voiceAppointmentTime = null;
          this.selectedPatients = [];
          this.timeSlotSelected = {};
          // console.log("Y2")
          this.initializeDate();
          this.loadAvailableLocations();
          this.getDoctorsList();
          this.timeSlotSelected = {};
        } else if (val != null && val["type"] == "book_appointment_with_json") {
          if (!this.oldAppointment) {
            this.isRescheduling = false;
          }

          if (val != null && val["appointment_doctor_id"] != null) {
            this.selectDoctor(val["appointment_doctor_id"]);
          }
          if (val != null && val["appointment_location_id"] != null) {
            this.selectLocation(val["appointment_location_id"]);
          }
          let appointment_date_val = val["appointment_date"];
          if (
            appointment_date_val != null &&
            appointment_date_val.length == 10
          ) {
            this.calendarDateSelected(appointment_date_val);
          }
          let appointment_time_slot = val["appointment_time_slot"];
          if (
            appointment_time_slot != null &&
            appointment_time_slot.length == 5
          ) {
            let temp = {};
            temp["start_time"] = appointment_time_slot;
            this.onTimeSlotClicked(temp, appointment_date_val);
          } else {
            this.threeDaysSelected = false;
          }
        } else if (
          val != null &&
          val["type"] == "patientId" &&
          val["patientId"]
        ) {
          // console.log("I can read the type and patient id.");
          this.isRescheduling = false;
          this.pageTitle = "New Appointment";
          this.oldAppointment = null;
          this.selectedPatients = [];
          this.voiceAppointmentDate = null;
          this.voiceAppointmentTime = null;
          this.timeSlotSelected = {};
          let appointment_date_val = val["appointment_date"];
          if (
            appointment_date_val != null &&
            appointment_date_val.length == 10
          ) {
            this.calendarDateSelected(appointment_date_val);
          } else {
            this.initializeDate();
          }
          this.fetchPatientAndPopulateList(val["patientId"]);
          // console.log("Y2")
          this.loadAvailableLocations();
          this.getDoctorsList();
          this.timeSlotSelected = {};
        }
        if (this.isRescheduling) {
          if (!this.isCalledOnce) {
            this.selectedPatients = [];
            this.selectionsForRescheduling();
          }
          this.isCalledOnce = true;
        }
        setTimeout(() => {
          this.reloadCalendarBool = true;
        }, 220);
        this.patientSearchComponent.focusOnSearchInput();
      });

    this.voiceSubscription = this._voiceService
      .getAppointmentJSON()
      .subscribe(json => {
        if (json) {
          // console.log("Y1")
          let appointmentDate = json["appointment_date"];
          let appointmentTime = json["appointment_time"];
          let patientName = json["patient_name"];
          if (!patientName || patientName.length == 0) {
            patientName = "";
          }
          setTimeout(time => {
            this.patientSearchString = patientName;
          }, 110);
          if (appointmentDate) {
            let dates = appointmentDate.split("-");
            this.start_date = {
              year: +dates[0],
              month: +dates[1],
              day: +dates[2]
            };
            this.selectThisDate();
            this.voiceAppointmentDate = appointmentDate;
          }
          if (appointmentTime) {
            this.voiceAppointmentTime = appointmentTime;
            this.onTimeSlotClicked({ start_time: appointmentTime });
          }
          this._notifyService.showSuccessMessage(
            "appointment_date: " +
              appointmentDate +
              "\n" +
              "appointmentTime: " +
              appointmentTime
          );
          this._voiceService.clearAppointmentJSON();
        }
      });
    this.currentSelectedPatientObserver();
  }

  private currentSelectedPatientObserver() {
    this._voiceService.getCurrentPatient().subscribe(patient => {
      this.selectedPatients.splice(0, this.selectedPatients.length);
      this.selectedPatients.push(patient);
    });
  }

  private selectionsForRescheduling() {
    if (this.oldAppointment) {
      let dates = this.oldAppointment.date.split("-");
      this.start_date = {
        year: +dates[0],
        month: +dates[1],
        day: +dates[2]
      };
      this.selectThisDate();
      if (this.oldAppointment.timeslot) {
        this.voiceAppointmentDate = this.oldAppointment.date;
        this.voiceAppointmentTime = this.oldAppointment.timeslot.substring(
          0,
          5
        );
      }

      this.selectDoctor(this.oldAppointment.doctorId);
      this.selectLocation(this.oldAppointment.locationId);
      this.fetchPatientAndPopulateList(this.oldAppointment.patientId);
      this.selectPurposeOfVisit(this.oldAppointment.purposeOfVisitId);
      this.onDoctorChanged();
    }
  }

  selectPurposeOfVisit(purposeOfVisitId: string) {
    if (purposeOfVisitId && this.opdChargesMap.has(purposeOfVisitId)) {
      this.selectedPurposeOfVisitId = purposeOfVisitId;
    }
  }

  fetchPatientAndPopulateList(patientId: string) {
    this.fetchingPatientProfiltFromId = true;
    this._patientProfileService
      .fetch(patientId)
      .then(response => {
        let clonedResponse: any = JSON.parse(JSON.stringify(response));
        let patient = new Patient();
        if (clonedResponse.body.success) {
          patient.initFromJSON(clonedResponse.body.user_profile);
          this.selectedPatients.push(patient);
        }
        this.fetchingPatientProfiltFromId = false;
      })
      .catch(err => {
        console.error(err);
        this.fetchingPatientProfiltFromId = false;
      });
  }

  selectLocation(locationId: string) {
    this.location = locationId;
    this.locationObject = GlobalVariables.getOrganisation().locations.get(
      this.location
    );
  }

  setDefaultCustomTime() {
    this.time = {
      hour: 10,
      minute: 0
    };
    // let hourStringFromTimePicker = this.time["hour"] + "";
    // let minuteStringFromTimePicker = this.time["minute"] + "";

    // if (
    //   hourStringFromTimePicker == null ||
    //   hourStringFromTimePicker.length == 0
    // ) {
    //   this.time["hour"] = 10;
    // }
    // if (
    //   minuteStringFromTimePicker == null ||
    //   minuteStringFromTimePicker.length == 0
    // ) {
    //   this.time["minute"] = 0;
    // }
    this.timeChangedInPicker();
  }

  clearSelectedTime() {
    this.time = {};
    this.timeSlotSelected = {};
    if (!this.timeSlotsToDisplay || this.timeSlotsToDisplay.length == 0) {
      this.onDoctorChanged();
    }
  }

  getSelectedTimeSlotAppointments() {
    if (this.timeSlotSelected && this.timeSlotSelected["appointments"]) {
      return this.timeSlotSelected["appointments"];
    } else {
      return [];
    }
  }

  timeChangedInPicker() {
    // this._notifyService.showSuccessMessage(
    //   "Time Changed",
    //   this.time["hour"] + ":" + this.time["minute"]
    // );
    let hourStringFromTimePicker = this.time["hour"] + "";
    let minuteStringFromTimePicker = this.time["minute"] + "";

    if (
      hourStringFromTimePicker &&
      hourStringFromTimePicker.length > 0 &&
      minuteStringFromTimePicker &&
      minuteStringFromTimePicker.length > 0
    ) {
      if (hourStringFromTimePicker.length == 1) {
        hourStringFromTimePicker = "0" + hourStringFromTimePicker;
      }
      if (minuteStringFromTimePicker.length == 1) {
        minuteStringFromTimePicker = "0" + minuteStringFromTimePicker;
      }
      let startTimeString =
        hourStringFromTimePicker + ":" + minuteStringFromTimePicker;

      let foundIndex = -1;
      let potentialMatch = this.timeSlotsToDisplay.find((element, index) => {
        if (element["start_time"] == startTimeString) {
          foundIndex = index;
          return true;
        }
      });

      if (potentialMatch && foundIndex > -1) {
        this.timeSlotSelected = potentialMatch;
      } else {
        this.timeSlotSelected = {
          start_time:
            hourStringFromTimePicker + ":" + minuteStringFromTimePicker
        };
      }
    } else {
      this.timeSlotSelected = {};
    }
    this.dateSelected = this.datePickerDate;
  }

  onSubmit() {
    var self = this;
    this.isSavingAppointment = true;
    if (this.validateForm()) {
      let appointment = new Appointment();
      if (this.isRescheduling) {
        appointment.appointmentId = this.oldAppointment.appointmentId;
        appointment.patientId = this.oldAppointment.patientId;
        appointment.patientAge = this.oldAppointment.patientAge;
        appointment.patientAgeObject = this.oldAppointment.patientAgeObject;
        appointment.patientName = this.oldAppointment.patientName;
        appointment.patientGender = this.oldAppointment.patientGender;
        appointment.patientPrimaryEmail = this.oldAppointment.patientPrimaryEmail;
        appointment.patientPrimaryContactNumber = this.oldAppointment.patientPrimaryContactNumber;
      }
      appointment.organisationId = GlobalVariables.getOrganisationId();
      appointment.organisationName = GlobalVariables.getOrganisationName();
      appointment.doctorId = this.selectedDoctorId;
      appointment.doctorName = this.doctors.get(this.selectedDoctorId).name;
      appointment.date = this.dateSelected;
      // appointment.date = this.datePickerDate;
      if (this.timeSlotSelected) {
        if (this.selectedSlotLength) {
          let startTime = this.timeSlotSelected["start_time"];
          let startTimeObj = moment(
            this.dateSelected + " " + startTime,
            "YYYY-MM-DD HH:mm"
          );
          // let startTimeObj = moment(
          //   this.datePickerDate + " " + startTime,
          //   "YYYY-MM-DD HH:mm"
          // );

          startTimeObj.add(this.selectedSlotLength, "minute");
          let endTime = startTimeObj.format("HH:mm");
          this.timeSlotSelected["end_time"] = endTime;
        }
        appointment.timeslot =
          this.timeSlotSelected["start_time"] +
          "-" +
          this.timeSlotSelected["end_time"];
      }
      appointment.locationId = this.location;
      appointment.locationName = this.locationObject.title;
      if (this.selectedPurposeOfVisitId) {
        appointment.purposeOfVisitId = this.selectedPurposeOfVisitId;
        if (this.opdChargesMap.has(this.selectedPurposeOfVisitId)) {
          appointment.purposeOfVisitTitle = this.opdChargesMap.get(
            this.selectedPurposeOfVisitId
          ).serviceName;
        }
      }
      if (this.isRescheduling) {
        self.isSavingAppointment = true;
        this._appointmentOperationsService
          .reschedule(this.oldAppointment, appointment)
          .then(resp => {
            self.isSavingAppointment = false;
            if (resp && resp["statusCode"] && resp["statusCode"] == 200) {
              this._notifyService.showSuccessMessage(
                "Appointment rescheduled :)"
              );
              this._scheduleAppointmentModalService.close();
            } else {
              this._notifyService.showErrorMessage("Please try again");
            }
          })
          .catch(err => {
            self.isSavingAppointment = false;
            console.error(err);
          });
      } else {
        self.isSavingAppointment = true;
        this._appointmentOperationsService
          .book(
            appointment,
            this.selectedPatients,
            this.patientIdToFollowUpIdMap
          )
          .then(resp => {
            self.isSavingAppointment = false;
            if (resp && resp["statusCode"] && resp["statusCode"] == 200) {
              this._notifyService.showSuccessMessage(
                "Appointment confirmed :)"
              );
              this._scheduleAppointmentModalService.close();
            } else {
              this._notifyService.showErrorMessage("Please try again");
            }
          })
          .catch(err => {
            self.isSavingAppointment = false;
            console.error(err);
            this._notifyService.showErrorMessage("Please try again");
          });
      }
    } else {
      this.isSavingAppointment = false;
    }
  }

  validateForm() {
    //Will implement more conditions later
    if (this.timeSlotSelected && this.timeSlotSelected["start_time"]) {
      return true;
    } else {
      this._notifyService.showWarningMessage("Select a timeslot");
      return false;
    }
  }

  patientSearchTextChanged() {}

  initializeDate() {
    let today = new Date(Date.now());
    this.datePickerDate = DateActions.getDateString(today);
    this.start_date = {
      day: today.getDate(),
      month: today.getMonth() + 1,
      year: today.getFullYear()
    };
  }

  selectPatient(patient: Patient) {
    this.selectedPatients.push(patient);
  }

  onTimeSlotClicked(timeSlot: any, clickedDate?: string) {
    console.log("timeslotdate=>", this.datePickerDate);
    this.timeSlotSelected = timeSlot;
    let json = {};
    json["hour"] = +this.timeSlotSelected["start_time"].substring(0, 2);
    json["minute"] = +this.timeSlotSelected["start_time"].substring(3, 5);
    this.time = json;
    if (clickedDate) {
      this.dateSelected = clickedDate;
      this.calendarDateSelected(clickedDate);
    }
  }

  isSelectedTimeSlot(time) {
    return (
      this.timeSlotSelected["start_time"] == time["start_time"] &&
      this.dateSelected == this.datePickerDate
    );
  }

  calendarDateSelected(date: string) {
    // this.datePickerDate = date;
    console.log(date);
    let m = moment(date, "YYYY-MM-DD");
    this.datePickerDate = m.format("YYYY-MM-DD");
    // update start_date here
    this.start_date = {
      year: m.year(),
      month: m.month() + 1,
      day: m.date()
    };
    this.selectThisDate();
  }

  isTimeSlotSelected() {
    return this.timeSlotSelected["start_time"] != null;
  }

  isThreeDaysSelected() {
    return this.threeDaysSelected;
  }
  /**
   * Load locations from organisation obejct
   */
  loadAvailableLocations() {
    GlobalVariables.getOrganisation().locations.forEach((val, key) => {
      this.location = key;
      let json = {};
      json["label"] = val.title;
      json["value"] = key;
      this.locationOptions.push(json);
    });
    if (this.locationOptions.length > 0) {
      this.location = this.locationOptions[0]["value"];
    }
  }

  onLocationChange() {
    this.locationObject = GlobalVariables.getOrganisation().locations.get(
      this.location
    );
    this.clearDateTimeSlotJSON();
    //TODO: fetch slots function call
    this.onDoctorChanged();
  }

  loadTodayDate() {
    let m = moment(new Date(), "YYYY-MM-DD");
    // m.add("days", -1);
    this.datePickerDate = m.format("YYYY-MM-DD");
    // update start_date here
    this.start_date = {
      year: m.year(),
      month: m.month() + 1,
      day: m.date()
    };
    this.selectThisDate();
    // this.threeDaysSelected = false;
  }

  loadPreviousDate() {
    console.log("load previous date");
    let m = moment(this.datePickerDate, "YYYY-MM-DD");
    m.add("days", -3);

    this.datePickerDate = m.format("YYYY-MM-DD");
    console.log("load previous date", this.datePickerDate);
    // update start_date here
    this.start_date = {
      year: m.year(),
      month: m.month() + 1,
      day: m.date()
    };
    this.selectThisDate();
  }
  loadNextDate() {
    let m = moment(this.datePickerDate, "YYYY-MM-DD");
    m.add("days", 3);
    this.datePickerDate = m.format("YYYY-MM-DD");
    console.log("loadNextDate", this.datePickerDate);
    // update start_date here
    this.start_date = {
      year: m.year(),
      month: m.month() + 1,
      day: m.date()
    };
    this.selectThisDate();
  }

  selectThisDate() {
    var self = this;
    if (self.dateTimeSlotJSON[self.datePickerDate] != null) {
      self.extractSlots();
    } else {
      this.onDoctorChanged();
    }
  }

  /**
   * This is to populate dates and their slots
   */

  /**
   * Available doctor list for selected date and location
   * @param date
   * @param locationId
   */
  getDoctorsList() {
    this.doctors.clear();
    this.availableDoctorOptions.splice(0, this.doctors.size);
    GlobalVariables.getOpdScheduleAvailableMembersMap(
      GlobalVariables.getOrganisationId()
    ).forEach((val, key) => {
      let temp = {};
      temp["label"] = val.name;
      temp["value"] = key;
      this.availableDoctorOptions.push(temp);
      this.doctors.set(key, val);
    });
    this.availableDoctorOptions.sort((a, b) => {
      return (a.value + "").localeCompare(b.value + "");
    });
    let docId = GlobalVariables.getMemberId();
    if (
      !GlobalVariables.getMember().getOpdScheduleAvailability(
        GlobalVariables.getOrganisationId()
      ) &&
      this.availableDoctorOptions.length > 0
    ) {
      docId = this.availableDoctorOptions[0]["value"];
    }
    this.selectDoctor(docId);
    this.clearDateTimeSlotJSON();
    //TODO: fetch slots function call
    this.onDoctorChanged();
  }

  onDoctorChanged() {
    this.filterAppointment();
    let self = this;
    if (
      this.selectedDoctorId &&
      this.selectedDoctorId.length > 0 &&
      this.datePickerDate &&
      this.location &&
      this.datePickerDate.length > 0 &&
      this.location.length > 0 &&
      this.threeDaysSelected
    ) {
      // Fetch three days schedule
      self.isRefreshingTimeSlots = true;
      this._opdScheduleOperationsService
        .fetchDoctorScheduleSlotsForDateAndLocationWithMultipleDates(
          this.datePickerDate,
          GlobalVariables.getOrganisationId(),
          this.location,
          this.selectedDoctorId,
          15
        )
        .then(resp => {
          if (resp && resp["statusCode"] && resp["statusCode"] == 200) {
            let body = resp["body"];
            let temp = resp["body"]["data"];
            if (temp) {
              for (let k in temp) {
                this.dateTimeSlotJSON[k] = temp[k];
              }
            }
            this.extractSlots();
          } else {
            //handle error in fetching
          }
          if (
            this.voiceAppointmentTime &&
            this.voiceAppointmentDate &&
            this.voiceAppointmentDate == this.datePickerDate
          ) {
            this.timeSlotsToDisplay.forEach(val => {
              if (val && val["start_time"] == this.voiceAppointmentTime) {
                this.onTimeSlotClicked(val);
              }
            });
            // this.voiceAppointmentTime = null;
          }
          self.isRefreshingTimeSlots = false;
        })
        .catch(err => {
          console.error(err);
          self.isRefreshingTimeSlots = false;
        });
    }
  }

  extractSlots() {
    let firstDate = this.threeDaysTimeSlotsToDisplay["one"]["pickerDate"];
    if (
      firstDate != null &&
      this.dateTimeSlotJSON[firstDate] &&
      this.dateTimeSlotJSON[firstDate]["slots"]
    ) {
      this.timeSlotsToDisplayOne = this.dateTimeSlotJSON[firstDate]["slots"];
    } else {
      this.timeSlotsToDisplayOne = [];
    }

    let twoDate = this.threeDaysTimeSlotsToDisplay["two"]["pickerDate"];
    if (
      twoDate != null &&
      this.dateTimeSlotJSON[twoDate] &&
      this.dateTimeSlotJSON[twoDate]["slots"]
    ) {
      this.timeSlotsToDisplayTwo = this.dateTimeSlotJSON[twoDate]["slots"];
    } else {
      this.timeSlotsToDisplayTwo = [];
    }

    let thirdDate = this.threeDaysTimeSlotsToDisplay["three"]["pickerDate"];
    if (
      thirdDate != null &&
      this.dateTimeSlotJSON[thirdDate] &&
      this.dateTimeSlotJSON[thirdDate]["slots"]
    ) {
      this.timeSlotsToDisplayThree = this.dateTimeSlotJSON[thirdDate]["slots"];
    } else {
      this.timeSlotsToDisplayThree = [];
    }
  }

  selectDoctor(memberId: string) {
    if (this.availableDoctorOptions.length > 0) {
      for (let a of this.availableDoctorOptions) {
        if (a["value"] == memberId) {
          this.selectedDoctorId = memberId;
          this.filterAppointment();
        }
      }
    }
  }

  addToNetwork(patient: Patient) {
    this._patientProfileService
      .makeConnection(
        patient.docvitaId,
        GlobalVariables.getOrganisationId(),
        GlobalVariables.getMemberId(),
        GlobalVariables.getMemberName()
      )
      .then(resp => {
        if (resp && resp["statusCode"] && resp["statusCode"] == 200) {
          this._notifyService.showSuccessMessage("Added to the network");
        } else {
          this._notifyService.showErrorMessage("Please try again.");
        }
      })
      .catch(err => {
        console.error(err);
        this._notifyService.showErrorMessage("Please try again.");
      });
  }

  removePatientFromList(status: boolean, patientDocvitaId: string) {
    let foundIndex = -1;
    let potentialMatch = this.selectedPatients.find((element, index) => {
      if (element.docvitaId == patientDocvitaId) {
        foundIndex = index;
        return true;
      }
    });
    if (potentialMatch && foundIndex > -1) {
      this.selectedPatients.splice(foundIndex, 1);
    }
  }

  eventRender(info) {
    let appointment = new Appointment();
    appointment.initFromJSON(info.event["extendedProps"]);
    $(info.el).tooltip({
      title:
        appointment.patientName +
        ", " +
        moment(appointment.date, "YYYY-MM-DD").format("DD MMM YYYY") +
        ", " +
        moment(appointment.getOnlyTimeToDisplay(), "hh:mm").format("hh:mm a") +
        ", " +
        appointment.status,
      placement: "top",
      trigger: "hover",
      container: "body"
    });
  }

  ngOnDestroy() {
    if (this.scheduleAppointmentSubscription)
      this.scheduleAppointmentSubscription.unsubscribe();
    if (this.voiceSubscription) this.voiceSubscription.unsubscribe();
    if (this.scheduleAppointmentDoctorSelectionSubscription)
      this.scheduleAppointmentDoctorSelectionSubscription.unsubscribe();
    if (this.scheduleAppointmentModalOpenSubscription)
      this.scheduleAppointmentModalOpenSubscription.unsubscribe();
  }

  // close modal with escape
  @HostListener("window:keyup", ["$event"])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.ESC) {
      this._scheduleAppointmentModalService.close();
    }
  }
}

import { VitalMeasurementConstants } from "./Vital Measurement Constants";
import { BasicModelInterface } from "../BasicModelInterface";
import { ObjectKeys } from "../../constants/object-keys";

export class VitalMeasurement implements BasicModelInterface{
  
  private _vitalMeasurementId: string;

  // Height, Weight, Blood Glucose, Blood Pressure, SPo2, BMI, Pulse,
  //   private _vitalType: string;

  // Height, Weight, Blood Glucose,
  private _vitalValue1: number;

  // BP - Diastolic
  private _vitalValue2: number;

  // Height-cm, Weight-kg, BG-mg/dL, BP-mm Hg, SpO2 - %, BMI - kg/m2
  private _vitalUnit: string;

  // For Blood Glucose -> Type
  // For Blood Pressure -> Position Type
  private _vitalInfo: string;

  private _createdOn: number;
  private _createdBy: string;
  private _measuredOn: number;

  private _docvitaPatientId: string;
  
  private _vitalType: string;
  constructor() {
    this.setDefaultsForVitalType();
  }

  toJSON(): {} {
    let json = {}
    if(this.vitalMeasurementId){
      json[ObjectKeys.vitalMeasurementId] = this.vitalMeasurementId;
    }
    if(this.vitalValue1){
      json[ObjectKeys.vitalValue1] = this.vitalValue1;
    }
    if(this.vitalValue2){
      json[ObjectKeys.vitalValue2] = this.vitalValue2;
    }
    if(this.vitalUnit){
      json[ObjectKeys.vitalUnit] = this.vitalUnit;
    }
    if(this.vitalInfo){
      json[ObjectKeys.vitalInfo] = this.vitalInfo;
    }
    if(this.createdOn){
      json[ObjectKeys.createdOn] = this.createdOn;
    }
    if(this.createdBy){
      json[ObjectKeys.createdBy] = this.createdBy;
    }
    if(this.measuredOn){
      json[ObjectKeys.measuredOn] = this.measuredOn;
    }
    if(this.docvitaPatientId){
      json[ObjectKeys.docvitaPatientId] = this.docvitaPatientId;
    }
    if(this.vitalType){
      json[ObjectKeys.vitalType] = this.vitalType;
    }
    return json;
  }
  initFromJSON(json: {}): void {
    if(json[ObjectKeys.vitalMeasurementId]){
      this.vitalMeasurementId = json[ObjectKeys.vitalMeasurementId];
    }
    if(json[ObjectKeys.vitalValue1]){
      this.vitalValue1 = json[ObjectKeys.vitalValue1];
    }
    if(json[ObjectKeys.vitalValue2]){
      this.vitalValue2 = json[ObjectKeys.vitalValue2];
    }
    if(json[ObjectKeys.vitalUnit]){
      this.vitalUnit = json[ObjectKeys.vitalUnit];
    }
    if(json[ObjectKeys.vitalInfo]){
      this.vitalInfo = json[ObjectKeys.vitalInfo];
    }
    if(json[ObjectKeys.createdOn]){
      this.createdOn = json[ObjectKeys.createdOn];
    }
    if(json[ObjectKeys.createdBy]){
      this.createdBy = json[ObjectKeys.createdBy];
    }
    if(json[ObjectKeys.measuredOn]){
      this.measuredOn = json[ObjectKeys.measuredOn]
    }
    if(json[ObjectKeys.docvitaPatientId]){
      this.docvitaPatientId = json[ObjectKeys.docvitaPatientId];
    }
    if(json[ObjectKeys.vitalType]){
      this.vitalType = json[ObjectKeys.vitalType];
    }
  }

  public getVitalValue() {
    switch (this.vitalType) {
      case VitalMeasurementConstants.VITAL_TYPE_BLOOD_GLUCOSE:
        let bloodGlucoseType = "";
        for (let timing of VitalMeasurementConstants.BLOOD_GLOCOSE_TIMINGS) {
          if (timing["value"] == this.vitalInfo) {
            bloodGlucoseType = timing["label"];
            break;
          }
        }
        return this.vitalValue1 != undefined && this.vitalValue1 > 0
          ? this.vitalValue1 +
              " " +
              VitalMeasurementConstants.BLOOD_GLUCOSE_UNIT +
              "\n" +
              "(" +
              bloodGlucoseType +
              ")"
          : " - ";
        break;
      case VitalMeasurementConstants.VITAL_TYPE_BLOOD_PRESSURE:
        if (
          this.vitalValue1 != undefined &&
          this.vitalValue1 > 0 &&
          this.vitalValue2 != undefined &&
          this.vitalValue2 > 0
        ) {
          let possiblePositionTypeLabel = VitalMeasurementConstants.BLOOD_PRESSURE_POSITIONS.find(
            element => {
              return element.value == this.vitalInfo;
            }
          ).label;
          let positionText =
            possiblePositionTypeLabel && possiblePositionTypeLabel.length > 0
              ? possiblePositionTypeLabel
              : this.vitalInfo;

          return (
            this.vitalValue1 +
            "/" +
            this.vitalValue2 +
            " " +
            VitalMeasurementConstants.BLOOD_PRESSURE_UNIT +
            "\n(" +
            positionText +
            ")"
          );
        } else {
          return " - ";
        }
        break;
      case VitalMeasurementConstants.VITAL_TYPE_BMI:
        return this.vitalValue1 != undefined && this.vitalValue1 > 0
          ? this.vitalValue1 + " " + VitalMeasurementConstants.BMI_UNIT
          : " - ";
        break;
      case VitalMeasurementConstants.VITAL_TYPE_HEIGHT:
        return this.vitalValue1 != undefined && this.vitalValue1 > 0
          ? this.vitalValue1 + " " + VitalMeasurementConstants.HEIGHT_UNIT
          : " - ";
        break;
      case VitalMeasurementConstants.VITAL_TYPE_PULSE:
        return this.vitalValue1 != undefined && this.vitalValue1 > 0
          ? this.vitalValue1 + " " + VitalMeasurementConstants.PULSE_UNIT
          : " - ";
        break;
      case VitalMeasurementConstants.VITAL_TYPE_TEMPERATURE:
        return this.vitalValue1 != undefined && this.vitalValue1 > 0
          ? this.vitalValue1 + " " + VitalMeasurementConstants.TEMPERATURE_UNIT
          : " - ";
        break;
      case VitalMeasurementConstants.VITAL_TYPE_WEIGHT:
        return this.vitalValue1 != undefined && this.vitalValue1 > 0
          ? this.vitalValue1 + " " + VitalMeasurementConstants.WEIGHT_UNIT
          : " - ";
        break;
      case VitalMeasurementConstants.VITAL_TYPE_SPO2:
        return this.vitalValue1 != undefined && this.vitalValue1 > 0
          ? this.vitalValue1 + " " + VitalMeasurementConstants.SPO2_UNIT
          : " - ";
        break;
      case VitalMeasurementConstants.VITAL_TYPE_RESPERATORY_RATE:
        return this.vitalValue1 != undefined && this.vitalValue1 > 0
          ? this.vitalValue1 +
              " " +
              VitalMeasurementConstants.RESPERATORY_RATE_UNIT
          : " - ";
        break;
      case VitalMeasurementConstants.VITAL_TYPE_PAIN:
        if (this.vitalValue1 != undefined) {
          let potentialPainLabel = VitalMeasurementConstants.PAIN_VITAL_OPTIONS.find(
            element => {
              return element.value == this.vitalValue1 + "";
            }
          ).label;
          if (potentialPainLabel && potentialPainLabel.length > 0) {
            return potentialPainLabel;
          } else {
            return " - ";
          }
        } else {
          return " - ";
        }
        break;
      default:
        return " - ";
    }
  }

  public getVitalTypeString() {
    switch (this.vitalType) {
      case VitalMeasurementConstants.VITAL_TYPE_BLOOD_GLUCOSE:
        return "Blood Glucose";
      case VitalMeasurementConstants.VITAL_TYPE_BLOOD_PRESSURE:
        return "Blood Pressure";
      case VitalMeasurementConstants.VITAL_TYPE_BMI:
        return "BMI";
      case VitalMeasurementConstants.VITAL_TYPE_HEIGHT:
        return "Height";
      case VitalMeasurementConstants.VITAL_TYPE_PULSE:
        return "Pulse";
      case VitalMeasurementConstants.VITAL_TYPE_TEMPERATURE:
        return "Body Temp.";
      case VitalMeasurementConstants.VITAL_TYPE_WEIGHT:
        return "Weight";
      case VitalMeasurementConstants.VITAL_TYPE_SPO2:
        return "SpO2";
      case VitalMeasurementConstants.VITAL_TYPE_RESPERATORY_RATE:
        return "Resp. Rate";
      case VitalMeasurementConstants.VITAL_TYPE_PAIN:
        return "Pain";
    }
  }

  setDefaultsForVitalType() {
    if (this.vitalType && this.vitalType.length > 0) {
      switch (this.vitalType) {
        case VitalMeasurementConstants.VITAL_TYPE_HEIGHT:
          this.vitalUnit = VitalMeasurementConstants.HEIGHT_UNIT;
          this.vitalInfo = undefined;
          break;
        case VitalMeasurementConstants.VITAL_TYPE_WEIGHT:
          this.vitalUnit = VitalMeasurementConstants.WEIGHT_UNIT;
          this.vitalInfo = undefined;
          break;
        case VitalMeasurementConstants.VITAL_TYPE_BMI:
          this.vitalUnit = VitalMeasurementConstants.BMI_UNIT;
          this.vitalInfo = undefined;
          break;
        case VitalMeasurementConstants.VITAL_TYPE_PULSE:
          this.vitalUnit = VitalMeasurementConstants.PULSE_UNIT;
          this.vitalInfo = undefined;
          break;
        case VitalMeasurementConstants.VITAL_TYPE_TEMPERATURE:
          this.vitalUnit = VitalMeasurementConstants.TEMPERATURE_UNIT;
          this.vitalInfo = undefined;
          break;
        case VitalMeasurementConstants.VITAL_TYPE_SPO2:
          this.vitalUnit = VitalMeasurementConstants.SPO2_UNIT;
          this.vitalInfo = undefined;
          break;
        case VitalMeasurementConstants.VITAL_TYPE_RESPERATORY_RATE:
          this.vitalUnit = VitalMeasurementConstants.RESPERATORY_RATE_UNIT;
          this.vitalInfo = undefined;
          break;
        case VitalMeasurementConstants.VITAL_TYPE_BLOOD_GLUCOSE:
          this.vitalUnit = VitalMeasurementConstants.BLOOD_GLUCOSE_UNIT;
          this.vitalInfo = VitalMeasurementConstants.RANDOM;
          break;
        case VitalMeasurementConstants.VITAL_TYPE_BLOOD_PRESSURE:
          this.vitalUnit = VitalMeasurementConstants.BLOOD_PRESSURE_UNIT;
          this.vitalInfo = VitalMeasurementConstants.BP_SITTING;
          break;
        case VitalMeasurementConstants.VITAL_TYPE_PAIN:
          this.vitalUnit = undefined;
          this.vitalInfo = undefined;
          break;
        default:
          this.vitalUnit = undefined;
          this.vitalInfo = undefined;
          break;
      }
    }
  }

  public get isValidForPush():boolean {
    if (this.vitalType && this.vitalType.length > 0) {
      let isValid = false;
      if (this.vitalValue1 != undefined) {
        isValid = true;
      }
      switch (this.vitalType) {
        case VitalMeasurementConstants.VITAL_TYPE_HEIGHT:
          // No Extra Checks Needed
          break;
        case VitalMeasurementConstants.VITAL_TYPE_WEIGHT:
          // No Extra Checks Needed
          break;
        case VitalMeasurementConstants.VITAL_TYPE_BMI:
          // No Extra Checks Needed
          break;
        case VitalMeasurementConstants.VITAL_TYPE_PULSE:
          // No Extra Checks Needed
          break;
        case VitalMeasurementConstants.VITAL_TYPE_TEMPERATURE:
          // No Extra Checks Needed
          break;
        case VitalMeasurementConstants.VITAL_TYPE_SPO2:
          // No Extra Checks Needed
          break;
        case VitalMeasurementConstants.VITAL_TYPE_RESPERATORY_RATE:
          // No Extra Checks Needed
          break;
        case VitalMeasurementConstants.VITAL_TYPE_BLOOD_GLUCOSE:
          // No Extra Checks Needed
          break;
        case VitalMeasurementConstants.VITAL_TYPE_BLOOD_PRESSURE:
          if (this.vitalValue2 != undefined) {
            isValid = true;
          } else {
            isValid = false;
          }
          break;
        case VitalMeasurementConstants.VITAL_TYPE_PAIN:
          // No Extra Checks Needed
          break;
        default:
          isValid = false;
          break;
      }
      return isValid;
    } else {
      return false;
    }
  }

  /**
   * Getter vitalMeasurementId
   * @return {string}
   */
  public get vitalMeasurementId(): string {
    return this._vitalMeasurementId;
  }

  /**
   * Getter vitalType
   * @return {string}
   */
  public get vitalType(): string {
    return this._vitalType;
  }

  /**
   * Getter vitalValue1
   * @return {number}
   */
  public get vitalValue1(): number {
    return this._vitalValue1;
  }

  /**
   * Getter vitalValue2
   * @return {number}
   */
  public get vitalValue2(): number {
    return this._vitalValue2;
  }

  /**
   * Getter vitalUnit
   * @return {string}
   */
  public get vitalUnit(): string {
    return this._vitalUnit;
  }

  /**
   * Getter vitalInfo
   * @return {string}
   */
  public get vitalInfo(): string {
    return this._vitalInfo;
  }

  /**
   * Getter createdOn
   * @return {number}
   */
  public get createdOn(): number {
    return this._createdOn;
  }

  /**
   * Getter createdBy
   * @return {string}
   */
  public get createdBy(): string {
    return this._createdBy;
  }

  /**
   * Getter measuredOn
   * @return {number}
   */
  public get measuredOn(): number {
    return this._measuredOn;
  }

  /**
   * Setter vitalMeasurementId
   * @param {string} value
   */
  public set vitalMeasurementId(value: string) {
    this._vitalMeasurementId = value;
  }

  /**
   * Setter vitalType
   * @param {string} value
   */
  public set vitalType(value: string) {
    this._vitalType = value;
    this.setDefaultsForVitalType();
  }

  /**
   * Setter vitalValue1
   * @param {number} value
   */
  public set vitalValue1(value: number) {
    this._vitalValue1 = value;
  }

  /**
   * Setter vitalValue2
   * @param {number} value
   */
  public set vitalValue2(value: number) {
    this._vitalValue2 = value;
  }

  /**
   * Setter vitalUnit
   * @param {string} value
   */
  public set vitalUnit(value: string) {
    this._vitalUnit = value;
  }

  /**
   * Setter vitalInfo
   * @param {string} value
   */
  public set vitalInfo(value: string) {
    this._vitalInfo = value;
  }

  /**
   * Setter createdOn
   * @param {number} value
   */
  public set createdOn(value: number) {
    this._createdOn = value;
  }

  /**
   * Setter createdBy
   * @param {string} value
   */
  public set createdBy(value: string) {
    this._createdBy = value;
  }

  /**
   * Setter measuredOn
   * @param {number} value
   */
  public set measuredOn(value: number) {
    this._measuredOn = value;
  }

  /**
   * Getter docvitaPatientId
   * @return {string}
   */
  public get docvitaPatientId(): string {
    return this._docvitaPatientId;
  }

  /**
   * Setter docvitaPatientId
   * @param {string} value
   */
  public set docvitaPatientId(value: string) {
    this._docvitaPatientId = value;
  }
}

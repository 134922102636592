import { Component, OnInit, OnDestroy } from "@angular/core";
import { PlanInfo } from "../../shared/models/MD - Member";
import { GlobalVariables } from "../../globar-var/globarVariables";
import { Router, ActivatedRoute } from "@angular/router";
import { PatientProfileService } from "../../shared/services/patient/patient-profile.service";
import { VoiceService } from "../../shared/services/voice/voice.service";
import { Patient } from "../../shared/models/Patient/Patient";
import { PatientProfileSyncService } from "src/app/shared/services/component-communication/patient-profile-sync.service";
import { PatientRegistrationModalService } from "src/app/shared/services/modalStateManagement/patient-registration-modal.service";
import { Subscription } from "rxjs";
import { NotifyService } from "src/app/shared/services/notify/notify.service";

@Component({
  selector: "[app-patient-header]",
  templateUrl: "./patient-header.component.html",
  styleUrls: ["./patient-header.component.css"]
})
export class PatientHeaderComponent implements OnInit, OnDestroy {
  patientDocVitaId = "";
  selectedPatient: Patient = new Patient();
  patientEmail = "";
  patientContactNumber = "";
  patientSexAge = "";
  patientProfileSyncSubscription: Subscription;

  constructor(
    private _router: Router,
    private activatedRoute: ActivatedRoute,
    private _patientProfileService: PatientProfileService,
    private _notifyService:NotifyService,
    private _patientProfileSyncService: PatientProfileSyncService,
    private _patientRegistrationModalService: PatientRegistrationModalService
  ) {}

  ngOnInit() {
    this.listener();
  }

  listener() {
    this.patientProfileSyncSubscription = this._patientProfileSyncService
      .listener()
      .subscribe(map => {
        if (map) {
          this.patientDocVitaId = this._patientProfileSyncService.extractPatientIdFromPath(
            this._router.url
          );
          if (
            this.patientDocVitaId &&
            this.patientDocVitaId.length > 0 &&
            map.has(this.patientDocVitaId)
          ) {
            this.selectedPatient = map.get(this.patientDocVitaId);
            this.patientSexAge = this.selectedPatient.sex_age;
            if (this.patientSexAge && this.patientSexAge.length > 0) {
              this.patientSexAge = " | " + this.patientSexAge;
            } else {
              this.patientSexAge = "";
            }

            this.patientEmail = this.selectedPatient.primaryEmail;
            if (this.patientEmail && this.patientEmail.length > 0) {
              this.patientEmail = " | " + this.patientEmail;
            } else {
              this.patientEmail = "";
            }
            if (
              this.selectedPatient.primaryContactNumber &&
              this.selectedPatient.primaryContactNumber.number &&
              this.selectedPatient.primaryContactNumber.initFromJSON.length > 0
            ) {
              this.patientContactNumber =
                " | " + this.selectedPatient.primaryContactNumber.number;
            } else {
              this.patientContactNumber = "";
            }
          }
        }
      });
  }

  editPatientProfile() {
    this._patientRegistrationModalService.selectPatientProfileForEditing(
      this.selectedPatient
    );
  }

  back() {
    var self = this;
    let path = this._router.url;
    if (path.indexOf("patients/patient-detail") > -1) {
      self._router.navigate(["/" + "patients"]);
      // current path should go back to patients
    } else if (
      path.indexOf("opd/today") > -1 &&
      path.indexOf("patient-detail") > -1
    ) {
      let finalPath = path.substring(
        path.indexOf("opd/today"),
        path.indexOf("patient-detail")
      );
      self._router.navigate([finalPath]);
      // current path should go back to opd/today 's existing selections
    } else if (
      path.indexOf("opd/calendar") > -1 &&
      path.indexOf("patient-detail") > -1
    ) {
      let finalPath = path.substring(
        path.indexOf("opd/calendar"),
        path.indexOf("patient-detail")
      );
      self._router.navigate([finalPath]);
      // current path should go back to opd/calendar 's existing selections
    } else {
      // handle other cases here
    }
  }

  refresh() {
    this._patientProfileSyncService.refresh();
  }

  printPatientInfo(){
    this._patientProfileService
      .printPatientInto(
        this.patientDocVitaId,
        GlobalVariables.getMemberId(),
        GlobalVariables.getOrganisationId()
      )
      .then((data: BlobPart) => {
        var file = new Blob([data], { type: "application/pdf" });
        var fileURL = URL.createObjectURL(file);
        // this.isRefreshing = false;
        window.open(fileURL).print();
        this._notifyService.showSuccessMessage("Pdf generated");
      })
      .catch(err => {
        this._notifyService.showErrorMessage("Please try again.");
      });
  }

  ngOnDestroy() {
    if (this.patientProfileSyncSubscription)
      this.patientProfileSyncSubscription.unsubscribe();
  }
}
